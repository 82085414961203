import BaseGetters from '@/store/base/getters'

export default BaseGetters({
  formatInitials: () => (firstName = null, lastName = null) => {
      let initials = ''

      if(typeof firstName === 'string')
          initials += firstName[0] ?? ''

      if(typeof lastName === 'string')
          initials += lastName[0] ?? ''

      return initials.toUpperCase() || ''
  },

  getUserFullName: ({module}) => (uuid) => module.find(user => user.uuid === uuid)?.full_name,

  getSystemUsers: ({module}) => module.filter((user) => user.role.slug !== 'client'),
})