<template lang="pug">
.app-menu.navbar-menu(
  :class="[showMobileMenu && isMobileView ? 'active' : '']"
)
  menu-logo

  #scrollbar
    .container-fluid
      menu-nav-bar

</template>

<script>
import MenuLogo from "@/components/menu/MenuLogo";
import MenuNavBar from "@/components/menu/MenuNavBar";
import {mapGetters} from "vuex";

export default {
  name: 'MenuRow',

  components: {
    MenuNavBar,
    MenuLogo
  },

  computed: {
    ...mapGetters({
      showMobileMenu: 'crmSettings/showMobileMenu',
      isMobileView: 'crmSettings/isMobileView'
    })
  },

  mounted() {
    this.checkMobileMenu()
    window.addEventListener('resize', this.checkMobileMenu);
  },

  methods: {
    checkMobileMenu() {
      if(document.documentElement.clientWidth <= 1024) {
        this.$store.dispatch('crmSettings/mobileView', true)
        return
      }

      this.$store.dispatch('crmSettings/mobileView', false)
    }
  }
}
</script>

<style scoped>

</style>