import {formatModuleUrl} from "@/helpers/formatters";
import api from "@/plugins/axios";
import Schema from "@/store/modules/plans/schema";
import BaseActions from '@/store/base/actions'
import * as Types from "@/store/mutationTypes";
import MultipleFormActions from '@/store/base/multipleFormData/actions'

const module = 'plans'

export default BaseActions({module}, {
  async fetchModel({commit, getters, dispatch, rootGetters}) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    // await dispatch('unsetModules')

    const year = rootGetters['projects/getPerformedTableYear']
    const month = rootGetters['projects/getPerformedTableMonth']

    await api.post(`${formatModuleUrl(module)}/get-by-key`, {key: 'date', value: {year, month}}).then(async ({data}) => {
      let modules = data || []

      await dispatch('parseModules', modules)
        .then(parsedModules => modules = parsedModules || modules)

      commit(Types.SET_MODEL, modules)

      dispatch('fetchedModel')
    })
  },
}, [MultipleFormActions(module), Schema])