import BaseMutations from '@/store/base/mutations'
import DetailMutations from "@/store/base/detail/mutations";
import DocumentsMutations from "@/store/base/documents/mutations";
import ArchivesMutations from "@/store/base/archives/mutations";
import * as Types from "@/store/mutationTypes";

const module = 'accommodations'

export default BaseMutations({
  [Types.FILL_FORM_DATA](state, uuid) {
    let module
    if(
      Array.isArray(state.module['archived']) &&
      Array.isArray(state.module['active'])
    )
      module = state.module['archived'].concat(state.module['active']).find(el => el.uuid === uuid)

    if(!module && state.currentModule?.uuid === uuid)
      module = state.currentModule

    state.formData.uuid = module.uuid
    state.formData.project_uuid = module.project_uuid
    state.formData.address = module.address
    state.formData.country_id = module.country_id
    state.formData.city_id = module.city_id
    state.formData.total_beds = module.total_beds
    state.formData.price = module.price
    state.formData.rental_period_starts_at = module.rental_period_starts_at
    state.formData.rental_period_ends_at = module.rental_period_ends_at
    state.formData.contact_details = module.contact_details
    state.formData.lessor = module.lessor
    state.formData.comment = module.comment
    state.formData.is_archived = module.is_archived
  },

  [Types.SET_CURRENT_MODULE_RESIDENTS](state, residents) {
    state.currentModule.residents = residents
    state.currentModule.residentsFetched = true
  },

  [Types.REMOVE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION](state, unassigned) {
    state.currentModule.residents = state.currentModule.residents.filter(resident => resident.uuid !== unassigned.uuid)
  },

  [Types.UPDATE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION](state, assigned) {
    if(assigned.accommodation_uuid === null) {
      state.currentModule.residents = state.currentModule.residents.filter(resident => resident.uuid !== assigned.uuid)
      return
    }

    let index = state.currentModule.residents.findIndex(resident => resident.uuid === assigned.uuid)

    if(0 <= index) {
      state.currentModule.residents[index] = assigned
      return
    }

    state.currentModule.residents.push(assigned)
  },
}, [ArchivesMutations({module}), DocumentsMutations(module), DetailMutations({module})])
