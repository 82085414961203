<template lang="pug">
component(:is="layout")
</template>

<script>

export default {
  data() {
    return {
      defaultLayout: 'crm'
    }
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || this.defaultLayout) + '-layout'
    }
  }
}
</script>

<style lang="scss">
@import './styles/app';
</style>