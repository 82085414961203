import {formatModuleUrl, getValueWithoutReference} from "@/helpers/formatters";
import BaseActions from "@/store/base/actions";
import DetailActions from "@/store/base/detail/actions";
import Schema from "@/store/modules/taskManagerTasks/schema";
import api from '@/plugins/axios'
import DocumentsActions from "@/store/base/documents/actions";
import HistoriesActions from "@/store/base/histories/actions";
import * as Types from "@/store/mutationTypes";

const module = 'taskManagerTasks'

export default BaseActions({module}, {
  async fetchModel({commit, getters, rootGetters, dispatch}) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    let url = formatModuleUrl(module)

    await api.post(`${url}/get-by-key`, {
      key: 'user',
      value: rootGetters['auth/getUuid'],
    }, {trigger404: false}).then(async ({data}) => {
      let modules = data || []

      await dispatch('parseModules', modules)
        .then(parsedModules => modules = parsedModules || [])

      commit(Types.SET_MODEL, modules)

      dispatch('fetchedModel')
    })
  },

  async currentModuleFetched({dispatch, rootGetters}, parsedCurrentModule) {
    if(parsedCurrentModule?.uuid && !rootGetters['comments/isFetched'])
      dispatch('comments/fetchModel', {key: 'model_uuid', value: parsedCurrentModule.uuid}, {root: true})
  },

  async updateModel({getters, dispatch}, {uuid, property, value}) {
    if(!getters['isFetched'])
      await dispatch('fetchModel')

    let payload = getters['getModule'].find(task => task.uuid === uuid)
    if(!payload)
      dispatch('notification/addNotificationToList', {
        type: 'error',
        message: `Unable to find ${getters['getModuleTitle'].toLowerCase()}.`,
        actions: { close: true }
      }, { root: true })
    payload = getValueWithoutReference(payload)

    payload[property] = value

    await dispatch('serialize', payload)
      .then(serialized => payload = serialized || payload)

    dispatch('updating', {uuid, property})

    api.post(`${formatModuleUrl(module)}/edit`, payload)
      .then(async response => {
        let data = response.data

        await dispatch('parse', data)
          .then(parsedData => data = parsedData || data)

        dispatch('updated', {uuid, property})

        dispatch(`editSingleModel`, {data})

        dispatch('warning/closeModal', null, { root: true })
      })
  },

  validateWebsocketResponse({commit, getters}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        if(getters['cancelDocumentsWebsocket'](event))
          return

        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [DetailActions({module}), DocumentsActions({module}), HistoriesActions({module}), Schema])
