import * as Types from './../../mutationTypes'

export default {
  [Types.WARNING_MODAL_IS_LOADING](state) {
    state.loading = true
  },

  [Types.SET_WARNING_MODAL_DATA](state, data) {
    state.showModal = true
    state.modalTitle = data.title || 'Warning'
    state.modalQuestion = data.question || 'Set modal question...'
    state.modalDescription = data.description || ''
    state.modalDescriptionList = data.list ?? null
    state.showConfirmButton = data.showConfirmButton ?? true
    state.cancelTitle = data?.cancelTitle
    state.confirmTitle = data?.confirmTitle
    state.action = state.showConfirmButton ? data.action : ''
    state.data = state.showConfirmButton ? data.data : {}
  },

  [Types.CLOSE_WARNING_MODAL](state) {
    state.loading = false
    state.showModal = false
    state.modalTitle = 'Warning'
    state.modalQuestion = ''
    state.modalDescription = ''
    state.modalDescriptionList = null
    state.showConfirmButton = true
    state.cancelTitle = null
    state.confirmTitle = null
    state.action = ''
    state.data = {}
  }
}