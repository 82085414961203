import BaseGetters from '@/store/base/getters'
import {isEqual} from "lodash";

export default BaseGetters({
  isUpdatingSchedule: state => ({schedule_uuid, employee_uuid, year, month}) => {
    if(state.updatingSchedules[schedule_uuid])
      return true

    return !!(state.updatingSchedules?.[0] || []).find(updatingSchedule => isEqual(updatingSchedule, {employee_uuid, year, month}))
  },
  findSchedule: ({module}) => (employeeUuid, timekeepingTemplateUuid, scheduleUuid) => {
    return module?.[employeeUuid]?.[timekeepingTemplateUuid]?.[scheduleUuid]
  },
  findScheduleByDuration: ({module}) => (employeeUuid, timekeepingTemplateUuid, year, month) => {
    const modules = Object.values(module?.[employeeUuid]?.[timekeepingTemplateUuid] || {})

    return modules.find(schedule => schedule.year === year && schedule.month === month)
  },
})
