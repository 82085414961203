import BaseActions from "@/store/base/actions";
import ArchivesActions from "@/store/base/archives/actions";
import DocumentsActions from "@/store/base/documents/actions";
import DetailActions from "@/store/base/detail/actions";
import * as Types from "@/store/mutationTypes";
import Schema from "./schema";
import HistoriesActions from "@/store/base/histories/actions";

const module = 'transports'

export default BaseActions({module}, {
  async fetchCurrentModuleEmployees({dispatch, commit, getters}, uuid) {
    // const employees = await dispatch('fetchCurrentModuleSubModules', {submoduleTitle: 'employees', uuid})

    let employees = null

    const transportUuid = uuid || getters.getCurrentModule?.uuid || null
    if(transportUuid) {
      employees = await dispatch('fetchCurrentModuleSubModules', {submoduleTitle: 'employees', uuid: transportUuid})
    }

    if(!Array.isArray(employees))
      employees = []


    commit(Types.SET_CURRENT_MODULE_EMPLOYEES, employees)
  },

  async validateModuleArchive({getters}, uuid) {
    const module = getters['getModuleByKey']('uuid', uuid)

    if(!module)
      return ['Undefined transport.']

    let errors = []

    if(module.empty_seats !== module.seats)
      errors.push('Transport is assigned to employees.')

    if(module.object_no)
      errors.push('Transport is used in an object.')

    return errors
  },

  updateEmployeeFromTransport({commit}, assigned) {
    commit(Types.UPDATE_EMPLOYEE_FROM_CURRENT_TRANSPORT, assigned)
  },

  removeEmployeeFromTransport({commit}, unassigned) {
    commit(Types.REMOVE_EMPLOYEE_FROM_CURRENT_TRANSPORT, unassigned)
  },

  validateWebsocketResponse({commit, getters}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        if(getters['cancelDocumentsWebsocket'](event))
          return

        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [ArchivesActions({module}), DocumentsActions({module}), DetailActions({module}), HistoriesActions({module}), Schema])
