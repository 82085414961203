import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'Fuel card',

  formData: {
    uuid: '',
    number: '',
  },

  dummyData: {
    uuid: '',
    number: '',
  },

  notificationTitle: 'number',
  primaryKey: 'uuid',

  searchBy: ['number'],
})
