import routes from "@/router/routes";

export default {
  methods: {
    // open selected page by route name
    openPage(routeName, openPage) {
      if(openPage) {
        this.$router.push({name: routeName})
        this.$store.dispatch('crmSettings/toggleMenu', false)
      }
    },

    // check if menu must be active
    activeMenu(routeName) {
      if(typeof routeName === "undefined")
        return false

      return this.getCurrentRouteName() === routeName
    },

    getCurrentRouteName() {
      return this.$route.name
    },

    getModuleRoute(module) {
      const moduleArray = Array.isArray(module) ? module : [module]

      return Object.values(routes).find(({meta}) => {
        if(!meta?.module)
          return

        const metaModuleArray = Array.isArray(meta.module) ? meta.module : [meta.module]

        return metaModuleArray.some(metaModule=> moduleArray.includes(metaModule))
      })
    },

    openModule(module, model_slug, second_model_slug) {
      const name = this.getModuleRoute(module)?.name

      const slug = second_model_slug || model_slug

      if(name && slug) {
        this.$router.push({name, params: {slug}})

        return true
      }

      return false
    },
  }
}