import HasFormActions from "@/store/base/hasForm/actions"
import Schema from "@/store/modules/sms/schema"

export default Object.assign(HasFormActions, {
  send({dispatch}, {payload}) {
    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: 'SMS messages sending has been successfully scheduled.',
      actions: {
        close: true,
      }
    }, { root: true })

    dispatch(`${payload.module}/resetChecked`, undefined, {root: true})
  },
}, Schema)