import BaseState from '@/store/base/state'
import MultipleFormState from '@/store/base/multipleFormData/state'

export default BaseState({
  moduleTitle: 'Performance month',

  module: {},

  formData: {
    uuid: '',
    project_uuid: '',
    year: '',
    month: '',
    week: '',
    work_performed: '',
    signed_invoices: '',
    work_uninvoiced: '',
  },

  dummyData: {
    uuid: '',
    project_uuid: '',
    year: '',
    month: '',
    week: '',
    work_performed: '',
    signed_invoices: '',
    work_uninvoiced: '',
  },

  notificationTitle: 'date',
  primaryKey: 'uuid',

  staticCrudActionTitle: 'updated',

  searchBy: [],

  formIdFields: ['project_uuid', 'year', 'month'],
}, [MultipleFormState])