<template lang="pug">
.ms-1.header-item.d-none.d-sm-flex
  button.btn.btn-icon.btn-topbar.btn-ghost-secondary.rounded-circle.light-dark-mode(
    :class="[activeMenu('task-manager') ? 'active' : '']"
    type='button'
    @click.prevent="openPage('task-manager', true )"
    v-b-tooltip.hover
    title="Task manager"
  )
    i.bx.bx-task.fs-22

</template>

<script>
import routerMixin from "@/mixins/routerMixin";

export default {
  name: "TaskManager",

  mixins: [routerMixin]
}
</script>
