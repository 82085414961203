import DocumentSchema from "@/store/base/documents/schema";

export default Object.assign({}, DocumentSchema, {
  serialize({rootGetters, getters}) {
    const { employee_uuid } = rootGetters['form/getFormMetaData']
    const data = getters['getFormData']

    return {
      uuid: data.uuid,
      direction_of_travel: data.direction_of_travel,
      documents: data.documents, //
      car_uuid: data.car_uuid,
      fuel_card_nr: data.fuel_card_nr,
      responsible_person_full_name: data.responsible_person_full_name,
      responsible_person_phone: data.responsible_person_phone,
      workplace_address: data.workplace_address,
      notes: data.notes,
      transport_info: rootGetters['form/getFormAction'] === 'createWithDocuments' ? Object.values(data.transport_info).map((data) => JSON.stringify(data)) : data.transport_info,
      transport_type: data.transport_type,
      trip_destination: data.trip_destination,
      employee_uuid,
    }
  },
})