import * as Types from '@/store/mutationTypes'
import { formatModuleUrl } from '@/helpers/formatters'
import BaseActions from './../../base/actions'
import TimekeepingActions from '@/store/base/timekeeping/actions'
import Schema from './schema'
import api from '@/plugins/axios'

const module = 'employeeTimekeepingTemplates'

export default BaseActions({module}, {
  async fetchModel({commit, getters}, employee_uuid = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post(`${formatModuleUrl(module)}/get-by-key`, {key: 'employee_uuid', value: employee_uuid}, {trigger404: false})
      .then(({data}) => commit(Types.SET_MODEL, typeof data[0] !== 'undefined' ? [data[0]] : []))
  },
  async fetchProjectModels({commit, getters}, project_uuid = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post(`${formatModuleUrl(module)}/get-by-key`, {key: 'project_uuid', value: project_uuid}, {trigger404: false})
      .then(({data}) => commit(Types.SET_MODEL, data))
  },
  setFormObjectData({commit, rootGetters}, {weekday, property, value}) {
    commit(Types.SET_FORM_OBJECT_DATA, {weekday, property, value, formAction: rootGetters['form/getFormAction']})
  },
  fillTemplate({commit, rootGetters}, templateUuid) {
    commit(Types.FILL_TEMPLATE, {templates: rootGetters['settingsTimekeepingTemplates/getModule'], templateUuid})
  },
  emptyFormDaysData({commit}) {
    commit(Types.EMPTY_FORM_DAYS_DATA)
  },

  validateWebsocketResponse({commit, rootGetters}, {type, event}) {
    if(rootGetters['employees/getCurrentModuleUuid'] !== event.data.employee_uuid)
      return

    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break
    }
  },
}, [TimekeepingActions({module}), Schema])
