import markings from "@/mixins/markings";

export default {
  formData: {
    days: {
      monday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      tuesday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      wednesday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      thursday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      friday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      saturday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      sunday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },
    },
  },

  weekdays: {
    monday: {
      title: 'Monday',
      short_title: 'Mon',
    },

    tuesday: {
      title: 'Tuesday',
      short_title: 'Tue',
    },

    wednesday: {
      title: 'Wednesday',
      short_title: 'Wed',
    },

    thursday: {
      title: 'Thursday',
      short_title: 'Thu',
    },

    friday: {
      title: 'Friday',
      short_title: 'Fri',
    },

    saturday: {
      title: 'Saturday',
      short_title: 'Sat',
    },

    sunday: {
      title: 'Sunday',
      short_title: 'Sun',
    },
  },

  absences: markings,
}