import * as Types from './../../mutationTypes'

export default {
  [Types.MOBILE_VIEW](state, show) {
    state.isMobileView = show
  },

  [Types.TOGGLE_MENU](state, data) {
    if(typeof data !== 'undefined' && !data)
      state.showMobileMenu = false
    else
      state.showMobileMenu = !state.showMobileMenu
  }
}