import BaseState from '@/store/base/state'
import DocumentsState from '@/store/base/documents/state'

export default BaseState({
  moduleTitle: 'Warehouse',

  formData: {
    uuid: '',
    title: '',
    tool_no: null,
    project_uuid: null,
    price: '',
    category_uuid: '',
    category_2_uuid: '',
    category_3_uuid: '',
    category_4_uuid: '',
    category_5_uuid: '',
    invoice: '',
    qty: 0,
    qty_type: 'vnt'
  },

  dummyData: {
    title: 'title',
    tool_no: 'unix_time',
    project_uuid: '',
    price: {type: 'integer', from: 100, to: 3000},
    category_uuid: '',
    category_2_uuid: '',
    category_3_uuid: '',
    category_4_uuid: '',
    category_5_uuid: '',
    invoice: '',
    uuid: '',
    qty: {type: 'integer', from: 1, to: 20},
    qty_type: ''
  },

  unassignFormData: {
    return_reason: '',
  },

  searchBy: ['title', 'tool_no', 'using.full_name', 'object_no'],

  unassignReasons: [
    {
      name: 'Returning',
      slug: 'returned',
    },

    {
      name: 'Changing',
      slug: 'changed',
    },

    {
      name: 'Broken',
      slug: 'broke',
    }
  ],
}, [DocumentsState])
