import BaseGetters from '@/store/base/getters'
import MultiFormGetters from '@/store/base/multipleFormData/getters'
import {values} from "lodash";

export default BaseGetters({
  getProjectWeek: ({module}, getters) => (year, month, weekNumber, project) => {
    const singleFormData = getters['getSingleFormData']({
      project_uuid: project.uuid,
      year,
      month,
      week: weekNumber * 1
    })

    if(singleFormData)
      return singleFormData

    return module?.[year]?.[month]?.[weekNumber]?.[project.uuid]
  },

  getModuleByKeysValues: (state, getters) => keysValues => {
    return getters['getModuleArray'].filter(model => Object.keys(keysValues).every(key => model[key] === keysValues[key]))
  },

  getWeekByKeys: ({module}) => (year, month, week, projectUuid) => {
    return module?.[year]?.[month]?.[week]?.[projectUuid]
  },

  getProjectPastWeeks: (state, getters) => (project, year, month) => {
    return getters['getModuleArray'].filter(model => {
      return model.year < year || (model.year === year && model.month <= month) && model.project_uuid === project.uuid
    })
  },

  getModuleArray: ({module}) => {
    let models = []

    values(module).forEach(yearGroup => {
      values(yearGroup).forEach(monthGroup => {
        values(monthGroup).forEach(weekGroup => {
          values(weekGroup).forEach(week => models.push(week))
        })
      })
    })

    return models
  },
}, [MultiFormGetters()], {multipleFormSearchBy: ['project_uuid', 'year', 'month', 'week']})