import BaseState from '@/store/base/state'

export default BaseState({
    moduleTitle: 'Transport defect',

    formData: {
        uuid: '',
        transport_uuid: '',
        defect: '',
        effected_at: '',
        comment: '',
    },

    dummyData: {
        uuid: '',
        transport_uuid: '',
        defect: 'sentence',
        effected_at: {key: 'YYYY-MM-DD', type: 'date'},
        comment: 'description',
    },

    notificationTitle: 'defect',
    primaryKey: 'uuid',

    searchBy: [],
})