import BaseActions from '@/store/base/actions'
import DetailActions from "@/store/base/detail/actions";
import DocumentsActions from "@/store/base/documents/actions";
import ArchivesActions from "@/store/base/archives/actions";
import Schema from "@/store/modules/accommodations/schema";
import * as Types from "@/store/mutationTypes";
import {userCan} from "@/helpers/formatters";

const module = 'accommodations'

export default BaseActions({module}, {
  async currentModuleFetched({dispatch, getters}, {uuid}) {
    if(!uuid)
      return

    if(userCan('view-accommodations-documents'))
      dispatch('fetchDocuments', {model: module, modelUuid: uuid})

    if(getters['getCurrentModuleTab'] === 'residents')
      dispatch('fetchCurrentModuleResidents', uuid)
  },

  async validateModuleArchive({getters}, uuid) {
    const module = getters['getModuleByKey']('uuid', uuid)

    if(!module)
      return ['Undefined accommodation.']

    return 0 < module.employees_count ? ['Accommodation has residents.'] : []
  },

  async fetchCurrentModuleResidents({dispatch, commit, getters}, uuid) {
    let residents = null

    const residentsUuid = uuid || getters.getCurrentModule?.uuid || null

    if(residentsUuid) {
      residents = await dispatch('fetchCurrentModuleSubModules', {submoduleTitle: 'residents', uuid: residentsUuid})
    }

    if(!Array.isArray(residents))
      residents = []

    commit(Types.SET_CURRENT_MODULE_RESIDENTS, residents)
  },

  async parseCurrentModule({getters}, module) {
    const existingModule = getters['getCurrentModule']

    return Object.assign({}, existingModule, {
      residents: existingModule.residents ?? [],
      residentsFetched: existingModule.residentsFetched ?? false,
    }, module)
  },

  updateEmployeeFromAccommodation({commit}, assigned) {
    commit(Types.UPDATE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION, assigned)
  },

  removeEmployeeFromAccommodation({commit}, unassigned) {
    commit(Types.REMOVE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION, unassigned)
  },

  validateWebsocketResponse({dispatch, commit, getters, rootGetters}, {type, event}) {
    const projectUuid = rootGetters['projects/getCurrentModuleUuid']

    switch(type) {
      case 'ModelCreateEvent':
        if(getters['cancelDocumentsWebsocket'](event))
          return

        if(projectUuid && projectUuid === event.data.project_uuid && rootGetters['projects/getCurrentModuleTab'] === 'accommodations')
          dispatch('projects/pushCurrentModuleAccommodation', {
            event: 'create',
            data: event.data,
          }, {root: true})

        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        if(projectUuid && projectUuid === event.data.project_uuid && rootGetters['projects/getCurrentModuleTab'] === 'accommodations')
          dispatch('projects/pushCurrentModuleAccommodation', {
            event: 'update',
            data: event.data,
          }, {root: true})

        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        if(projectUuid && projectUuid === event.data.project_uuid && rootGetters['projects/getCurrentModuleTab'] === 'accommodations')
          dispatch('projects/pushCurrentModuleAccommodation', {
            event: 'delete',
            data: event.data,
          }, {root: true})

        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [ArchivesActions({module}), DocumentsActions({module}), DetailActions({module}), Schema])
