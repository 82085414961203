import * as Types from './../../mutationTypes'
import api from '@/plugins/axios'

import BaseActions from '@/store/base/actions'

export default BaseActions({module: 'roles'}, {
  async fetchDefaultRoleList({commit}) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', 'Default roles')

    await api.get('roles/default-roles')
      .then(res => {
        commit(Types.SET_DEFAULT_ROLES, res.data)
      })
  },

  editRoleAndPermissions({ dispatch, commit }, data) {
    return new Promise(resolve => {
      api.post('roles/edit', data, {suppressErrorHandling: true})
        .then(res => {
          commit(Types.UPDATE_SINGLE_MODEL, res.data)

          this.dispatch('notification/addNotificationToList', {
            type: 'success',
            message: 'Role "' + res.data.new.name + '" was updated.',
            actions: {
              close: true,
            }
          }, { root: true })

          resolve(true)
        })
        .catch((response) => {
          dispatch('form/setFormFieldsErrors', response.errors, {root: true})

          if(Object.keys(response?.errors || {}).length === 1 && Object.keys(response.errors).includes('permissions'))
            dispatch('notification/addNotificationToList', {
              type: 'error',
              message: response.message,
              actions: {
                close: true
              }
            }, {root: true})
        })
    })
  }
})