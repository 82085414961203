import {endOfDay, startOfDay} from "@/helpers/formatters";

export default {
  serialize({rootGetters, getters}) {
    const { employee_uuid } = rootGetters['form/getFormMetaData']
    const data = getters['getFormData']

    return {
      uuid: data.uuid,
      title: data.title,
      training_starts_at: startOfDay(data.training_starts_at),
      training_ends_at: endOfDay(data.training_ends_at),
      duration: data.duration,
      price: data.price,
      employee_uuid,
    }
  },
}