import { isNull, isObject, mapValues } from "lodash";
import moment from "moment";
import store from "@/store";
// ! Necessary import for moment duration to work
import {} from 'moment-duration-format'

export function formatModuleUrl(module) {
  return module.split(/(?=[A-Z])/).join('-').toLowerCase()
}

/**
 * Uppercase first letter of a string
 */
export function ucfirst(text, lowerFirst = true) {
  if(typeof text !== 'string')
    return ''

  const textLower = lowerFirst ? text.toLowerCase() : text

  return textLower.charAt(0).toUpperCase() + textLower.slice(1)
}

/**
 * Set and format the day time to the start of the day.
 *
 * @param date
 * @param format
 * @returns {string}
 */
export function startOfDay(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment(date).startOf('day').format(format)
}

/**
 * Set and format the day time to the end of the day.
 *
 * @param date
 * @param format
 * @returns {string}
 */
export function endOfDay(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment(date).endOf('day').format(format)
}

export function formatDate(date, format = 'YYYY-MM-DD') {
  return moment(date, format).format(format)
}

export function userCan(permission) {
  return !!store.getters['auth/authUserHasPermission'](permission)
}

export function isClient() {
  return store.getters['auth/authUser']?.type === 'client'
}

export function convertTimeToMinutes(time) {
  return moment.duration(time).asMinutes()
}

/**
 * Recursion for setting all object properties to determined value
 */
export function emptyValues(value, setTo = '') {
  return isObject(value) && !isNull(value)? mapValues(value, (valueObject) => emptyValues(valueObject, setTo)) : setTo
}

/**
 * Remove array and object variables references
 */
export function getValueWithoutReference(value) {
  if(Array.isArray(value))
    return [...value].map(arrayItem => getValueWithoutReference(arrayItem))
  else if(isObject(value) && !isNull(value))
    return mapValues(value, (valueObject) => getValueWithoutReference(valueObject))

  return value
}

/**
 * Calculate hours, minutes duration and return it as momentjs duration
 *
 * @param start
 * @param end
 * @returns {boolean|moment.Duration}
 */
export function getTimeDuration({start, end}) {
  if(process.env.VUE_APP_DEBUG_MODE === 'true' && (typeof start !== 'string' || typeof end !== 'string')) {
    console.error('%c>>> getTimeDuration: ', 'color: red', 'INVALID PARAMETERS PASSED')
    return false
  }

  const startTime = moment(`1970-01-01 ${start}`, 'YYYY-MM-DD HH:mm')
  const endTime = moment(`1970-01-01 ${end}`, 'YYYY-MM-DD HH:mm')

  // If work ends in the next day of work start
  if(endTime.diff(startTime) < 0)
    endTime.add(1, 'days')

  return moment.duration(endTime.diff(startTime))
}

export function formatDuration(duration, units, format = 'HH:mm') {
  if(
    format === 'HH:mm' &&
    units === 'minutes' &&
    0 <= duration && duration < 60
  )
    return `00:${addPad(duration)}`

  return moment.duration(duration, units).format(format)
}

export function isPastDate(date, granulity = undefined, checkCurrentDay = false) {
  if(checkCurrentDay)
    return moment().isSameOrAfter(date, granulity)

  return moment().isAfter(date, granulity)
}

export function isFutureDate(date, granulity = undefined, checkCurrentDay = false) {
  if(checkCurrentDay)
    return moment().isSameOrBefore(date, granulity)

  return moment().isBefore(date, granulity)
}

export function isPresentDate(date, granulity) {
  return moment(date).isSame(new Date, granulity)
}

/**
 * Force number or string to be 2 characters length. For example, convert '8' to '08'
 *
 * @param variable
 * @returns {string}
 */
export function addPad(variable) {
  return variable.toString().padStart(2, '0')
}

/**
 * Add pad to month and day
 *
 * @param year
 * @param month
 * @param day
 */
export function normalizeDate(year, month, day) {
  return year && month && day ? `${year}-${addPad(month)}-${addPad(day)}` : undefined
}

export function dateBetweenDates(date, startDate, endDate) {
  if(!date || !startDate || !endDate)
    return false

  const momentDate = moment(date)

  return (momentDate.isAfter(startDate) || momentDate.isSame(startDate)) && (momentDate.isBefore(endDate) || momentDate.isSame(endDate))
}

export function getFutureMoment(startMoment, date, format = 'YYYY-MM-DD HH:mm') {
  const endMoment = moment(date, format)

  if(endMoment.diff(startMoment) < 0)
      endMoment.add(Math.floor((endMoment.diff(startMoment) * -1) / (1000 * 60 * 60 * 24)) + 1, 'days')

  return endMoment
}

export function dateOverlap(startDateStartsAt, startDateEndsAt, endDateStartsAt, endDateEndsAt) {
  const nightWorkStart = moment(`1970-01-01 ${endDateStartsAt}`)
  const nightWorkEnd = getFutureMoment(nightWorkStart, `1970-01-01 ${endDateEndsAt}`)
  const workStart = moment(`1970-01-01 ${startDateStartsAt}`)
  const workEnd = getFutureMoment(workStart, `1970-01-01 ${startDateEndsAt}`)

  return workEnd.isBefore(nightWorkStart) || (nightWorkStart.isBefore(workStart) && nightWorkEnd.isAfter(workStart))
}

export function encodeBase64(string) {
  if(typeof string === 'undefined')
    return null

  return ['string', 'number'].includes(typeof string) ? window.btoa(encodeURIComponent(string)) : string
}

export function isJson(str) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }

    return true
}

export function transformToLatin(text) {
  const map = {'ą': 'a', 'č': 'c', 'ę': 'e', 'ė': 'e', 'į': 'i', 'š': 's', 'ų': 'u', 'ū': 'u', 'ž': 'z', 'Ą': 'A', 'Č': 'C', 'Ę': 'E', 'Ė': 'E', 'Į': 'I', 'Š': 'S', 'Ų': 'U', 'Ū': 'U', 'Ž': 'Z'}

  return text.replace(/[ąčęėįšųūžĄČĘĖĮŠŲŪŽ]/g, function(matched) {
    return map[matched];
  });
}