import {dateOverlap, formatDuration, getTimeDuration, getFutureMoment} from '@/helpers/formatters'
import { has } from 'lodash'
import moment from 'moment'
// ! Necessary import for moment duration to work
import {} from 'moment-duration-format'

export default {
  getDayData: (state, getters, rootState, rootGetters) => (day) => {
    const formData = rootGetters['form/getFormAction'] === 'assignModel' ? 'assignFormData' : 'formData'

    return has(state[formData]?.days || {}, day) ? state[formData].days[day] : null
  },
  getDayWorkHours: (state, getters) => (...parameters) => {
    const dayData = getters['getDayData'](...parameters)

    if(
      has(dayData, 'work_starts_at') &&
      dayData.work_starts_at &&
      has(dayData, 'work_ends_at') &&
      dayData.work_ends_at
    ) {
      const workDuration = getTimeDuration({
        start: dayData.work_starts_at,
        end: dayData.work_ends_at,
      }).as('milliseconds')

      let lunchDuration = 0
      if(
        has(dayData, 'lunch_starts_at') &&
        dayData.lunch_starts_at &&
        has(dayData, 'lunch_ends_at') &&
        dayData.lunch_ends_at
      ) {
        lunchDuration = getTimeDuration({
          start: dayData.lunch_starts_at,
          end: dayData.lunch_ends_at,
        }).as('milliseconds')
      }

      return moment.utc(workDuration - lunchDuration).format('HH:mm')
    }

    return '00:00'
  },
  getNightWorkHours: (state, getters) => (day, weekday, employeeUuid) => {
    const { nightShiftStartTime, nightShiftEndTime } = getters['getNightRate']()

    const { work_starts_at, work_ends_at, lunch_starts_at, lunch_ends_at } = getters['getDayData'](day, weekday, employeeUuid) ?? {}

    if(!work_starts_at || !work_ends_at)
      return '00:00';

    const nightShiftOverlap = dateOverlap(work_starts_at, work_ends_at, nightShiftStartTime, nightShiftEndTime)

    let workStart = null
    let nightWorkStart = null
    if(nightShiftOverlap) {
      nightWorkStart = moment(`1970-01-01 ${nightShiftStartTime}`)
      workStart = work_starts_at === nightShiftStartTime ? nightWorkStart : getFutureMoment(nightWorkStart, `1970-01-01 ${work_starts_at}`)
    }else {
      workStart = moment(`1970-01-01 ${work_starts_at}`)
      nightWorkStart = work_starts_at === nightShiftStartTime ? workStart : getFutureMoment(workStart, `1970-01-01 ${nightShiftStartTime}`)
    }

    const nightWorkEnd = getFutureMoment(nightWorkStart, `1970-01-01 ${nightShiftEndTime}`)
    const workEnd = getFutureMoment(workStart, `1970-01-01 ${work_ends_at}`)

    let nightWorkMinutes = 0

    // If work time interval crosses night work interval
    if(
      (!nightShiftOverlap && workEnd.isAfter(nightWorkStart)) ||
      (nightShiftOverlap && workStart.isBefore(nightWorkEnd))
    ) {
      const nightRateStart = workStart.isSameOrAfter(nightWorkStart) ? workStart : nightWorkStart
      const nightRateEnd = workEnd.isSameOrBefore(nightWorkEnd) ? workEnd : nightWorkEnd

      // Minutes of night rate work
      nightWorkMinutes = moment.duration(nightRateEnd.diff(nightRateStart)).asMinutes()

      // Calculate night rate lunch and subtract it from night rate work minutes
      if(lunch_starts_at && lunch_ends_at) {
        const lunchStart = getFutureMoment(workStart, `${workStart.format('YYYY-MM-DD')} ${lunch_starts_at}`)
        const lunchEnd = getFutureMoment(lunchStart, `1970-01-01 ${lunch_ends_at}`)

        let nightLunchStart = null;
        if(lunchStart.isSameOrBefore(nightRateStart) && nightRateStart.isBefore(lunchEnd))
          nightLunchStart = nightRateStart
        else if(lunchStart.isSameOrAfter(nightRateStart))
          nightLunchStart = lunchStart

        const nightLunchEnd = lunchEnd.isSameOrBefore(nightRateEnd) ? lunchEnd : nightRateEnd

        if(nightLunchStart?.isSameOrAfter(nightWorkStart) && nightLunchEnd?.isAfter(nightLunchStart))
          nightWorkMinutes -= moment.duration(nightLunchEnd.diff(nightLunchStart)).asMinutes()
      }
    }

    return 0 < nightWorkMinutes ? formatDuration(nightWorkMinutes, 'minutes') : '00:00'
  },
  getWeekWorkHours: (state, getters) => {
    let weekWorkMinutes = 0

    Object.keys(getters['getWeekdays']).forEach(weekday => {
      const weekdayWorkTime = moment(getters['getDayWorkHours'](weekday), 'HH:mm')
      weekWorkMinutes += weekdayWorkTime.hours() * 60 + weekdayWorkTime.minutes()
    })

    if(!weekWorkMinutes)
      return '00:00'

    // Convert week work minutes to milliseconds and format
    return formatDuration(weekWorkMinutes * 60 * 1000, 'milliseconds', 'HH:mm')
  },
  getNightRate: () => () => {
    return {
      nightShiftStartTime: '22:00',
      nightShiftEndTime: '06:00',
    }
  },
  getWeekdays: state => state.weekdays,
  getAbsences: state => state.absences,
}
