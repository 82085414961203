import * as Types from "@/store/mutationTypes";

export default () => ({
  fillBlacklistFormData({commit}, data) {
    commit(Types.FILL_BLACKLIST_FORM_DATA, data)
  },

  setBlacklistFormInputData({commit}, data) {
    commit(Types.SET_BLACKLIST_FORM_INPUT_DATA, data)
  },

  emptyBlacklistFormData({commit}) {
    commit(Types.EMPTY_BLACKLIST_FORM_DATA)
  },
})