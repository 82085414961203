<template lang="pug">
.dropdown.topbar-head-dropdown.ms-1.header-item.notification-box(
  v-if="userCan('view-alerts')"
  v-click-outside="hideNotifications"
)
  button#page-header-notifications-dropdown.btn.btn-icon.btn-topbar.btn-ghost-secondary.rounded-circle(
    type='button'
    v-b-tooltip.hover
    title="Notifications"
    @click="showNotifications = !showNotifications"
  )
    i.bx.bx-bell.fs-22
    span.position-absolute.topbar-badge.fs-10.translate-middle.badge.rounded-pill.bg-danger(
      v-if="notifications.length"
    )
      | {{ notifications.length }}
  .dropdown-menu.dropdown-menu-lg.dropdown-menu-end.p-0.notification-box-wrapper(
    :class="{show: showNotifications}"
    aria-labelledby='page-header-notifications-dropdown'
    data-popper-placement='bottom-end'
  )
    .dropdown-head.bg-primary.bg-pattern.rounded-top
      .p-3
        .row.align-items-center
          .col
            h6.m-0.fs-16.fw-semibold.text-white  Notifications
          .col-auto.dropdown-tabs
            span.badge.badge-soft-light.fs-13  {{ notifications.length }} New

    #notificationItemsTabContent.tab-content
      #all-noti-tab.tab-pane.fade.show.active.py-2.ps-2(role='tabpanel')
        .pe-2.notification-body(
          data-simplebar='init'
        )
          .simplebar-wrapper(
            v-if="notifications.length"
          )
            .simplebar-height-auto-observer-wrapper
              .simplebar-height-auto-observer
            .simplebar-mask
              .simplebar-offset(style='right: 0px; bottom: 0px;')
                .simplebar-content-wrapper(tabindex='0' role='region' aria-label='scrollable content' style='height: auto; overflow: hidden scroll;')
                  .simplebar-content(style='padding: 0px 8px 0px 0px;')
                    .text-reset.notification-item.d-block.dropdown-item.position-relative(
                      v-for="(notification, index) in notifications"
                    )
                      .d-flex
                        .avatar-xs.me-3
                          span.avatar-title.bg-soft-info.text-info.rounded-circle.fs-16
                            i(:class="getNotificationIconClass(notification)")

                        .flex-1
                          a.stretched-link(
                            href='#'
                            @click.prevent="openAlertModelDetail(notification)"
                          )
                            h6.mt-0.mb-2.lh-base
                              | {{ getAlertTitle(notification) }}
                          p.mb-0.fs-11.fw-medium.text-uppercase.text-muted
                            span
                              i.mdi.mdi-clock-outline
                              |  {{ formatTimeAgo(notification.created_at) }}

            .simplebar-placeholder(style='width: auto; height: 515px;')
          .simplebar-track.simplebar-horizontal(style='visibility: hidden;')
            .simplebar-scrollbar(style='width: 0px; display: none;')
          .simplebar-track.simplebar-vertical(style='visibility: visible;')
            .simplebar-scrollbar(style='height: 174px; display: block; transform: translate3d(0px, 0px, 0px);')

        .empty(
          v-if="!notifications.length"
        )
          .w-25.w-sm-50.pt-3.mx-auto
            img.img-fluid(src='@/assets/images/svg/bell.svg' alt='user-pic')
          .text-center.pb-5.mt-2
            h6.fs-18.fw-semibold.lh-base You don't have any notifications


      #alerts-tab.tab-pane.fade.p-4(role='tabpanel' aria-labelledby='alerts-tab')
        .w-25.w-sm-50.pt-3.mx-auto
          img.img-fluid(src='/img/bell.a7ab3cae.svg' alt='user-pic')
        .text-center.pb-5.mt-2
          h6.fs-18.fw-semibold.lh-base  Hey! You don't have any notifications

</template>

<script>
import {mapGetters} from "vuex";
import routerMixin from "@/mixins/routerMixin";

export default {
  name: "NotificationsView",

  mixins: [routerMixin],

  data() {
    return {
      showNotifications: false
    }
  },

  computed: {
    ...mapGetters({
      notifications: 'auth/getAlerts',
      getAlertTitle: 'auth/getAlertTitle',
    })
  },

  methods: {
    hideNotifications() {
      this.showNotifications = false
    },

    openAlertModelDetail({alertable_slug, pivot_slug, module}) {
      this.openModule(module, alertable_slug, pivot_slug)

      this.hideNotifications()
    },

    getNotificationIconClass({module}) {
      switch(module) {
        case 'projects':
          return 'ri-projector-2-line'

        case 'accommodations':
          return 'ri-hotel-line'

        case 'transports':
          return 'ri-car-fill'

        case 'employees':
          return 'ri-team-line'

        case 'personCertificates':
          return 'ri-file-paper-2-line'

        case 'taskManagerTasks':
          return 'ri-task-line'
      }
    },
  }
}
</script>
