export default {
  serialize({getters}) {
    const formData = getters['getFormData']

    return {
      uuid: formData?.uuid,
      number: formData?.number,
    }
  },
}
