import BaseActions from './../../base/actions'
import DocumentsActions from "@/store/base/documents/actions";
import * as Types from '../../mutationTypes'

const module = 'automaticDocuments'

export default BaseActions({module}, {
  validateWebsocketResponse({commit}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_AUTOMATIC_DOCUMENTS, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_AUTOMATIC_DOCUMENTS, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.DELETE_AUTOMATIC_DOCUMENTS, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [DocumentsActions({module})])
