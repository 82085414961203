import BaseState from '@/store/base/state'

export default BaseState({
    moduleTitle: 'Alert settings',

    formData: {
      uuid: '',
      title: '',
      description: '',
      years: '',
      months: '',
      days: '',
      hours: '',
      minutes: '',
    },

    dummyData: {
      uuid: '',
      title: '',
      description: 'description',
      years: {key: 'month_number', type: 'number'},
      months: {key: 'month_number', type: 'number'},
      days: {key: 'month_number', type: 'number'},
      hours: {key: 'month_number', type: 'number'},
      minutes: {key: 'month_number', type: 'number'},
    }
})