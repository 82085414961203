export default {
  serialize({getters}) {
    const formData = getters['getFormData']

    return {
      uuid: formData.uuid,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      password: formData.password,
      password_confirm: formData.password_confirm,
      type: formData.type,
      role: formData.role,
      client: formData.client,
      phone_number: formData.phone_number,
      project_uuid: formData.project_uuid,
      employee_uuid: formData.employee_uuid || null,
    }
  },
}