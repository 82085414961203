import BaseState from '@/store/base/state'
import PersonState from '@/store/base/person/state'
import DocumentsState from '@/store/base/documents/state'
import DetailState from "@/store/base/detail/state";
import BlacklistState from "@/store/base/blacklist/state";
import TimekeepingState from "@/store/base/timekeeping/state";
import HistoriesState from "@/store/base/histories/state";

export const employeesSearchBy = ['first_name', 'last_name', 'full_name', 'client', 'object_name', 'certificates.title', 'phone', 'table_number']

export default BaseState({
  moduleTitle: 'Employee',

  formData: {
    uuid: '',
    first_name: '',
    last_name: '',
    personal_number: '',
    company_uuid: '',
    department: '',
    country_id: '',
    city_id: '',
    address: '',
    phone: '',
    email: '',
    declared_address: '',
    residence_address: '',
    residence_type: '',
    residence_duration: '',
    residence_number: '',
    marital_status: '',
    children: '',
    employment_contract_date: '',
    employment_date: '',
    contract_type: '',
    contract_duration: '',
    salary: '',
    full_time_equivalent: '',
    vacation_norm: null,
    work_hours_per_week: null,
    trial_period: '',
    employment_contract_number: null,
    health_certificate_issued_date: '',
    health_certificate_duration_date: '',
    europe_health_insurance_card_duration_date: '',
    has_driving_license: '',
    driving_license_issued_date: '',
    driving_license_duration_date: '',
    table_number: '',
    positions_uuid: [],
    document_type: '',
    document_number: null,
    document_duration: '',
    gender: '',
    citizenship_id: '',
    date_of_birth: '',
    languages: '',
    education: '',
    qualification: '',
    is_manager: false,
    status: '',
    comment: '',
    certificates: [],
  },

  dummyData: {
    uuid: '',
    first_name: 'first_name',
    last_name: 'last_name',
    personal_number: {type: 'integer', from: 1000000, to: 9999999},
    company_uuid: {key: 'slug', type: 'random_element', getterNamespace: 'companies/getModule'},
    department: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getDepartments'},
    country_id: '',
    city_id: '',
    address: 'address1',
    phone: 'phone',
    email: 'email',
    declared_address: 'address2',
    residence_address: 'address1',
    residence_type: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getResidenceTypes'},
    residence_duration:  {key: 'YYYY-MM-DD', type: 'date'},
    residence_number: {type: 'integer', from: 1000000, to: 9999999},
    marital_status: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getMaritalStatuses'},
    children: 'sentence',
    employment_contract_date: {key: 'YYYY-MM-DD', type: 'date'},
    employment_date: {key: 'YYYY-MM-DD', type: 'date'},
    contract_type: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getContractTypes'},
    contract_duration: {key: 'YYYY-MM-DD', type: 'date'},
    salary: {type: 'integer', from: 1000, to: 10000},
    full_time_equivalent: {type: 'double', from: 0.1, to: 1},
    vacation_norm: {type: 'integer', from: 1, to: 5},
    work_hours_per_week: {type: 'integer', from: 0, to: 168},
    trial_period: {key: 'YYYY-MM-DD', type: 'date'},
    employment_contract_number: {type: 'integer', from: 1000000, to: 9999999},
    health_certificate_issued_date: {key: 'YYYY-MM-DD', type: 'date'},
    health_certificate_duration_date: {key: 'YYYY-MM-DD', type: 'date'},
    europe_health_insurance_card_duration_date: {key: 'YYYY-MM-DD', type: 'date'},
    has_driving_license: 'boolean',
    driving_license_issued_date: {key: 'YYYY-MM-DD', type: 'date'},
    driving_license_duration_date: {key: 'YYYY-MM-DD', type: 'date'},
    table_number: {type: 'integer', from: 1, to: 1000},
    document_type: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getDocumentTypes'},
    document_number: {type: 'integer', from: 1000000, to: 9999999},
    document_duration: {key: 'YYYY-MM-DD', type: 'date'},
    gender: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getGenders'},
    citizenship_id: {key: 'id', type: 'random_element', getterNamespace: 'citizenships/getModule'},
    date_of_birth: {key: 'YYYY-MM-DD', type: 'date'},
    languages: 'word',
    education: 'short_description',
    qualification: 'short_description',
    project_uuid: 'title',
    client: 'full_name',
    is_manager: 'boolean',
    status: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getStatuses'},
    comment: 'description',
    certificates: [],
  },

  generateDocumentsFormData: {
    attachment_number: '',

    form: '',
    document_serial_number: '',
    document_date: '',
    first_name: '',
    last_name: '',
    employment_contract_date: '',
    employment_contract_number: '',
    address: '',
    personal_number: '',
    company_uuid: '',
    position: '',
    department: '',
    signer_full_name: '',
    signer: '',
    represented_by: '',
    from_date: '',
    phone: '',
    email: '',
    work_start: '',
    salary: '',
    account_number: '',
    decision: '',
    reason: '',
    sign_date: '',
    vacation_start: '',
    vacation_end: '',
    qualification: '',
    contract_type: '',
    contract_duration: '',
    work_hours_per_week: '',
    trial_period_months: '',
    apply: 0,
    date_of_birth: '',
    work_contract_clause: '',
    work_contract_clause_correction: '',
    work_contract_termination_date: '',
    work_contract_termination_reason: '',
  },

  notificationTitle: 'full_name',
  primaryKey: 'uuid',

  currentModule: {},
  currentModuleTabs: {
    profile: {title: 'Profile', permission: 'view-employees-details'},
    documents: {title: 'Documents', permission: 'view-employees-documents'},
    // TODO: change permission
    workTrip: {title: 'Work trip', permission: 'view-employees-details'},
    accommodation: {title: 'Accommodation', permission: 'view-accommodations'},
    travel: {title: 'Travel', permission: 'view-transports'},
    schedule: {title: 'Schedule', permission: 'view-employee-schedules'},
    warehouse: {title: 'Tools', permission: 'view-warehouse'},
    trainings: {title: 'Trainings', permission: 'view-employee-trainings'},
    history: {title: 'History', permission: 'view-histories'},
    settings: {title: 'Settings', permission: 'edit-employees'}
  },
  currentModuleTab: 'profile',

  scheduleErrors: {},

  assignFormData: {
    module_uuid: '',

    accommodation_uuid: '',
    accommodation_starts_at: '',
    accommodation_ends_at: '',

    transport_uuid: '',
    travel_starts_at: '',
    travel_ends_at: '',
  },

  statuses: [
    {
      name: 'Working in object',
      slug: 'working',
    },
    {
      name: 'On vacation',
      slug: 'vacation',
    },
    {
      name: 'On unpaid vacation',
      slug: 'unpaid_vacation',
    },
  ],

  departments: [
    {
      name: 'Personnel',
      name_lt: 'Personalo skyrius',
      slug: 'personnel'
    },

    {
      name: 'Finance',
      name_lt: 'Finansų skyrius',
      slug: 'finance'
    },

    {
      name: 'Technical and production',
      name_lt: 'Projektai, sandėlis',
      slug: 'technical_and_production'
    },

    {
      name: 'Marketing',
      name_lt: 'Marketingo skyrius',
      slug: 'marketing'
    },

    {
      name: 'Management',
      name_lt: 'Adminstracija, projektų vadovai, koordinatoriai',
      slug: 'management'
    },

    {
      name: 'Law',
      name_lt: 'Teisės skyrius',
      slug: 'law'
    },

    {
      name: 'Sales ',
      name_lt: 'Pardavimų skyrius',
      slug: 'sales'
    },
  ],

  maritalStatuses: [
    {
      name: 'Married',
      slug: 'married'
    },
    {
      name: 'Single',
      slug: 'single'
    },
    {
      name: 'Divorced',
      slug: 'divorced'
    }
  ],

  contractTypes: [
    {
      name: 'Fixed term',
      name_lt: 'Terminuota',
      slug: 'fixed_term',
    },

    {
      name: 'Indefinite',
      name_lt: 'Neterminuota',
      slug: 'indefinite',
    },

    {
      name: 'Temporary work',
      name_lt: 'Laikinojo darbo',
      slug: 'temporary_work'
    },
    {
      name: 'Apprenticeships',
      name_lt: 'Pameistrystės',
      slug: 'apprenticeships'
    },
    {
      name: 'Project work',
      name_lt: 'Projektinio darbo',
      slug: 'project_work'
    }
  ],

  documentTypes: [
    {
      name: 'ID card',
      slug: 'id_card'
    },
    {
      name: 'Passport',
      slug: 'passport'
    }
  ],

  genders: [
    {
      name: 'Male',
      slug: 'male'
    },
    {
      name: 'Female',
      slug: 'female'
    },
    {
      name: 'Other',
      slug: 'other'
    }
  ],

  rotation_table: {
    exporting: false,
    months: [],
  },

  exportingTools: false,

  timekeepingDays: [],

  searchBy: employeesSearchBy,
}, [PersonState, DocumentsState, BlacklistState, DetailState, TimekeepingState, HistoriesState])
