import * as Types from '@/store/mutationTypes'

export default ({module}) => ({
  [Types.SET_ARCHIVE_FORM_INPUT_DATA](state, {key, value}) {
    state.archiveFormData[key] = value
  },

  [Types.EMPTY_ARCHIVE_FORM_DATA](state) {
    state.archiveFormData.uuid = ''
  },

  [Types.ARCHIVE_MODULE](state, data) {
    if(state.module?.['active'] === undefined)
      return

    const index = state.module['active']?.findIndex(module => module.uuid === data?.uuid)

    if(typeof state.module['active'][index] === 'object') {
      let moduleName = state.module['active'][index][state.notificationTitle]

      state.module['active'][index].is_archived = data.is_archived
      state.module['archived'].unshift(state.module['active'][index])
      state.module['active'].splice(index, 1)

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `${state.moduleTitle} "${moduleName}" was archived.`,
        actions: {
          close: true,
        }
      }, { root: true })

      if(state.filtersBy.length)
        this.dispatch(`${module}/filter`, {}, {root: true})
    }
  },

  [Types.UNARCHIVE_MODULE](state, data) {
    const index = state.module['archived']?.findIndex(module => module.uuid === data?.uuid)

    if(typeof state.module['archived'][index] === 'object') {
      let moduleName = state.module['archived'][index][state.notificationTitle]

      state.module['archived'][index].is_archived = data.is_archived
      state.module['active'].unshift(state.module['archived'][index])
      state.module['archived'].splice(index, 1)

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `${state.moduleTitle} "${moduleName}" was unarchived.`,
        actions: {
          close: true,
        }
      }, { root: true })

      if(state.filtersBy.length)
        this.dispatch(`${module}/filter`, {}, {root: true})
    }
  },

  [Types.FILL_ARCHIVE_FORM_DATA](state, uuid) {
    const moduleToArchive = state.module['archived'].concat(state.module['active']).find(el => el.uuid === uuid)

    state.archiveFormData.uuid = moduleToArchive.uuid
  },

  [Types.SET_ARCHIVE_FORM_DATA](state, data) {
    state.archiveFormData = data
  },

  [Types.SET_ARCHIVE_FILTER_STATUS](state, status) {
    state.filteringArchivedModules = status
  },

  [Types.SINGLE_ARCHIVE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index].is_archived = 1
      return
    }

    let index = state.module?.['active']?.findIndex(el => el[state.primaryKey] === data[state.primaryKey])

    if(typeof state.module?.['active']?.[index] !== 'undefined') {
      state.module['active'][index].is_archived = 1
      state.module['archived'].unshift(state.module['active'][index])
      state.module['active'].splice(index, 1)

      if(state.filtersBy.length)
        this.dispatch(`${module}/filter`, {}, {root: true})
    }
  },

  [Types.SINGLE_UNARCHIVE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index].is_archived = 0
      return
    }

    let index = state.module['archived'].findIndex(el => el[state.primaryKey] === data[state.primaryKey])

    if(typeof state.module['archived'][index] !== 'undefined') {
      state.module['archived'][index].is_archived = 0
      state.module['active'].unshift(state.module['archived'][index])
      state.module['archived'].splice(index, 1)

      if(state.filtersBy.length)
        this.dispatch(`${module}/filter`, {}, {root: true})
    }
  },

  // TODO: improve stateVariable usage
  [Types.ADD_TO_MODEL](state, data) {
    if(typeof data.stateVariable === 'string' && state[data.stateVariable]) {
      const stateVariable = data.stateVariable

      delete data.stateVariable

      state[stateVariable].unshift(data)
      return
    }

    state.module[data.is_archived ? 'archived' : 'active'].unshift(data)
  },

  // TODO: improve stateVariable usage
  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined' && state[data.stateVariable]) {
      let index = state[data.stateVariable].findIndex(el => el.uuid === data.uuid)
      let stateVariable = data.stateVariable

      delete data.stateVariable

      state[stateVariable][index] = data
      return
    }

    let key = data.is_archived ? 'archived' : 'active'

    if(typeof state.module[key] !== 'undefined') {
      const index = state.module[key].findIndex(el => el[state.primaryKey] === data[state.primaryKey])

      if(typeof state.module[key][index] !== 'undefined') {
        state.module[key][index] = data

        if(state.filtersBy.length)
          this.dispatch(`${module}/filter`, {}, {root: true})
      }
    }
  },

  // TODO: improve stateVariable usage
  [Types.SINGLE_DELETE_FROM_MODAL](state, data) {
    if(typeof data?.stateVariable !== 'undefined' && state[data.stateVariable]) {
      state[data.stateVariable] = state[data.stateVariable].filter(model => model.uuid !== data.uuid)
      return
    }

    const key = data.is_archived ? 'archived' : 'active'

    if(typeof state.module[key] !== 'undefined') {
      state.module[key] = state.module[key].filter(el => el[state.primaryKey] !== data[state.primaryKey])

      if(state.filtersBy.length)
        this.dispatch(`${module}/filter`, {}, {root: true})
    }
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    data.forEach(oldModule => {
      state.module['archived'] = state.module['archived'].filter(mod => mod[state.primaryKey] !== oldModule[state.primaryKey])

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: state.moduleTitle + ' "' + oldModule[state.notificationTitle] + '" was deleted.',
        actions: {
          close: true,
        }
      }, { root: true })
    })

    if(state.filtersBy.length)
      this.dispatch(`${module}/filter`, {}, {root: true})
  },
})
