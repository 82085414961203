import BaseGetters from '@/store/base/getters'

export default BaseGetters({
  getArray: ({module}) => {
    const categories = []

    Object.values(module).forEach(group => categories.push(...group))

    return categories
  },
  getCategoryListByKey: state => key => {
    return typeof state.module[key] !== 'undefined' ? state.module[key] : []
  },
  fetchedCounts: state => state.fetchedCounts,
  getCategoriesTree: (state, getters) => (uuids) => {
    const tree = { main: [] }

    const categoriesList = getters['getArray']

    const categories = uuids.map(uuid => categoriesList.find(category => category.uuid === uuid))

    categories.forEach(category => {
      if(!category)
        return

      if(!category.parent_uuid) {
        tree.main.push(category)
        return
      }

      if(!Array.isArray(tree[category.parent_uuid]))
        tree[category.parent_uuid] = []

      tree[category.parent_uuid].push(category)
    })

    return tree
  },
})
