import * as Types from './../../mutationTypes'

export default {
  showModal({ commit }, data) {
    commit(Types.SET_ACTION_MODAL_DATA, data)
  },

  closeModal({ dispatch, commit, getters }) {
    const action = getters['getAction']

    commit(Types.CLOSE_ACTION_MODAL)

    if(action === 'signature/signDocument')
      dispatch('signature/emptyFormData', undefined, { root: true })
  },

  modalLoading({ commit }) {
    commit(Types.ACTION_MODAL_IS_LOADING)
  },

  initData({ commit }, data) {
    commit(Types.ACTION_MODAL_INIT_DATA, data)
  },

  setFormInputData({ commit }, data) {
    commit(Types.ACTION_SET_DATA_VALUE, data)
  }
}
