import BaseMutations from '@/store/base/mutations'
import DocumentsMutations from '@/store/base/documents/mutations'
import * as Types from './../../mutationTypes'

const module = 'employeeWorkTrip'

export default BaseMutations({
  [Types.ADD_TO_MODEL](state, data) {
		console.log('ADD_TO_MODEL', data[0])
    state.module.unshift(data[0])
  },
}, [DocumentsMutations(module)])
