<template lang="pug">
header#page-topbar
  .layout-width
    .navbar-header
      .d-flex
        // LOGO
        .navbar-brand-box.horizontal-logo
          a.logo.logo-dark(href='/')
            span.logo-sm
              img(src='@/assets/images/logo-sm.png' alt='' height='40')
            span.logo-lg
              img(src='@/assets/images/logo-dark.png' alt='' height='35')
          a.logo.logo-light(href='/')
            span.logo-sm
              img(src='@/assets/images/logo-sm.png' alt='' height='40')
            span.logo-lg
              img(src='@/assets/images/logo-light.png' alt='' height='35')

        // Mobile hamburger
        button#topnav-hamburger-icon.btn.btn-sm.px-3.fs-16.header-item.vertical-menu-btn.topnav-hamburger(
          type='button'
          @click="toggleMenu"
        )
          span.hamburger-icon
            span
            span
            span

      .d-flex.align-items-center
        // Task manager
        task-manager(
          v-if="userCan('view-task-manager')"
        )

        // Notifications
        notifications

        // Current user menu
        user-menu
</template>

<script>
import UserMenu from "@/components/topBar/UserMenu";
import Notifications from "@/components/topBar/notifications";
import TaskManager from "@/components/topBar/TaskManager";

export default {
  name: "TopBar",

  components: {
    TaskManager,
    Notifications,
    UserMenu
  },

  methods: {
    toggleMenu() {
      this.$store.dispatch('crmSettings/toggleMenu')
    }
  }
}
</script>

<style scoped>

</style>