import BaseGetters from '@/store/base/getters'
import TimekeepingGetters from '@/store/base/timekeeping/getters'

export default BaseGetters({
  getTemplate: (state, getters) => getters['getModule'][0] || null,
  getTemplateWeekDay: (state, getters) => (weekday, employeeUuid) => {
    if(!getters['getTimekeepingTemplate'](employeeUuid))
      return null

    let weekdayIndex = weekday
    if(typeof weekday === 'number')
      weekdayIndex = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ][weekday - 1]

    return getters['getTimekeepingTemplate'](employeeUuid)?.days[weekdayIndex] || null
  },
  getTimekeepingTemplate: (state) => (employeeUuid) => {
    return Object.values(state.module[employeeUuid] || {})[0] || undefined
  },
}, [TimekeepingGetters])
