import BaseMutations from '@/store/base/mutations'
import DocumentsMutations from "@/store/base/documents/mutations";
import * as Types from '@/store/mutationTypes'

const module = 'automaticDocuments'

export default BaseMutations({
  [Types.ADD_AUTOMATIC_DOCUMENTS](state, data) {
		const index = state.module.findIndex(({uuid}) => uuid === data.base_model_uuid)

		if(0 <= index)
			state.module[index].documents.push(data)
	},

  [Types.UPDATE_AUTOMATIC_DOCUMENTS]() {

	},

  [Types.DELETE_AUTOMATIC_DOCUMENTS](state, data) {
		const index = state.module.findIndex(({uuid}) => uuid === data.base_model_uuid)

		if(0 <= index)
			state.module[index].documents = state.module[index].documents.filter(({uuid}) => uuid !== data.uuid)
	},
}, [DocumentsMutations(module)])
