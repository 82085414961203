import {isUndefined} from 'lodash'

export default {
  serialize(context, payload) {
    return {
      uuid: payload.uuid,
      work_starts_at: isUndefined(payload.work_starts_at) ? null : payload.work_starts_at,
      work_ends_at: isUndefined(payload.work_ends_at) ? null : payload.work_ends_at,
      lunch_starts_at: isUndefined(payload.lunch_starts_at) ? null : payload.lunch_starts_at,
      lunch_ends_at: isUndefined(payload.lunch_ends_at) ? null : payload.lunch_ends_at,
      absence: payload.absence,
      model: payload.model,
      model_uuid: payload.model_uuid,
      year: payload.year,
      month: payload.month,
      day: payload.day,
    }
  },
}

