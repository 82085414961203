<template lang="pug">
.user-avatar.avatar-xs(:title="formatTitle(firstName, lastName)")
  | {{ formatInitials(firstName, lastName) || defaultInitials }}

</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'UserAvatar',

  props: {
    firstName: {
      type: String,
      default: ''
    },

    lastName: {
      type: String,
      default: ''
    },

    defaultInitials: {
      type: String,
      default: '',
      validator: (value) => value.length <= 3
    }
  },

  computed: {
    ...mapGetters({
      formatInitials: 'users/formatInitials'
    })
  },

  methods: {
    formatTitle(firstName, lastName) {
      let title = ''

      if(typeof firstName === 'string')
        title = firstName

      if(typeof lastName === 'string')
        title += ' ' + lastName

      return title.trim() || 'System'
    }
  }
}
</script>