export default {
  getFormData: state => state.formData,
  getInputData: state => key => state.formData[key],
  getDummyData: state => state.dummyData,
  getRangeInputData: (state, getters, rootState, rootGetters) => (fromKey, toKey) => {
    let getInputData = getters['getInputData']

    switch(rootGetters['form/getFormAction']) {
      case 'assignModel':
        getInputData = getters['getAssignInputData']
        break

      case 'unassignModel':
        getInputData = getters['getUnassignInputData']
        break
    }

    return {from: getInputData(fromKey), to: getInputData(toKey)}
  },
  getAssignFormData: state => state.assignFormData,
  getUnassignFormData: state => state.unassignFormData,
  getGenerateDocumentsFormData: state => state.generateDocumentsFormData,
  getAssignInputData: state => key => state.assignFormData[key],
  getUnassignInputData: state => key => state.unassignFormData[key],
  getGenerateDocumentsInputData: state => key => state.generateDocumentsFormData[key],
}