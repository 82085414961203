import DocumentTypes from "@/mixins/generateableDocumentsTypes";
import DocumentSchema from "@/store/base/documents/schema";
import moment from "moment";

export default Object.assign({}, DocumentSchema, {
  serialize({getters, rootGetters}, payload) {
    const formMetaData = rootGetters['form/getFormMetaData']
    let formData = getters['getFormData']

    /* eslint-disable no-case-declarations */
    switch(rootGetters['form/getFormName']) {
      case 'accommodationAssign':
        formData = getters['getAssignFormData']

        return {
          module_uuid: formMetaData.module_uuid || formData.module_uuid,
          assign_uuid: formData.accommodation_uuid || formMetaData.accommodation_uuid,
          accommodation_starts_at: formData.accommodation_starts_at,
          accommodation_ends_at: formData.accommodation_ends_at,
        }

      case 'transportAssign':
        formData = getters['getAssignFormData']

        return {
          module_uuid: formMetaData.module_uuid || formData.module_uuid,
          assign_uuid: formData.transport_uuid || formMetaData.transport_uuid,
          travel_starts_at: formData.travel_starts_at,
          travel_ends_at: formData.travel_ends_at,
        }

      case 'projectAssign':
        return {
          module_uuid: payload.module_uuid,
          assign_uuid: payload.assign_uuid,
        }

      case 'generateDocuments':
        formData = getters['getGenerateDocumentsFormData']

        const fields = DocumentTypes?.[formData.form]?.fields || []

        let serialized = {}
        // Map each document type field with formData values
        fields.forEach(field => serialized[field] = formData?.[field])

        if(serialized?.date_of_birth)
          serialized.date_of_birth = moment(serialized.date_of_birth).format('YYYY-MM-DD')

        return Object.assign(serialized, {
          form: formData.form,
          base_model_uuid: formMetaData.base_model_uuid,
        })
    }

    const serialized = {
      uuid: formData.uuid,
      first_name: formData.first_name,
      last_name: formData.last_name,
      personal_number: formData.personal_number,
      company_uuid: formData.company_uuid,
      department: formData.department,
      country_id: formData.country_id,
      city_id: formData.city_id,
      address: formData.address,
      phone: formData.phone,
      email: formData.email,
      declared_address: formData.declared_address,
      residence_address: formData.residence_address,
      residence_type: formData.residence_type,
      residence_duration: formData.residence_duration,
      residence_number: formData.residence_number,
      marital_status: formData.marital_status,
      children: formData.children,
      employment_contract_date: formData.employment_contract_date,
      employment_date: formData.employment_date,
      contract_type: formData.contract_type,
      contract_duration: formData.contract_duration,
      salary: formData.salary,
      full_time_equivalent: formData.full_time_equivalent,
      vacation_norm: formData.vacation_norm,
      work_hours_per_week: formData.work_hours_per_week,
      trial_period: formData.trial_period,
      employment_contract_number: formData.employment_contract_number,
      health_certificate_issued_date: formData.health_certificate_issued_date,
      health_certificate_duration_date: formData.health_certificate_duration_date,
      europe_health_insurance_card_duration_date: formData.europe_health_insurance_card_duration_date,
      has_driving_license: !!formData.has_driving_license,
      table_number: formData.table_number,
      positions_uuid: formData.positions_uuid ? Object.values(formData.positions_uuid) : undefined,
      document_type: formData.document_type,
      document_number: formData.document_number,
      document_duration: formData.document_duration,
      gender: formData.gender,
      citizenship_id: formData.citizenship_id,
      date_of_birth: formData.date_of_birth,
      languages: formData.languages,
      education: formData.education,
      qualification: formData.qualification,
      project_uuid: formData.project_uuid,
      client: formData.client,
      is_manager: !!formData.is_manager,
      status: formData.status,
      comment: formData.comment,
      certificates: formData.certificates,
    }

    if(serialized.has_driving_license) {
      serialized.driving_license_issued_date = formData.driving_license_issued_date
      serialized.driving_license_duration_date = formData.driving_license_duration_date
    }

    return serialized
  },

  async parseCurrentModule({getters}, module) {
    const existingModule = getters['getCurrentModule']

    return Object.assign({}, existingModule, {
      warehouse: existingModule.warehouse ?? [],
      warehouseFetched: existingModule.warehouseFetched ?? false,
      accommodation: module.accommodation_uuid ? (existingModule.accommodation ?? {}) : {},
      accommodationFetched: existingModule.accommodationFetched ?? false,
      transport: module.transport_uuid ? (existingModule.transport ?? {}) : {},
      transportFetched: existingModule.transportFetched ?? false,
      scheduleYear: existingModule.scheduleYear ?? null,
      scheduleMonth: existingModule.scheduleMonth ?? null,
      exportingSchedule: existingModule.exportingSchedule ?? false,
    }, module)
  },

  serializeFire({getters}) {
    const formData = getters['getBlacklistFormData']

    return {
      uuid: formData.uuid,
      is_blacklisted: !!formData.is_blacklisted,
      blacklisted_reason: formData.is_blacklisted ? formData.blacklisted_reason : undefined,
      fired_date: formData.fired_date,
      fire_reason_category: formData.fire_reason_category,
      fire_official_comment: formData.fire_official_comment,
      fire_comment: formData.fire_comment,
    }
  },
})
