export default {
  blacklistFormData: {
    is_blacklisted: false,
    blacklisted_reason: '',
    fired_date: null,
    fire_reason_category: null,
    fire_official_comment: '',
    fire_comment: '',
  }
}
