import BaseActions from '@/store/base/actions'
import Schema from "@/store/modules/employeeTrainings/schema";
import * as Types from './../../mutationTypes'

const module = 'employeeTrainings'

export default BaseActions({module}, {
  validateWebsocketResponse({commit, rootGetters}, {type, event}) {
    if(rootGetters['employees/getCurrentModule']?.uuid !== event.data.employee_uuid)
      return

    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break
    }
  },
}, [Schema])