import BaseGetters from '@/store/base/getters'

export default BaseGetters({
  getCategoryListByKey: state => key => {
    return typeof state.module[key] !== 'undefined' ? state.module[key] : []
  },
  fetchedCounts: state => state.fetchedCounts,
  getTitle: state => titleUuid => {
    if(!Array.isArray(state.prefetchedCategories) || typeof titleUuid !== 'string' || !titleUuid?.length)
      return ''

    return state.prefetchedCategories.find(category => category.uuid === titleUuid)?.title || ''
  },
  getPrefetchedCategories: state => state.prefetchedCategories
})