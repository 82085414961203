import BaseActions from './../../base/actions'
import PersonActions from '@/store/base/person/actions'
import DetailActions from "@/store/base/detail/actions"
import DocumentsActions from '@/store/base/documents/actions'
import BlacklistActions from '@/store/base/blacklist/actions'
import * as Types from '@/store/mutationTypes'
import Schema from './schema'
import api from '@/plugins/axios'
import router from "@/router";
import {formatModuleUrl} from "@/helpers/formatters";

const module = 'candidates'

export default BaseActions({module}, {
  async currentModuleFetched({dispatch}, parsedCurrentModule) {
    if(parsedCurrentModule?.uuid)
      dispatch('comments/fetchModel', {key: 'model_uuid', value: parsedCurrentModule.uuid}, {root: true})
  },

  filter({commit, getters}) {
    let prefilters = []

    const statusFilter = getters['getFilterData']('is_blacklisted')

    if(statusFilter === 0)
      commit(Types.REMOVE_FILTER_MODULE, {key: 'is_blacklisted'})

    if([undefined, 0, ''].includes(statusFilter))
      prefilters.push({
        key: 'is_blacklisted',
        value: 0,
      })

    commit(Types.FILTER_MODULE, {
      searchBy: 'searchBy',
      rootModule: 'module',
      prefilters,
    })

    let statistics = {
      count: 0,
      period: {
        from: undefined,
        to: undefined,
      }
    }

    if(getters['showSearchResults']) {
      const period = getters['getFilterData']('fired_date')

      statistics = {
        count: getters['getSearchResults'].length,
        period: {
          from: period?.from,
          to: period?.to,
        }
      }
    }

    commit(Types.UPDATE_FIRED_STATISTICS, statistics)
  },

  validateEdit({dispatch, getters}, {uuid, title}) {
    let module = getters['getModule']?.find(module => module.uuid === uuid)

    if(!module && getters['getCurrentModule']?.uuid === uuid)
      module = getters['getCurrentModule']

    if(module.is_blacklisted) {
      dispatch('warning/showWarningModal', {
          question: `You can't edit "${title}" because of the remaining errors:`,
          description: '',
          list: ["Blacklisted candidates can't be edited."],
          action: '',
          showConfirmButton: false
      }, {root: true})

      return
    }

    dispatch('form/showForm', {
        model: 'candidates',
        action: 'edit',
        show: true,
        title: `Edit: ${title}`,
        value: uuid
      }, {root: true})
  },

  async unblacklistModule({dispatch, commit, getters}, data) {
    api.post(`${formatModuleUrl(module)}/unblacklist`, {uuid: data.value})
      .then(async res => {
        let responseData = res?.data

        if(
          responseData?.type !== 'candidate' ||
          responseData?.is_blacklisted
        )
          new Error()

        await dispatch('parse', responseData)
          .then(parsedData => responseData = parsedData !== null ? parsedData : responseData)

        commit(Types.UNBLACKLIST_MODULE, responseData)

        dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `"${responseData[getters['getNotificationKey']]}" was successfully unblacklisted`,
          actions: { close: true }
        }, { root: true })
      })
      .catch(() => {
        dispatch('notification/addNotificationToList', {
          type: 'error',
          message: `Failed to unblacklist "${data[getters['getNotificationKey']]}"`,
          actions: {
            close: true,
          }
        }, { root: true })
      })

    dispatch('warning/closeModal', null, {root: true})
  },

  addFiredEmployee({commit}, firedEmployee) {
    commit(Types.ADD_FIRED_EMPLOYEE, firedEmployee)

    if(router.currentRoute.value.name === 'profile')
      router.replace({name: 'candidate', params: {slug: firedEmployee.slug}})
  },

  hire({dispatch}, {value}) {
    api.post(`${formatModuleUrl(module)}/hire`, {uuid: value})
      .then(async res => {
        let responseData = res?.data

        if(responseData?.type !== 'employee')
          new Error()

        await dispatch('employees/parse', responseData, {root: true})
          .then(parsedData => responseData = parsedData !== null ? parsedData : responseData)

        dispatch('hireCandidate', responseData)
      })
      .catch(() => {
        dispatch('notification/addNotificationToList', {
          type: 'error',
          message: 'Candidate employment was unsuccessful',
          actions: { close: true }
        }, { root: true })
      })

    dispatch('warning/closeModal', null, {root: true})
  },

  hireCandidate({dispatch, commit, rootGetters}, hiredCandidate) {
    commit(Types.HIRE_CANDIDATE, hiredCandidate)

    dispatch('employees/addHiredCandidate', hiredCandidate, {root: true})
      .then(() => {
        dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `"${hiredCandidate[rootGetters['employees/getNotificationKey']]}" was successfully hired`,
          actions: { close: true }
        }, { root: true })
      }).catch(() => {
        dispatch('notification/addNotificationToList', {
          type: 'error',
          message: `Failed to hire "${hiredCandidate[rootGetters['employees/getNotificationKey']]}"`,
          actions: { close: true }
        }, { root: true })
      })
  },

  blacklistedModel({commit}, data) {
    commit(Types.BLACKLISTED_MODULE, data)
  },

  validateWebsocketResponse({commit, getters}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        if(getters['cancelDocumentsWebsocket'](event))
          return

        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [PersonActions, DocumentsActions({module}), BlacklistActions({module}), DetailActions({module}), Schema])
