import * as Types from './../mutationTypes'
import HasFormMutations from "@/store/base/hasForm/mutations"

export default (mutations = {}, extensions = []) => Object.assign({}, HasFormMutations,{
  [Types.SET_MODEL](state, data) {
    state.module = data
    state.isFetched = true
  },

  [Types.ADD_TO_MODEL](state, data) {
    state.module.unshift(data)
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    data.forEach(oldModule => {
      state.module = state.module.filter(mod => mod[state.primaryKey] !== oldModule[state.primaryKey])

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: state.moduleTitle + ' "' + oldModule[state.notificationTitle] + '" was deleted.',
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    let index = state.module.findIndex(el => el[state.primaryKey] === data[state.primaryKey])
    state.module[index] = data
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, data) {
      state.module = state.module.filter(role => role[state.primaryKey] !== data[state.primaryKey])
  },

  [Types.FILTER_MODULE](state, {searchBy, rootModule, prefilters = []}) {
    if(
        (state.filtersBy.length <= 0 && state.searchValue.length <= 1) ||
        !Array.isArray(state.filtersBy) ||
        !Array.isArray(state[searchBy])
    ) {
      state.moduleSearchResults = []
      state.showSearchResults = false
      return
    }

    let module = rootModule.split('.')
    module = module.length <= 1 ? state[module[0]] : state[module[0]][module[1]]

    const filters = [...state.filtersBy, ...prefilters]

    state.moduleSearchResults = module.filter(el => {
      let show = true
      let filtersCount = filters.length

      let filtersMatchingCount = 0
      filters.forEach(fil => {
        if(
          el[fil.key] === fil.value ||
          (
            // !isNaN(fil.value?.from) &&
            // !isNaN(fil.value?.to) &&
            !fil?.isInterval &&
            fil.value.from <= el[fil.key] &&
            el[fil.key] <= fil.value.to
          ) ||
          (
            fil?.isInterval &&
            el[fil.keyFrom] <= fil.value.from &&
            fil.value.to <= el[fil.keyTo]
          ) ||
          (
            Array.isArray(el[fil.key]) &&
            el[fil.key].includes(fil.value)
          ) ||
          (
            Array.isArray(fil.value) &&
            !Array.isArray(el[fil.key]) &&
            fil.value.includes(el[fil.key])
          )
        )
          filtersMatchingCount++
      })

      if(filtersCount !== filtersMatchingCount)
          show = false

      if(state.searchValue.length >= 2 && show) {
        const fieldsMatchingSearchValue = state[searchBy].filter(searchKey => {
          if(searchKey.includes('.')) {
            const nestedKeys = searchKey.split('.')

            if(Array.isArray(el?.[nestedKeys[0]]))
              return el[nestedKeys[0]].find(subElement => subElement[nestedKeys[1]].toLowerCase().includes(state.searchValue.toLowerCase()))

            return el?.[nestedKeys[0]]?.[nestedKeys[1]].toLowerCase().includes(state.searchValue.toLowerCase())
          }else if(Array.isArray(el[searchKey])) {
            return el[searchKey].filter(elArrayValue => elArrayValue.toLowerCase().includes(state.searchValue.toLowerCase())).length
          }

          return el[searchKey]?.toLowerCase()?.includes(state.searchValue.toLowerCase())
        })

        show = !!fieldsMatchingSearchValue.length
      }
      return show
    })

    state.showSearchResults = true
  },

  [Types.REMOVE_EMPTY_FILTERS](state) {
    state.filtersBy = state.filtersBy.filter(filter => {
      if(filter.value === 0)
        return true

      return (filter.value ?? false) || filter?.value?.from && filter?.value?.to
    })
  },

  [Types.ADD_FILTER_MODULE](state, data) {
    if(data.value === '')
      return

    if(data.value === true)
      data.value = 1
    else if(data.value === false)
      data.value = 0

    state.filtersBy.push(data)
  },

  [Types.ADD_INTERVAL_FILTER_MODULE](state, data) {
    state.filtersBy.push(data)
  },

  [Types.REMOVE_FILTER_MODULE](state, data) {
    if(data.value === true)
      data.value = 1

    if(data.value === false)
      data.value = 0

    state.filtersBy = state.filtersBy.filter(el => el.key !== data.key || (data?.isInterval && el.keyFrom !== data.keyFrom && el.keyTo !== data.keyTo))
  },

  [Types.CLEAR_ALL_FILTER_MODULE](state, data) {
    state.filtersBy = data
  },

  [Types.SEARCH_BY_TEXT](state, data) {
    state.searchValue = data.trim()
  },

  [Types.CLEAR_SEARCH](state) {
    state.searchValue = ''
  },

  [Types.UNSET_MODULES](state) {
    state.module = Array.isArray(state.module) ? [] : {}
    state.isFetched = false
  },

  [Types.APPROVE_MODEL]() {},

  [Types.REFUSE_MODEL]() {},

  [Types.UPDATING_MODEL](state, {uuid, property}) {
    state.updating[uuid] = [property]
  },

  [Types.UPDATED_MODEL](state, {uuid}) {
    if(Object.keys(state.updating).includes(uuid))
      delete state.updating[uuid]
  },

  [Types.CHECK_MODULE](state, {values, append = true}) {
    const checked = append ? Object.assign({}, state.checked) : {}

    if(Array.isArray(values))
      values.forEach(primaryKeyValue => checked[primaryKeyValue] = true)
    else
      checked[values] = true

    state.checked = checked
  },

  [Types.UNCHECK_MODULE](state, primaryKeyValue) {
    if(Object.keys(state.checked).includes(primaryKeyValue))
      delete state.checked[primaryKeyValue]
  },

  [Types.RESET_CHECKED](state) {
    state.checked = {}
  },
}, ...extensions, mutations)
