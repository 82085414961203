import BaseState from '@/store/base/state'

export default BaseState({
    moduleTitle: 'Project categories',
    module: {},

    formData: {
      uuid: '',
      title: '',
      parent_uuid: '',
      active: false
    },

    dummyData: {
      uuid: '',
      title: 'title',
      parent_uuid: '',
      level_depth: '',
      active: 'boolean',
    },

    fetchedCounts: 0,

    // All categories prefetched
    prefetchedCategories: null
})