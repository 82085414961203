import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import DocumentsState from "@/store/base/documents/state";
import HasFormState from "@/store/base/hasForm/state"

export default {
  namespaced: true,

  state: () => Object.assign({}, DocumentsState, HasFormState, {
    moduleTitle: 'Signature',

    massSignDocuments: [],
    documents: [],
    employees: [],
    positions: [],
    exclude_employees: [],
    exclude_positions: [],
    selectedAll: false,
    toSms: false,
    toEmail: true,
    qualified: true,

    formData: {
      email: [],
    },
  }),
  getters,
  actions,
  mutations
}
