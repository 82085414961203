import BaseGetters from '@/store/base/getters'
import MultiFormGetters from '@/store/base/multipleFormData/getters'
import {values} from "lodash";

export default BaseGetters({
  getMarkings: ({markings}) => markings,

  getMarkingName: ({markings}) => marking => markings.find(({slug}) => slug === marking)?.name || undefined,

  getModuleArray: ({module}) => {
    let models = []

    values(module).forEach(employeeIndex => {
      values(employeeIndex).forEach(yearIndex => {
        values(yearIndex).forEach(monthIndex => {
          values(monthIndex).forEach(week => models.push(week))
        })
      })
    })

    return models
  },

  getRotationDay: (state, getters) => (employee, year, month, day, withMultipleFormData = true) => {
    if(withMultipleFormData) {
      const singleFormData = getters['getSingleFormData']({
        employee_uuid: employee.uuid,
        year,
        month,
        day,
      })

      if(singleFormData)
        return singleFormData
    }

    return getters['getByIndex'](employee.uuid, year, month, day)
  },

  getModuleByKeysValues: (state, getters) => (keysValues) => {
    return getters['getModuleArray'].filter(model => {
      return Object.keys(keysValues).every((key) => model[key] === keysValues[key])
    })
  },

  getByPrimaryKey: (state, getters) => searchable => {
    let primaryKey = getters['getPrimaryKey']

    return getters['getModuleArray'].find(model => model[primaryKey] === searchable[primaryKey])
  },
}, [MultiFormGetters()])