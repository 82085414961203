import api from '@/plugins/axios';
import * as Types from './../../mutationTypes'
import HasFormActions from "@/store/base/hasForm/actions";
import Schema from './schema'

const module = 'locations'

export default Object.assign({}, HasFormActions, Schema, {
  async fetchAllLocations({commit}) {
    await api.get('locations/all')
      .then(response => {
        commit(Types.SET_LOCATIONS, response.data)
      })
  },

  addToModel({commit, getters}, data) {
    commit(Types.ADD_TO_MODEL, data.data)

    commit(Types.EMPTY_FORM_DATA)

    const modelNotificationTitle = getters['getNotificationTitle'](data.data)

    const staticActionCRUD = getters['getStaticCrudAction']

    this.dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `${getters['getModuleTitle']} ${modelNotificationTitle ? '"' + modelNotificationTitle + '" ' : ''}was ${staticActionCRUD || 'created'}.`,
      actions: {
        close: true,
      }
    }, { root: true })
  },

  async editSingleModel({commit, getters}, data) {
    commit(Types.UPDATE_SINGLE_MODEL, data.data)

    const modelNotificationTitle = getters['getNotificationTitle'](data.data)

    const staticActionCRUD = getters['getStaticCrudAction']

    this.dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `${getters['getModuleTitle']} ${modelNotificationTitle ? '"' + modelNotificationTitle + '" ' : ''}was ${staticActionCRUD || 'updated'}.`,
      actions: {
        close: true,
      }
    }, { root: true })
  },

  delete({commit}, data) {
    let url = module.split(/(?=[A-Z])/).join('-').toLowerCase();

    api.post(url + '/delete', data)
      .then(res => {
        commit(Types.DELETE_FROM_MODULE, res.data)

        // Close warning modal after delete
        this.dispatch('warning/closeModal', { root: true })
      })
      .catch(() => {
        this.dispatch('warning/closeModal', { root: true })
      })
  },

  validateWebsocketResponse({commit}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break
    }
  },

  async updatedSingleModel() {},
})