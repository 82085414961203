import BaseActions from '@/store/base/actions'
import api from "@/plugins/axios";
import * as Types from "@/store/mutationTypes";
import ArchivesActions from "@/store/base/archives/actions";
import DocumentsActions from "@/store/base/documents/actions";
import DetailActions from "@/store/base/detail/actions";
import Schema from "@/store/modules/projects/schema";
import {formatModuleUrl, isClient} from "@/helpers/formatters";
import moment from "moment";
import {padStart} from "lodash";
import {downloadFile} from "@/helpers/files";
import TimekeepingActions from "@/store/base/timekeeping/actions";

const module = 'projects'

export default BaseActions({module}, {
  /**
   * Fetch current module by slug if string is passed or by 'data.key' if object is passed
   */
  async fetchCurrentModule({dispatch, commit, getters}, data) {
    let currentModule = null

    const key = typeof data?.key !== 'undefined' ? data.key : 'slug'
    const value = typeof data?.value !== 'undefined' ? data.value : data

    if(isClient() && !getters['isFetched'])
      await dispatch('fetchModel', undefined)

    if(typeof value === 'string' && value.length) {
      currentModule = getters.getModule.find(module => module[key] === value)

      if(!currentModule) {
        await api.post(`/${formatModuleUrl(module)}/get-by-key`, {
          key,
          value
        }).then(response => {
          currentModule = response?.data[0]
        })
      }

      if(currentModule) {
        await dispatch('parseCurrentModule', currentModule).then((response) => currentModule = response !== null ? response : currentModule)

        dispatch('currentModuleFetched', currentModule)
      }
    }

    commit(Types.SET_CURRENT_MODULE, currentModule)
  },

  async fetchCurrentModuleAccommodations({commit, getters}, uuid) {
    let accommodations = null

    const accommodationsUuid = uuid || getters.getCurrentModule?.uuid || null

    if(accommodationsUuid)
      await api.post('/accommodations/get-by-key', {
          key: 'project_uuid',
          value: accommodationsUuid,
        }, { trigger404: false }).then(response => {
          accommodations = response?.data
        })

    if(!Array.isArray(accommodations))
      accommodations = []

    commit(Types.SET_CURRENT_MODULE_ACCOMMODATIONS, accommodations)
  },

  async validateModuleArchive({getters}, uuid) {
    const module = getters['getModuleByKey']('uuid', uuid)

    if(!module)
      return ['Undefined project.']

    let errors = []

    if(0 < module.employees_no)
      errors.push('Project has employees.')

    // TODO: check if all hours are confirmed

    return errors
  },

  async fetchCurrentModuleEmployees({commit, getters}, currentModuleUuid) {
    let employees = null

    const projectUuid = currentModuleUuid || getters.getCurrentModule?.uuid || null
    if(projectUuid) {
      await api.post('projects/employees/all', {
        module_uuid: projectUuid
      }).then(response => {
        employees = response.data
      }).catch(() => {})
    }

    if(!Array.isArray(employees))
      employees = []

    commit(Types.SET_CURRENT_MODULE_EMPLOYEES, employees)
  },

  async fetchClientProjectEmployees({dispatch, getters}) {
    const clientProject = getters['getModule'][0]

    if(clientProject?.uuid)
      dispatch('fetchCurrentModuleEmployees', clientProject.uuid)
  },

  async fetchCurrentModuleTools({commit, getters}, currentModuleUuid) {
    let tools = null

    const projectUuid = currentModuleUuid || getters.getCurrentModule?.uuid || null
    if(projectUuid) {
      await api.post('projects/tools/all', {
        module_uuid: projectUuid
      }).then(response => {
        tools = response.data
      }).catch(() => {})
    }

    if(!Array.isArray(tools))
      tools = []

    commit(Types.SET_CURRENT_MODULE_WAREHOUSE, tools)
  },

  async parseCurrentModule({getters}, module) {
    const existingModule = getters['getCurrentModule']

    return Object.assign({}, existingModule, {
      employees: existingModule.employees ?? [],
      employeesFetched: existingModule.employeesFetched ?? false,
      tools: existingModule.tools ?? [],
      toolsFetched: existingModule.toolsFetched ?? false,
    }, module)
  },

  async unassignedEmployee({dispatch, commit, getters, rootGetters}, {unassignedModel, unassignedFrom, triggerNotification = true}) {
    commit(Types.PROJECT_UNASSIGN_EMPLOYEE, {
      project: unassignedModel,
      employee: unassignedFrom,
    })

    dispatch('employees/removeProject', unassignedFrom, { root: true })

    if(triggerNotification)
      dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `Employee "${unassignedFrom[rootGetters['employees/getNotificationKey']]}" was successfully unassigned from project "${unassignedModel[getters['getNotificationKey']]}"`,
        actions: { close: true }
      }, { root: true })
  },

  async assignedEmployee({dispatch, commit, getters, rootGetters}, {assignedModel, assignedTo, triggerNotification = true}) {
    commit(Types.PROJECT_ASSIGN_EMPLOYEE, {
      employee: assignedTo,
      project: assignedModel,
    })

    dispatch('employees/addProject', assignedTo, { root: true })

    if(triggerNotification)
      dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `Employee "${assignedTo[rootGetters['employees/getNotificationKey']]}" was successfully assigned to project "${assignedModel[getters['getNotificationKey']]}"`,
        actions: { close: true }
      }, { root: true })
  },

  async currentModuleFetched({dispatch, getters}, {uuid}) {
    if(!uuid)
      return

    switch (getters['getCurrentModuleTab']) {
      case 'employees':
        dispatch('fetchCurrentModuleEmployees', uuid)
        break

      case 'tools':
        dispatch('fetchCurrentModuleTools', uuid)
        break
    }
  },

  setPerformedTableDate({commit}, date) {
    const momentJsDate = moment(date)

    if(!momentJsDate.isValid())
      return

    const weeks = []
    for (let day = 1; day <= momentJsDate.daysInMonth(); day++) {
      const momentDate = moment(`${momentJsDate.year()}-${momentJsDate.month() + 1}-${day}`, 'YYYY-M-D')
      const momentDateFormatted = momentDate.format('YYYY-MM-DD')
      const weekNumber = momentDate.isoWeek()

      const weekIndex = weeks.findIndex(week => week.week === weekNumber)

      const start = weeks[weekIndex]?.start ? weeks[weekIndex].start : momentDateFormatted

      let weekData = {
        week: weekNumber,
        start: start,
        end: momentDateFormatted,
        shortDate: {
          start: moment(start).format('MM-DD'),
          end: moment(momentDateFormatted).format('MM-DD'),
        },
      }

      if(0 <= weekIndex)
        weeks[weekIndex] = weekData
      else
        weeks.push(weekData)
    }

    commit(Types.SET_PERFORMED_TABLE_DATE, {
      year: momentJsDate.year(),
      month: momentJsDate.month() + 1,
      weeks,
    })
  },

  initiatePerformedTable({commit}) {
    commit(Types.INITIATE_PERFORMED_TABLE)
  },

  async filter({dispatch, commit, getters, rootGetters}) {
    const filterType = rootGetters['filters/getOptions']?.type

    if(filterType === 'performedTable') {
      let momentFilterDate = moment()

      const filterDate = getters['getIntervalFilterData']('work_begins_month', 'work_ends_month')?.from
      if(filterDate)
        momentFilterDate = moment(filterDate, 'YYYY-MM')

      const year = momentFilterDate.year()
      const month = momentFilterDate.month() + 1

      const date = `${year}-${padStart(month, 2, '0')}`

      await dispatch('setPerformedTableDate', moment(date, 'YYYY-MM'))

      commit(Types.FILTER_MODULE, {
        searchBy: 'searchByPerformedTable',
        rootModule: 'module.active',
        prefilters: getters['getPrefilters'],
      })

      return
    }else if(filterType === 'projectSchedule') {
      commit(Types.FILTER_MODULE, {
        searchBy: 'searchByEmployee',
        rootModule: 'currentModule.employees',
        prefilters: getters['getPrefilters'],
      })

      return
    }

    commit(Types.SET_ARCHIVE_FILTER_STATUS, !!getters['getFilterData']('is_archived'))

    commit(Types.FILTER_MODULE, {
      searchBy: 'searchBy',
      rootModule: `module.${getters['filteredByArchiveProjects'] ? 'archived' : 'active'}`
    })
  },

  searchByText({commit, getters}, {value, searchBy, rootModule}) {
    commit(Types.SEARCH_BY_TEXT, value)

    commit(Types.FILTER_MODULE, {
      searchBy: searchBy || 'searchBy',
      rootModule: rootModule || `module.${getters['filteredByArchiveProjects'] ? 'archived' : 'active'}`,
      prefilters: getters['getPrefilters'],
    })
  },

  exportPerformedTableXLSX({commit, getters}) {
    commit(Types.EXPORTING_PERFORMED_TABLE, true)

    const year = getters['getPerformedTableYear']
    const month = getters['getPerformedTableMonth']
    const searchQuery = getters['getSearchValue']
    const filters = getters['getFiltersBy']

    api.post(`${formatModuleUrl(module)}/performed-table/export`, {year, month, searchQuery, filters}, {responseType: 'blob'})
      .then(response => {
        downloadFile(response, getters['getExportingPerformedTableFileName'](year, month, filters, searchQuery)+'.xlsx')

        commit(Types.EXPORTING_PERFORMED_TABLE, false)
      })
  },

  validateWebsocketResponse({commit, getters}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        if(getters['cancelDocumentsWebsocket'](event))
          return

        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },

  pushCurrentModuleAccommodation({commit}, {event, data}) {
    commit(Types.PUSH_CURRENT_PROJECT_ACCOMMODATION, {event, data})
  },
}, [ArchivesActions({module}), DocumentsActions({module}), DetailActions({module}), TimekeepingActions({module}), Schema])
