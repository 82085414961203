// Auth types
export const RETRIEVE_TOKEN = 'RETRIEVE_TOKEN'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const EMPTY_AUTH_USER = 'EMPTY_AUTH_USER'
export const SET_ALERTS = 'SET_ALERTS'
export const ADD_ALERT = 'ADD_ALERT'
export const DISMISS_ALERT = 'DISMISS_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const UPDATE_AUTH_USER_PERMISSIONS = 'UPDATE_AUTH_USER_PERMISSIONS'
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'

// Global types
export const SET_MODEL = 'SET_MODEL'
export const SET_MODEL_LIST = 'SET_MODEL_LIST'
export const ADD_TO_MODEL = 'ADD_TO_MODEL'
export const APPROVE_MODEL = 'APPROVE_MODEL'
export const REFUSE_MODEL = 'REFUSE_MODEL'
export const REMOVE_FROM_MODEL = 'REMOVE_FROM_MODEL'
export const EMPTY_MODEL = 'EMPTY_MODEL'
export const UPDATE_SINGLE_MODEL = 'UPDATE_SINGLE_MODEL'
export const DELETE_FROM_MODAL = 'DELETE_FROM_MODAL'
export const DELETE_FROM_MODULE = 'DELETE_FROM_MODULE'
export const SINGLE_DELETE_FROM_MODAL = 'SINGLE_DELETE_FROM_MODAL'
export const FILTER_MODULE = 'FILTER_MODULE'
export const ADD_FILTER_MODULE = 'ADD_FILTER_MODULE'
export const REMOVE_FILTER_MODULE = 'REMOVE_FILTER_MODULE'
export const ADD_INTERVAL_FILTER_MODULE = 'ADD_INTERVAL_FILTER_MODULE'
export const CLEAR_ALL_FILTER_MODULE = 'CLEAR_ALL_FILTER_MODULE'
export const SEARCH_BY_TEXT = 'SEARCH_BY_TEXT'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const REMOVE_EMPTY_FILTERS = 'REMOVE_EMPTY_FILTERS'
export const SINGLE_ARCHIVE_MODEL = 'SINGLE_ARCHIVE_MODEL'
export const SINGLE_UNARCHIVE_MODEL = 'SINGLE_UNARCHIVE_MODEL'
export const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE'
export const SET_CURRENT_MODULE_TAB = 'SET_CURRENT_MODULE_TAB'
export const UNSET_MODULES = 'UNSET_MODULES'
export const SET_TEMP_MODULE = 'SET_TEMP_MODULE'
export const UPDATING_MODEL = 'UPDATING_MODEL'
export const UPDATED_MODEL = 'UPDATED_MODEL'
export const SET_HISTORIES = 'SET_HISTORIES'
export const SET_IS_FETCHING_HISTORIES = 'SET_IS_FETCHING_HISTORIES'
export const SET_HISTORIES_LOADED_LENGTH = 'SET_HISTORIES_LOADED_LENGTH'
export const CLEAR_HISTORIES = 'CLEAR_HISTORIES'
export const CHECK_MODULE = 'CHECK_MODULE'
export const UNCHECK_MODULE = 'UNCHECK_MODULE'
export const RESET_CHECKED = 'RESET_CHECKED'
// Form data
export const SET_FORM_INPUT_DATA = 'SET_FORM_INPUT_DATA'
export const SET_FORM_OBJECT_DATA = 'SET_FORM_OBJECT_DATA'
export const SET_ARRAY_FORM_INPUT_DATA = 'SET_ARRAY_FORM_INPUT_DATA'
export const EDIT_ARRAY_FORM_INPUT_DATA = 'EDIT_ARRAY_FORM_INPUT_DATA'
export const MODIFY_ARRAY_FORM_INPUT_DATA = 'MODIFY_ARRAY_FORM_INPUT_DATA'
export const REMOVE_ARRAY_FORM_INPUT_DATA = 'REMOVE_ARRAY_FORM_INPUT_DATA'
export const SET_ASSIGN_FORM_INPUT_DATA = 'SET_ASSIGN_FORM_INPUT_DATA'
export const SET_UNASSIGN_FORM_INPUT_DATA = 'SET_UNASSIGN_FORM_INPUT_DATA'
export const SET_ARCHIVE_FORM_INPUT_DATA = 'SET_ARCHIVE_FORM_INPUT_DATA'
export const SET_FORM_DATA = 'SET_FORM_DATA'
export const SET_ARCHIVE_FORM_DATA = 'SET_ARCHIVE_FORM_DATA'
export const EMPTY_FORM_DATA = 'EMPTY_FORM_DATA'
export const EMPTY_ASSIGN_FORM_DATA = 'EMPTY_ASSIGN_FORM_DATA'
export const EMPTY_UNASSIGN_FORM_DATA = 'EMPTY_UNASSIGN_FORM_DATA'
export const EMPTY_ARCHIVE_FORM_DATA = 'EMPTY_ARCHIVE_FORM_DATA'
export const EMPTY_GENERATE_DOCUMENTS_FORM_DATA = 'EMPTY_GENERATE_DOCUMENTS_FORM_DATA'
export const SET_FORM_MODAL_DATA = 'SET_FORM_MODAL_DATA'
export const SET_FORM_META_DATA = 'SET_FORM_META_DATA'
export const SET_FORM_FIELDS_ERRORS = 'SET_FORM_FIELDS_ERRORS'
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS'
export const SET_FORM_NAME = 'SET_FORM_NAME'
export const FORM_IS_SUBMITTED = 'FORM_IS_SUBMITTED'
export const FORM_IS_FINISHED = 'FORM_IS_FINISHED'
export const FILL_FORM_DATA = 'FILL_FORM_DATA'
export const FILL_ASSIGN_FORM_DATA = 'FILL_ASSIGN_FORM_DATA'
export const FILL_UNASSIGN_FORM_DATA = 'FILL_UNASSIGN_FORM_DATA'
export const FILL_ARCHIVE_FORM_DATA = 'FILL_ARCHIVE_FORM_DATA'
export const FILL_TEMPLATE = 'FILL_TEMPLATE'
export const FILL_GENERATE_DOCUMENTS_FORM_DATA = 'FILL_GENERATE_DOCUMENTS_FORM_DATA'
export const SET_GENERATE_DOCUMENTS_FORM_INPUT_DATA = 'SET_GENERATE_DOCUMENTS_FORM_INPUT_DATA'
export const SET_FORM_DOCUMENT_DATA = 'SET_FORM_DOCUMENT_DATA'
export const SET_FORM_INVOICE_PRODUCT_DATA = 'SET_FORM_INVOICE_PRODUCT_DATA'
export const ADD_FORM_INVOICE_PRODUCT = 'ADD_FORM_INVOICE_PRODUCT'
export const DESTROY_FORM_INVOICE_PRODUCT = 'DESTROY_FORM_INVOICE_PRODUCT'

// CRM settings
export const MOBILE_VIEW = 'MOBILE_VIEW'
export const TOGGLE_MENU = 'TOGGLE_MENU'

// Warning modal
export const SET_WARNING_MODAL_DATA = 'SET_WARNING_MODAL_DATA'
export const CLOSE_WARNING_MODAL= 'CLOSE_WARNING_MODAL'
export const WARNING_MODAL_IS_LOADING = 'WARNING_MODAL_IS_LOADING'

// Filters modal
export const SHOW_FILTER_MODAL = 'SHOW_FILTER_MODAL'
export const HIDE_FILTER_MODAL = 'HIDE_FILTER_MODAL'
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'

// Roles
export const SET_DEFAULT_ROLES = 'SET_DEFAULT_ROLES'

// Warehouse categories
export const SET_PARENT_UUID = 'SET_PARENT_UUID'
export const UPDATE_CURRENT_TOOL = 'UPDATE_CURRENT_TOOL'

// Employee
export const SET_CURRENT_MODULE_HISTORY = 'SET_CURRENT_MODULE_HISTORY'
export const SET_CURRENT_MODULE_ACCOMMODATION = 'SET_CURRENT_MODULE_ACCOMMODATION'
export const SET_CURRENT_MODULE_TRANSPORT = 'SET_CURRENT_MODULE_TRANSPORT'
export const SET_CURRENT_MODULE_TIMEKEEPING_TEMPLATE = 'SET_CURRENT_MODULE_TIMEKEEPING_TEMPLATE'
export const ADD_WAREHOUSE_TO_CURRENT_MODULE = 'ADD_WAREHOUSE_TO_CURRENT_MODULE'
export const UPDATE_WAREHOUSE_IN_CURRENT_MODULE = 'UPDATE_WAREHOUSE_IN_CURRENT_MODULE'
export const REMOVE_WAREHOUSE_FROM_CURRENT_MODULE = 'REMOVE_WAREHOUSE_FROM_CURRENT_MODULE'
export const ASSIGN_WAREHOUSE = 'ASSIGN_WAREHOUSE'
export const UNASSIGN_WAREHOUSE = 'UNASSIGN_WAREHOUSE'
export const UPDATE_CURRENT_MODULE_WAREHOUSE = 'UPDATE_CURRENT_MODULE_WAREHOUSE'
export const TOGGLE_TOOL_RESPONSIBILITY = 'TOGGLE_TOOL_RESPONSIBILITY'
export const PUSH_HISTORY = 'PUSH_HISTORY'
export const ADD_HIRED_CANDIDATE = 'ADD_HIRED_CANDIDATE'
export const FIRE_EMPLOYEE = 'FIRE_EMPLOYEE'
export const REMOVE_PROJECT_FROM_EMPLOYEE = 'REMOVE_PROJECT_FROM_EMPLOYEE'
export const ADD_PROJECT_TO_EMPLOYEE = 'ADD_PROJECT_TO_EMPLOYEE'
export const REMOVE_EMPLOYEE_FROM_TOOL = 'REMOVE_EMPLOYEE_FROM_TOOL'
export const ADD_EMPLOYEE_TO_TOOL = 'ADD_EMPLOYEE_TO_TOOL'
export const REMOVE_ACCOMMODATION_FROM_CURRENT_MODULE = 'REMOVE_ACCOMMODATION_FROM_CURRENT_MODULE'
export const REMOVE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION = 'REMOVE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION'
export const UPDATE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION = 'UPDATE_EMPLOYEE_FROM_CURRENT_ACCOMMODATION'
export const REMOVE_EMPLOYEE_FROM_CURRENT_TRANSPORT = 'REMOVE_EMPLOYEE_FROM_CURRENT_TRANSPORT'
export const UPDATE_EMPLOYEE_FROM_CURRENT_TRANSPORT = 'UPDATE_EMPLOYEE_FROM_CURRENT_TRANSPORT'
export const REMOVE_TRANSPORT_FROM_CURRENT_MODULE = 'REMOVE_TRANSPORT_FROM_CURRENT_MODULE'
export const SET_TIMEKEEPING_DAYS = 'SET_TIMEKEEPING_DAYS'
export const UNSET_TIMEKEEPING_DAYS = 'UNSET_TIMEKEEPING_DAYS'
export const SET_SCHEDULE_YEAR = 'SET_SCHEDULE_YEAR'
export const SET_SCHEDULE_MONTH = 'SET_SCHEDULE_MONTH'
export const EXPORTING_EMPLOYEE_WAREHOUSE = 'EXPORTING_EMPLOYEE_WAREHOUSE'

// Employee schedule
export const EXPORTING_EMPLOYEE_SCHEDULE = 'EXPORTING_EMPLOYEE_SCHEDULE'

// Rotation table
export const SET_ROTATION_TABLE_DATE = 'SET_ROTATION_TABLE_DATE'
export const INITIATE_ROTATION_TABLE = 'INITIATE_ROTATION_TABLE'
export const EXPORTING_ROTATION_TABLE = 'EXPORTING_ROTATION_TABLE'

// Candidate
export const BLACKLIST_MODULE = 'BLACKLIST_MODULE'
export const UPDATE_FIRED_STATISTICS = 'UPDATE_FIRED_STATISTICS'
export const UNBLACKLIST_MODULE = 'UNBLACKLIST_MODULE'
export const HIRE_CANDIDATE = 'HIRE_CANDIDATE'
export const ADD_FIRED_EMPLOYEE = 'ADD_FIRED_EMPLOYEE'

// Documents
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const CREATE_DOCUMENTS = 'CREATE_DOCUMENTS'
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS'
export const ARHIVE_DOCUMENTS = 'ARHIVE_DOCUMENTS'
export const RESTORE_DOCUMENTS = 'RESTORE_DOCUMENTS'
export const SET_FORM_FILES_DATA = 'SET_FORM_FILES_DATA'
export const EMPTY_FILES_FORM_DATA = 'EMPTY_FILES_FORM_DATA'
export const UNSET_DOCUMENTS = 'UNSET_DOCUMENTS'

// Projects
export const ARCHIVE_MODULE = 'ARCHIVE_MODULE'
export const UNARCHIVE_MODULE = 'UNARCHIVE_MODULE'
export const SET_CURRENT_MODULE_EMPLOYEES = 'SET_CURRENT_MODULE_EMPLOYEES'
export const SET_CURRENT_MODULE_WAREHOUSE = 'SET_CURRENT_MODULE_WAREHOUSE'
export const SET_ARCHIVE_FILTER_STATUS = 'SET_ARCHIVE_FILTER_STATUS'
export const PROJECT_UNASSIGN_EMPLOYEE = 'PROJECT_UNASSIGN_EMPLOYEE'
export const PROJECT_ASSIGN_EMPLOYEE = 'PROJECT_ASSIGN_EMPLOYEE'
export const SET_PERFORMED_TABLE_DATE = 'SET_PERFORMED_TABLE_DATE'
export const INITIATE_PERFORMED_TABLE = 'INITIATE_PERFORMED_TABLE'
export const UNSET_ROTATION_TABLE = 'UNSET_ROTATION_TABLE'
export const EXPORTING_PERFORMED_TABLE = 'EXPORTING_PERFORMED_TABLE'

// Blacklist
export const SET_BLACKLIST_FORM_INPUT_DATA = 'SET_BLACKLIST_FORM_INPUT_DATA'
export const EMPTY_BLACKLIST_FORM_DATA = 'EMPTY_BLACKLIST_FORM_DATA'
export const FILL_BLACKLIST_FORM_DATA = 'FILL_BLACKLIST_FORM_DATA'
export const SET_BLACKLIST_FORM_DATA = 'SET_BLACKLIST_FORM_DATA'
export const BLACKLISTED_MODULE = 'BLACKLISTED_MODULE'

// Project categories
export const SET_CATEGORIES = 'SET_CATEGORIES'

// Locations
export const SET_LOCATIONS = 'SET_LOCATIONS'

// Citizenships
export const SET_CITIZENSHIPS = 'SET_CITIZENSHIPS'

// Accommodations
export const SET_CURRENT_MODULE_RESIDENTS = 'SET_CURRENT_MODULE_RESIDENTS'
export const SET_CURRENT_MODULE_ACCOMMODATIONS = 'SET_CURRENT_MODULE_ACCOMMODATIONS'

// Transport services
export const SET_CURRENT_MODULE_SERVICES = 'SET_CURRENT_MODULE_SERVICES'
export const SET_CURRENT_MODULE_DEFECTS = 'SET_CURRENT_MODULE_DEFECTS'

// Timekeepings
export const SET_MULTIPLE_FORM_DATA = 'SET_MULTIPLE_FORM_DATA'
export const APPEND_SCHEDULE_DAYS_ERRORS = 'UPDATE_SCHEDULE_DAYS_ERRORS'
export const EMPTY_MULTIPLE_FORM_DATA = 'EMPTY_MULTIPLE_FORM_DATA'
export const CLEAR_SINGLE_MULTIPLE_FORM_ERRORS = 'CLEAR_SINGLE_MULTIPLE_FORM_ERRORS'
export const REMOVE_SINGLE_MULTIPLE_FORM_DATA = 'REMOVE_SINGLE_MULTIPLE_FORM_DATA'
export const SET_MULTIPLE_FORM_DATA_UPDATING_STATUS = 'SET_MULTIPLE_FORM_DATA_UPDATING_STATUS'
export const EMPTY_FORM_DAYS_DATA = 'EMPTY_FORM_DAYS_DATA'
export const ADD_UPDATING_SCHEDULES = 'ADD_UPDATING_SCHEDULES'
export const REMOVE_UPDATING_SCHEDULES = 'REMOVE_UPDATING_SCHEDULES'

// Dashnoard
export const DASHBOARD_INITIATED = 'DASHBOARD_INITIATED'
export const SET_LOADING_STATE = 'SET_LOADING_STATE'
export const SET_STATE_DATA = 'SET_STATE_DATA'
export const REVENUE_ADD_FILTER = 'REVENUE_ADD_FILTER'

// Signature
export const SIGN_SET_VARIABLES = 'SIGN_SET_VARIABLES'

// Action modal
export const SET_ACTION_MODAL_DATA = 'SET_ACTION_MODAL_DATA'
export const CLOSE_ACTION_MODAL = 'CLOSE_ACTION_MODAL'
export const ACTION_MODAL_IS_LOADING = 'ACTION_MODAL_IS_LOADING'
export const ACTION_MODAL_INIT_DATA = 'ACTION_MODAL_INIT_DATA'
export const ACTION_SET_DATA_VALUE = 'ACTION_SET_DATA_VALUE'

export const POP_PAGE = 'POP_PAGE'
export const PUSH_PAGE = 'PUSH_PAGE'

export const PUSH_CURRENT_PROJECT_ACCOMMODATION = 'PUSH_CURRENT_PROJECT_ACCOMMODATION'
export const FETCH_STARTED = 'FETCH_STARTED'
export const FETCH_ENDED = 'FETCH_ENDED'

export const ADD_AUTOMATIC_DOCUMENTS = 'ADD_AUTOMATIC_DOCUMENTS'
export const UPDATE_AUTOMATIC_DOCUMENTS = 'UPDATE_AUTOMATIC_DOCUMENTS'
export const DELETE_AUTOMATIC_DOCUMENTS = 'DELETE_AUTOMATIC_DOCUMENTS'
