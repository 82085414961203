import * as Types from './../../../mutationTypes'

export default {
  [Types.SHOW_FILTER_MODAL](state, data) {
    state.showModal = true
    state.modalTitle = data.title
    state.moduleName = data.module
    state.options = data.options
  },

  [Types.HIDE_FILTER_MODAL](state) {
    state.showModal = false
  },

  [Types.SET_FILTER_OPTIONS](state, data) {
    state.options = data
  }
}