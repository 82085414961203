import BaseMutations from '@/store/base/mutations'
import PersonMutations from '@/store/base/person/mutations'
import DocumentsMutations from '@/store/base/documents/mutations'
import DetailMutations from "@/store/base/detail/mutations";
import BlacklistMutations from "@/store/base/blacklist/mutations";
import * as Types from '@/store/mutationTypes'

const module = 'candidates'

export default BaseMutations({
  [Types.HIRE_CANDIDATE](state, hiredCandidate) {
    const moduleIndex = state.module.findIndex(module => module.uuid === hiredCandidate.uuid)

    if(0 <= moduleIndex)
      state.module.splice(moduleIndex, 1)
  },

  [Types.ADD_FIRED_EMPLOYEE](state, firedEmployee) {
    if(state.isFetched)
      state.module.unshift(firedEmployee)
  },

  [Types.UNBLACKLIST_MODULE](state, module) {
    state.currentModule.is_blacklisted = module.is_blacklisted
  },

  [Types.BLACKLISTED_MODULE](state, data) {
    const moduleIndex = state.module.findIndex(module => module.uuid === data.uuid)

    if(state?.currentModule?.uuid) {
      state.currentModule.is_blacklisted = data.is_blacklisted
      state.currentModule.blacklisted_reason = data.blacklisted_reason
    }

    if(0 <= moduleIndex) {
      state.module[moduleIndex].is_blacklisted = data.is_blacklisted
      state.module[moduleIndex].blacklisted_reason = data.blacklisted_reason
    }
  },

  [Types.UPDATE_FIRED_STATISTICS](state, data) {
    state.fired_statistics = {
      count: data?.count || 0,
      period: {
        from: data?.period?.from || undefined,
        to: data?.period?.to || undefined,
      }
    }
  },
}, [PersonMutations, DocumentsMutations(module), BlacklistMutations({module}), DetailMutations({module})])
