import DocumentSchema from '@/store/base/documents/schema'
import {endOfDay, startOfDay} from "@/helpers/formatters";

export default Object.assign({}, DocumentSchema, {
  serialize({getters}) {
    const inputData = getters['getFormData']

    const payload = {
      uuid: inputData.uuid,
      body_type: inputData.body_type,
      registration_number: inputData.registration_number,
      car_brand_uuid: inputData.car_brand_uuid,
      car_model_uuid: inputData.car_model_uuid,
      project_uuid: inputData.project_uuid,
      seats: inputData.seats,
      insurance_starts_at: startOfDay(inputData.insurance_starts_at),
      insurance_ends_at: endOfDay(inputData.insurance_ends_at),
      inspection_starts_at: startOfDay(inputData.inspection_starts_at),
      inspection_ends_at: endOfDay(inputData.inspection_ends_at),
      fuel_cards: inputData.fuel_cards,
      ownership_type: inputData.ownership_type,
      information: inputData.information,
    }

    if(payload.ownership_type === 'rent') {
      payload.price = !isNaN(inputData.price) && !['', null].includes(inputData.price) ? inputData.price : null
      payload.contract_ends_at = inputData.contract_ends_at || null
    }

    return payload
  },

  async parseCurrentModule({getters}, module) {
    const existingModule = getters['getCurrentModule']

    return Object.assign({}, existingModule, {
      employees: existingModule.employees ?? [],
      employeesFetched: existingModule.employeesFetched ?? false,
    }, module)
  },
})
