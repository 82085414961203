import BaseGetters from '@/store/base/getters'
import ArchivesGetters from "@/store/base/archives/getters";
import DocumentsGetters from "@/store/base/documents/getters";
import DetailGetters from "@/store/base/detail/getters";
import moment from "moment";
import {padStart, trim} from "lodash";
import {addPad, isFutureDate, isPresentDate} from "@/helpers/formatters";
// import {normalizeDate} from "@/helpers/formatters";
import TimekeepingGetters from '@/store/base/timekeeping/getters'

export default BaseGetters({
  getProjectTypes: state => state.projectTypes,
  getCurrentModule: state => state.currentModule,
  getProjectTypeTitle: state => projectTypeSlug => state?.projectTypes.find(projectType => projectType.slug === projectTypeSlug)?.name || '',
  getCurrentModuleEmployees: state => state?.currentModule?.employees,
  getEmployeesFetched: state => state.currentModule?.employeesFetched,
  getCurrentModuleTools: state => state?.currentModule?.tools,
  getToolsFetched: state => state.currentModule?.toolsFetched,
  getProjectByUuid: state => projectUuid => state.module['archived'].concat(state.module['active']).find(project => project.uuid === projectUuid),
  getPerformedTableYear: ({performed_table}) => performed_table.year,
  getPerformedTableMonth: ({performed_table}) => performed_table.month,
  getPerformedTableDate: ({performed_table}) => {
    return performed_table.year && performed_table.month ? moment(`${performed_table.year}-${addPad(performed_table.month)}`, 'YYYY-MM').format('YYYY-MM') : undefined
  },
  getPerformedTableWeeks: ({performed_table}) => performed_table.weeks,
  getPerformedTable: ({moduleSearchResults}) => moduleSearchResults,
  isExportingTable: ({performed_table}) => {
    return !!performed_table.exporting
  },
  getPrefilters: (state, getters, rootState, rootGetters) => {
    const prefilters = []

    if(
      rootGetters['filters/getOptions']?.type === 'performedTable' &&
      !getters['getIntervalFilterData']('work_begins_month', 'work_ends_month')?.from
    ) {
      const momentDate = moment()
      const date = `${momentDate.year()}-${padStart(momentDate.month() + 1, 2, '0')}`

      prefilters.push({
        isInterval: true,
        keyFrom: 'work_begins_month',
        keyTo: 'work_ends_month',
        value: {
          from: date,
          to: date,
        }
      })
    }

    return prefilters
  },

  getExportingPerformedTableFileName: (state, getters, rootState, rootGetters) => (year, month, filters, searchQuery) => {
    let projectManagers = []

    filters.forEach(({key, value}) => {
      if(key === 'project_manager_uuid')
        projectManagers = rootGetters['users/getModule']
          .filter(user => user.uuid === value)
          .map(user => trim(`${user.first_name} ${user.last_name}`))
    })

    let fileName = projectManagers?.length ? `${projectManagers.join(',')} aktavimai` : 'Aktavimai'

    if(year && month)
      fileName += ' ' + moment(`${year}-${month}`, 'YYYY-M').format('YYYY-MM')

    if(searchQuery)
      fileName += `. Užklausa '${searchQuery}'`

    return fileName
  },
  getDayData: (state, getters, rootState, rootGetters) => (day, weekday, employeeUuid) => rootGetters['employees/getTimekeepingDay'](day, weekday, employeeUuid),
  getAbsenceDays: (state, getters, rootState, rootGetters) => absence => {
    const year = rootGetters['employees/getScheduleYear']
    const month = rootGetters['employees/getScheduleMonth']

    const scheduleYearMonth = `${year}-${addPad(month)}`

    if(isFutureDate(scheduleYearMonth, 'month'))
      return []

    const days = []

    rootGetters['employees/getTimekeepingDays'].forEach(timekeepingDay => {
      const dayDate = `${scheduleYearMonth}-${addPad(timekeepingDay.day)}`

      if(
        isFutureDate(dayDate) ||
        isPresentDate(dayDate, 'day')
      )
        return

      (state.currentModule?.employees || []).forEach(employee => {
        const day = rootGetters['employees/getTimekeepingDay'](timekeepingDay.day, timekeepingDay.weekday, employee.uuid)

        if(day?.absence === absence.slug)
          days.push(day)
      })
    })

    return days
  },
  getAbsenceDaysCount: (state, getters) => absence => getters['getAbsenceDays'](absence).length,
  getAbsenceHours: (state, getters) => absence => {
    let workTimeMinutes = 0

    getters['getAbsenceDays'](absence).forEach(absenceDay => {
      const workTime = moment(getters['getDayWorkHours'](absenceDay.day, absenceDay.weekday, absenceDay.model_uuid), 'HH:mm')

      workTimeMinutes += workTime.hours() * 60 + workTime.minutes()
    })

    return Math.floor(workTimeMinutes / 60)
  },
  isCurrentModuleAccommodationsFetched: ({currentModule}) => !!currentModule?.accommodationsFetched,
  getCurrentModuleAccommodations: ({currentModule}) => currentModule?.accommodations || [],
}, [ArchivesGetters, DocumentsGetters, DetailGetters, TimekeepingGetters])
