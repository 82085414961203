import * as Types from './../../mutationTypes'

export default {
  [Types.SET_TOKEN](state, token) {
    state.token = token
  },

  [Types.SET_AUTH_USER](state, user) {
    state.user = user
  },

  [Types.EMPTY_AUTH_USER](state) {
    state.user = {
      user: null,
      token: null
    }
  },

  [Types.SET_ALERTS](state, alerts) {
    state.alerts = alerts
  },

  [Types.ADD_ALERT](state, alert) {
    state.alerts.unshift(alert)
  },

  [Types.DISMISS_ALERT](state, alert) {
    const index = state.alerts.findIndex(stateAlert => stateAlert.uuid === alert.uuid)

    if(0 <= index) {
      state.alerts[index].status = 0
    }
  },

  [Types.REMOVE_ALERT](state, alert) {
      state.alerts = state.alerts.filter(stateAlert => stateAlert.uuid !== alert.uuid)
  },

  [Types.UPDATE_AUTH_USER_PERMISSIONS](state, permissions) {
    if(state.user?.uuid && state.token)
      state.user.permissions = permissions
  },

  [Types.UPDATE_AUTH_USER](state, user) {
    if(state.user?.uuid === user.uuid && state.token)
      state.user = user
  },
}