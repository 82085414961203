import * as Types from './../../mutationTypes'
import DocumentsMutations from "@/store/base/documents/mutations";
import HasFormMutations from "@/store/base/hasForm/mutations"

const module = 'signature'

export default Object.assign({}, DocumentsMutations(module), HasFormMutations, {
	[Types.SIGN_SET_VARIABLES](state, {key, value}) {
		state[key] = value
	},

	[Types.ADD_TO_MODEL](state, data) {
		state.massSignDocuments.unshift(data)
	},

	[Types.DELETE_FROM_MODULE](state, data) {
		data.forEach(oldModule => {
      state.massSignDocuments = state.massSignDocuments.filter(mod => mod['uuid'] !== oldModule['uuid'])

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: ' Document: "' + oldModule['file_name'] + '.' + oldModule['file_extension'] + '" was deleted.',
          actions: {
            close: true,
          }
        }, { root: true })
    })
	}
})
