import api from '@/plugins/axios';
import * as Types from './../../mutationTypes'

export default {
    async fetchModel({commit}) {
        await api.get('citizenships/all')
            .then(response => {
                commit(Types.SET_CITIZENSHIPS, response.data)
            })
    }
}