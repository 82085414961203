import BaseState from '@/store/base/state'
import PersonState from '@/store/base/person/state'
import DocumentsState from '@/store/base/documents/state'
import DetailState from "@/store/base/detail/state";
import BlacklistState from "@/store/base/blacklist/state";

export default BaseState({
  moduleTitle: 'Candidate',

  formData: {
    uuid: '',
    first_name: '',
    last_name: '',
    country_id: '',
    city_id: '',
    address: '',
    phone: '',
    email: '',
    declared_address: '',
    marital_status: '',
    positions_uuid: [],
    has_driving_license: 0,
    gender: '',
    citizenship_id: '',
    date_of_birth: '',
    languages: '',
    education: '',
    qualification: '',
    comment: '',
    channel: '',
    certificates: [],
  },

  dummyData: {
    uuid: '',
    first_name: 'first_name',
    last_name: 'last_name',
    country_id: '',
    city_id: '',
    address: 'address1',
    phone: 'phone',
    email: 'email',
    declared_address: 'address2',
    marital_status: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getMaritalStatuses'},
    // positions_uuid: [],
    has_driving_license: {type: 'integer', from: 0, to: 1},
    gender: {key: 'slug', type: 'random_element', getterNamespace: 'employees/getGenders'},
    citizenship_id: {key: 'id', type: 'random_element', getterNamespace: 'citizenships/getModule'},
    date_of_birth: {key: 'YYYY-MM-DD', type: 'date'},
    languages: 'word',
    education: 'short_description',
    qualification: 'short_description',
    comment: 'description',
    channel: 'description',
    certificates: [],
  },

  blacklistFormData: {
    uuid: '',
    blacklisted_reason: '',
  },

  notificationTitle: 'full_name',
  primaryKey: 'uuid',

  currentModule: {},
  currentModuleTabs: {
    profile: {title: 'Profile'},
    documents: {title: 'Documents'},
    settings: {title: 'Settings'},
  },
  currentModuleTab: 'profile',

  fired_statistics: {
    count: 0,
    period: {
      from: undefined,
      to: undefined,
    }
  },

  searchBy: ['first_name', 'last_name', 'full_name', 'positions', 'phone', 'qualification'],
}, [PersonState, DocumentsState, BlacklistState, DetailState])
