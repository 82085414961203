import * as Types from '@/store/mutationTypes'
import BaseActions from './../../base/actions'
import Schema from './schema'
import api from '@/plugins/axios'
import {formatModuleUrl, normalizeDate} from '@/helpers/formatters'
import { isPlainObject } from "lodash";
import MultipleFormActions from '@/store/base/multipleFormData/actions'

const module = 'timekeepingDays'

export default BaseActions({module}, {
  async fetchModel({commit, getters}, employee_uuid = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post(`${formatModuleUrl(module)}/get-by-key`, {key: 'model_uuid', value: employee_uuid}, {trigger404: false})
      .then(res => commit(Types.SET_MODEL, res.data))
  },

  async fetchProjectModels({commit, getters}, projectUuid) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post(`${formatModuleUrl(module)}/get-by-key`, {key: 'project_uuid', value: projectUuid}, {trigger404: false})
      .then(res => commit(Types.SET_MODEL, res.data))
  },

  setSingleFormData({commit}, {year, month, day, property, value}) {
    commit(Types.SET_MULTIPLE_FORM_DATA, {year, month, day, property, value})
  },

  async setMultipleFormData({dispatch, commit, getters, rootGetters}, {employee_uuid: employeeUuid = rootGetters['employees/getCurrentModuleUuid'], year, month, day, property, value, weekday, dayIndex}) {
    let dayTemplate = getters['getDay'](employeeUuid, year, month, day)

    if(!dayTemplate) {
      dayTemplate = rootGetters['employeeTimekeepingTemplates/getTemplateWeekDay'](weekday, employeeUuid)

      if(dayTemplate)
        dayTemplate.uuid = undefined
    }

    commit(Types.SET_MULTIPLE_FORM_DATA, {
      dayTemplate: isPlainObject(dayTemplate) ? Object.assign({}, dayTemplate) : dayTemplate,
      year,
      month,
      day,
      property,
      value,
      weekday,
      employee_uuid: employeeUuid,
    })

    const scheduleDay = rootGetters['timekeepingDays/getMultipleFormDataDay'](employeeUuid, {year, month, day})

    if(scheduleDay) {
      await dispatch('employees/clearSingleMultipleFormErrors', dayIndex, { root: true })

      let hasErrors = false

      await dispatch('validateScheduleDay', {dayIndex, scheduleDay})
        .then(async scheduleDayErrors => {
          hasErrors = !!Object.keys(scheduleDayErrors).length

          await dispatch('employees/appendScheduleDaysErrors', scheduleDayErrors, { root: true })
        })

      if(!hasErrors)
        dispatch('updateScheduleDay', {
          scheduleDay,
          model: 'employees',
          model_uuid: employeeUuid
        })
    }
  },

  async validateScheduleDay({rootGetters}, {dayIndex, scheduleDay}) {
    const dayErrors = {}

    const requiredKeys = rootGetters['timekeepingDays/getRequiredFields']

    if(requiredKeys.every(key => !scheduleDay[key]))
      return dayErrors

    requiredKeys.forEach(key => {
      if(!scheduleDay[key]) {
        const errorIndex = `days.${dayIndex}.${key}`

        if(!Array.isArray(dayErrors[errorIndex]))
          dayErrors[errorIndex] = []

        dayErrors[errorIndex].push(`${normalizeDate(scheduleDay.year, scheduleDay.month, scheduleDay.day)} "${key.replaceAll('_', ' ')}" is required.`)
      }
    })

    return dayErrors
  },

  emptyMultipleFormData({commit}) {
    commit(Types.EMPTY_MULTIPLE_FORM_DATA)
  },

  async updateScheduleDay({dispatch, commit}, {scheduleDay, model, model_uuid}) {
    commit(Types.SET_MULTIPLE_FORM_DATA_UPDATING_STATUS, {
      employee_uuid: model_uuid,
      year: scheduleDay.year,
      month: scheduleDay.month,
      day: scheduleDay.day,
      updating: true,
    })

    // // Create or Update timekeepingDay
    dispatch('createOrUpdate', {
      create: !scheduleDay.uuid,
      model: Object.assign({}, scheduleDay, {model, model_uuid})
    })
  },

  async createOrUpdate({dispatch, commit}, {create, model}) {
    let payload = model

    await dispatch('serialize', payload)
      .then(serialized => payload = serialized || payload)

    api.post(`${formatModuleUrl(module)}/${create ? 'create' : 'edit'}`, payload)
      .then(async response => {
        let data = response.data

        await dispatch('parse', data)
          .then(parsedData => data = parsedData || data)

        if(create)
          await dispatch('addToModel', {data})
        else
          await dispatch('editSingleModel', {data})

        commit(Types.REMOVE_SINGLE_MULTIPLE_FORM_DATA, data)
      })
      .catch((error) => {
        commit(Types.SET_MULTIPLE_FORM_DATA_UPDATING_STATUS, {
          employee_uuid: payload.model_uuid,
          year: payload.year,
          month: payload.month,
          day: payload.day,
          updating: false,
        })

        dispatch('employees/appendScheduleDaysErrors', error.errors, {root: true})
      })
  },

  async delete({dispatch, commit}, payload) {
    api.post(`${formatModuleUrl(module)}/delete`, payload)
      .then(async response => {
        let data = response.data

        await dispatch('parse', data)
          .then(parsedData => data = parsedData || data)

        commit(Types.DELETE_FROM_MODULE, data)

        commit(Types.REMOVE_SINGLE_MULTIPLE_FORM_DATA, data)
      })
      .catch(() => {
        commit(Types.SET_MULTIPLE_FORM_DATA_UPDATING_STATUS, {
          employee_uuid: payload.model_uuid,
          year: payload.year,
          month: payload.month,
          day: payload.day,
          updating: false,
        })
      })
  },

  validateWebsocketResponse({commit, rootGetters}, {type, event}) {
    const currentEmployee = rootGetters['employees/getCurrentModule']

    if(!event.data.date || !currentEmployee?.uuid || currentEmployee.uuid !== event.data?.model_uuid)
      return

    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [MultipleFormActions(['year', 'month', 'day'], module), Schema])
