import api from '~/plugins/axios';
import * as Types from './../../mutationTypes'
import { formatModuleUrl } from '@/helpers/formatters'
import { omit } from 'lodash'

export default {
  async create({rootGetters, dispatch, commit}, model) {
    commit(Types.FORM_IS_SUBMITTED)
    let formData = rootGetters[model + '/getFormData']

    let url = model.split(/(?=[A-Z])/).join('-').toLowerCase();

    // if we need serialize form data
    await dispatch(model + '/serialize', null, { root: true })
      .then(res => {
        if(res !== null)
          formData = res
      })

    api.post(url + '/create', formData)
      .then(async res => {
        await dispatch(`${model}/parse`, res, {root: true})
            .then(response => {
              if (response !== null)
                res = response
            })

        dispatch(model + '/addToModel', res, { root: true })
        commit(Types.FORM_IS_FINISHED)
        dispatch('closeForm')
      })
      .catch(() => {
        commit(Types.FORM_IS_FINISHED)
      })
  },

  async edit({rootGetters, dispatch, commit}, model) {
    commit(Types.FORM_IS_SUBMITTED)
    let formData = rootGetters[model + '/getFormData']

    // if we need serialize form data
    await dispatch(model + '/serialize', null, { root: true })
      .then(res => {
        if(res !== null)
          formData = res
      })

    api.post(`${formatModuleUrl(model)}/edit`, formData)
      .then(async res => {
        await dispatch(`${model}/parse`, res, {root: true})
          .then(response => {
            if (response !== null)
              res = response
          })

        dispatch(`${model}/editSingleModel`, res, { root: true })
        commit(Types.FORM_IS_FINISHED)
        dispatch('closeForm')
      })
      .catch(() => {})
  },

  showForm({commit, dispatch}, data) {
    if(typeof data.value !== 'undefined') {
      let modelAction = 'fillFormData'

      switch (data.action) {
        case 'archive':
        case 'unarchive':
          modelAction = 'fillArchiveFormData'
          break

        case 'blacklist':
          modelAction = 'fillBlacklistFormData'
          break

        case 'assignModel':
          modelAction = 'fillAssignFormData'
          break

        case 'unassignModel':
          modelAction = 'fillUnassignFormData'
          break

        case 'generateDocuments':
          modelAction = 'fillGenerateDocumentsFormData'
          break
      }

      dispatch(data.model + '/' + modelAction, data.value, { root: true })
    }

    commit(Types.SET_FORM_MODAL_DATA, data)
  },

  setFormMetaData({commit}, {key, value}) {
    commit(Types.SET_FORM_META_DATA, {key, value})
  },

  closeForm({commit, dispatch, getters}) {
    let emptyFormAction = 'emptyFormData'

    switch (getters['getFormAction']) {
      case 'upload':
        emptyFormAction = 'emptyFilesFormData'
        break

      case 'archive':
      case 'unarchive':
        emptyFormAction = 'emptyArchiveFormData'
        break

      case 'blacklist':
      case 'fire':
        emptyFormAction = 'emptyBlacklistFormData'
        break

      case 'assignModel':
        emptyFormAction = 'emptyAssignFormData'
        break

      case 'unassignModel':
        emptyFormAction = 'emptyUnassignFormData'
        break

      case 'generateDocuments':
        emptyFormAction = 'emptyGenerateDocumentsFormData'
        break
    }

    dispatch(getters['getFormModel'] + '/' + emptyFormAction , null, { root: true })

    commit(Types.SET_FORM_MODAL_DATA, {
      model: '',
      action: '',
      show: false,
      metaData: {},
    })

    dispatch('clearErrors')
  },

  setFormFieldsErrors({commit}, data) {
    commit(Types.SET_FORM_FIELDS_ERRORS, data)
  },

  setFormErrors({commit}, data) {
    console.log('Form errors: ', data)
    commit(Types.SET_FORM_ERRORS, data)
  },

  setFormName({commit}, name) {
    commit(Types.SET_FORM_NAME, name)
  },

  clearErrors({dispatch}) {
    dispatch('setFormFieldsErrors', [])
    dispatch('setFormErrors', [])
  },

  async upload({rootGetters, dispatch, commit}, model) {
    commit(Types.FORM_IS_SUBMITTED)

    let payload = rootGetters[`${model}/getFormFilesData`]

    await dispatch(`${model}/serializeUpload`, payload, { root: true })
      .then(serialized => payload = serialized || payload)

    const url = model !== 'documents' ? `/${formatModuleUrl(model)}/documents/create` : '/documents/create'

    api.post(url, payload)
      .then(response => {
        dispatch(`${model}/addDocuments`, Array.isArray(response.data) ? response.data : [], { root: true })
        commit(Types.FORM_IS_FINISHED)
        dispatch('closeForm')
      })
      .catch(() => {
        commit(Types.FORM_IS_FINISHED)
      })
  },

  async createWithDocuments({dispatch, commit, rootGetters}, model) {
    commit(Types.FORM_IS_SUBMITTED)

    const formData = Object.assign({}, rootGetters[`${model}/getFormData`], rootGetters[`${model}/getFormFilesData`])

    let payload = null
    await dispatch(`${model}/serializeCreateWithDocuments`, null, { root: true })
      .then(serialized => payload ??= serialized)

    await api.post(`/${formatModuleUrl(model)}/create`, payload ?? formData)
      .then(response => {
        dispatch(model + '/addToModel', response, { root: true })
        commit(Types.FORM_IS_FINISHED)
        dispatch('closeForm')
      })
      .catch(() => {
        commit(Types.FORM_IS_FINISHED)
      })
  },

  async archive({rootGetters, getters, dispatch, commit}, model) {
    commit(Types.FORM_IS_SUBMITTED)

    await api.post(`${formatModuleUrl(model)}/archive`, rootGetters[`${model}/getArchiveFormData`])
        .then(({data}) => {
          if(
              data &&
              typeof data?.uuid === 'string' &&
              data?.is_archived
          ) {
            dispatch(`${model}/archiveModule`, data, { root: true })

            dispatch('closeForm')
            return
          }

          dispatch('notification/addNotificationToList', {
            type: 'error',
            message: `Failed to archive ${getters['getModuleTitle']?.toLowerCase()}`,
            actions: {
              close: true,
            }
          }, { root: true })
        })
        .catch(() => {})

    commit(Types.FORM_IS_FINISHED)

    dispatch('warning/closeModal', null, {root: true})
  },

  async unarchive({rootGetters, getters, dispatch, commit}, model) {
    commit(Types.FORM_IS_SUBMITTED)

    await api.post(`${formatModuleUrl(model)}/unarchive`, rootGetters[`${model}/getArchiveFormData`])
        .then(({data}) => {
          if(
              data &&
              typeof data.uuid === 'string' &&
              !data.is_archived
          )
            dispatch(`${model}/unarchiveModule`, data, { root: true })

        })
        .catch(() => {
          dispatch('notification/addNotificationToList', {
            type: 'error',
            message: `Failed to archive ${getters['getModuleTitle']?.toLowerCase()}`,
            actions: {
              close: true,
            }
          }, { root: true })
        })

    commit(Types.FORM_IS_FINISHED)

    dispatch('closeForm')

    dispatch('warning/closeModal', null, {root: true})
  },

  /**
   * Assign module to another module.
   * Can be used directly (when 'data' is an object) or within a form (when 'data' is string module name )
   *
   * Parameters:
   *    - module - base module
   *    - submodule - module being assigned
   *    - payload - payload for the assign request
   *    - module_callback - callback for successful request with response data (by default 'assignedModule' action)
   */
  async assignModel({dispatch, commit, getters, rootGetters}, data) {
    const {module, submodule, module_callback} = typeof data === 'string' ? Object.assign({}, getters['getFormMetaData']) : data

    let payload = data?.payload
    if(typeof data === 'string')
      payload = Object.assign({}, rootGetters[`${data}/getAssignFormData`], omit(getters['getFormMetaData'], ['module', 'submodule', 'module_callback']))

    if(getters['showForm'])
      commit(Types.FORM_IS_SUBMITTED)

    await dispatch(`${module}/serialize`, payload, { root: true })
      .then(serialized => payload = serialized !== null ? serialized : payload )

    return await api.post(`/${formatModuleUrl(module)}/assign/${formatModuleUrl(submodule)}`, payload)
      .then(async response => {
        if(getters['showForm'])
          commit(Types.FORM_IS_FINISHED)

        const isResponseBoth = [undefined, 'both'].includes(payload?.response)

        // Parse main module
        let assignedTo = undefined
        if(isResponseBoth || payload?.response === 'model') {
          const assignedToData = isResponseBoth ? response.data.model : response.data

          await dispatch(`${module}/parse`, assignedToData, { root: true })
            .then(parsed => assignedTo = parsed !== null ? parsed : assignedToData)
        }

        // Parse submodule
        let assignedModel = undefined
        if(isResponseBoth || payload?.response === 'submodel') {
          const assignedModelData = isResponseBoth ? response.data.submodel : response.data

          await dispatch(`${submodule}/parse`, assignedModelData, { root: true })
              .then(parsed => assignedModel = parsed !== null ? parsed : assignedModelData)
        }

        // Callback for base module unassign logic
        dispatch(module_callback || `${module}/assignedModule`, {
          submodule,
          payload,
          assignedTo,
          assignedModel,
        }, { root: true })


        // Close warning modal if open
        if(rootGetters['warning/showModal'])
          dispatch('warning/closeModal', null, { root: true })

        // Close form if open
        if(getters['showForm'])
          dispatch('closeForm')
      }).catch(() => {
        commit(Types.FORM_IS_FINISHED)

        dispatch(`${module}/failedAssignedModel`, null, { root: true })
      })
  },

  /**
   * Unassign module from another module.
   * Can be used directly (when 'data' is an object) or within a form (when 'data' is string module name )
   *
   * Parameters:
   *    - module - base module
   *    - submodule - module being unassigned
   *    - payload - payload for the unassign request
   *    - module_callback - callback for successful request with response data (by default 'unassignedModule' action)
   */
  async unassignModel({dispatch, commit, getters, rootGetters}, data) {
    const {module, submodule, module_callback} = typeof data === 'string' ? Object.assign({}, getters['getFormMetaData']) : data

    let payload = data?.payload
    if(typeof data === 'string')
      payload = Object.assign({}, rootGetters[`${data}/getUnassignFormData`], omit(getters['getFormMetaData'], ['module', 'submodule', 'module_callback']))

    if(getters['showForm'])
      commit(Types.FORM_IS_SUBMITTED)

    return await api.post(`/${formatModuleUrl(module)}/unassign/${formatModuleUrl(submodule)}`, payload)
      .then(async response => {
        if(getters['showForm'])
          commit(Types.FORM_IS_FINISHED)

        const isResponseBoth = [undefined, 'both'].includes(payload?.response)

        // Parse main module
        let unassignedFrom = undefined
        if(isResponseBoth || payload?.response === 'model') {
          const unassignedFromData = isResponseBoth ? response.data.model : response.data

          await dispatch(`${module}/parse`, unassignedFromData, { root: true })
            .then(parsed => unassignedFrom = parsed !== null ? parsed : unassignedFromData)
        }

        // Parse submodule
        let unassignedModel = undefined
        if(isResponseBoth || payload?.response === 'submodel') {
          const unassignedModelData = isResponseBoth ? response.data.submodel : response.data

          await dispatch(`${submodule}/parse`, unassignedModelData, { root: true })
              .then(parsed => unassignedModel = parsed !== null ? parsed : unassignedModelData)
        }

        // Callback for base module unassign logic
        dispatch(module_callback || `${module}/unassignedModule`, {
          submodule,
          payload,
          unassignedFrom,
          unassignedModel,
        }, { root: true })


        // Close warning modal if open
        if(rootGetters['warning/showModal'])
          dispatch('warning/closeModal', null, { root: true })

        // Close form if open
        if(getters['showForm'])
          dispatch('closeForm')
      }).catch(() => {
        commit(Types.FORM_IS_FINISHED)

        dispatch(`${module}/failedUnassignedModel`, null, { root: true })
      })
  },

  async blacklist({dispatch, commit, rootGetters}, model) {
    commit(Types.FORM_IS_SUBMITTED)
    let formData = rootGetters[`${model}/getBlacklistFormData`]

    // if we need serialize form data
    await dispatch(`${model}/serializeBlacklist`, null, { root: true })
      .then(res => formData = res !== null ? res : formData)

    api.post(`${formatModuleUrl(model)}/blacklist`, formData)
      .then(async res => {
        res = res?.data

        await dispatch(`${model}/parse`, res, {root: true})
          .then(response => res = response !== null ? response : res)

        dispatch(`${model}/blacklistedModel`, res, { root: true })

        commit(Types.FORM_IS_FINISHED)

        dispatch('closeForm')

        dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `"${res[rootGetters[`${model}/getNotificationKey`]]}" was successfully blacklisted`,
          actions: { close: true }
        }, { root: true })
      })
      .catch(() => {
        commit(Types.FORM_IS_FINISHED)

        dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `Failed to blacklist "${formData[rootGetters[`${model}/getNotificationKey`]]}"`,
          actions: { close: true }
        }, { root: true })
      })
  },

  async fire({dispatch, commit, rootGetters}, model) {
    commit(Types.FORM_IS_SUBMITTED)
    let formData = rootGetters[`${model}/getBlacklistFormData`]

    // if we need serialize form data
    await dispatch(`${model}/serializeFire`, null, { root: true })
      .then(res => formData = res !== null ? res : formData)

    api.post(`${formatModuleUrl(model)}/fire`, formData)
      .then(res => {
        dispatch(`${model}/fire`, res.data, {root: true})
        commit(Types.FORM_IS_FINISHED)

        dispatch('closeForm')
      })
      .catch(() => {
        commit(Types.FORM_IS_FINISHED)
      })
  },

  async send({dispatch, commit, rootGetters}, model) {
    commit(Types.FORM_IS_SUBMITTED)
    let payload = rootGetters[`${model}/getFormData`]

    await dispatch(`${model}/serialize`, null, { root: true })
      .then(serialized => payload = serialized || payload)

    api.post(`${formatModuleUrl(model)}/send`, payload)
      .then(response => {
        dispatch(`${model}/send`, {
          payload,
          data: response.data
        }, {root: true})

        commit(Types.FORM_IS_FINISHED)

        dispatch('closeForm')
      })
      .catch(() => {
        commit(Types.FORM_IS_FINISHED)
      })
  },

  async generateDocuments({dispatch, commit, rootGetters}, model) {
    commit(Types.FORM_IS_SUBMITTED)
    let payload = rootGetters[`${model}/getFormData`]

    await dispatch(`${model}/serialize`, null, { root: true })
      .then(serialized => payload = serialized || payload)

    api.post(`${formatModuleUrl(model)}/documents/generate`, payload)
      .then(response => {
        dispatch(`${model}/addDocuments`, Array.isArray(response.data) ? response.data : [response.data], { root: true })

        commit(Types.FORM_IS_FINISHED)

        dispatch('closeForm')
      })
      .catch(() => {
        commit(Types.FORM_IS_FINISHED)
      })
  },

  async documentMassSign({dispatch}, model) {
    if(model !== 'signature')
      return;

      dispatch('signature/documentMassSign', null, {root: true})
  },
}
