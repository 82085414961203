import * as Types from "@/store/mutationTypes";
import BaseActions from '@/store/base/actions'
import {formatModuleUrl} from "@/helpers/formatters";
import api from '@/plugins/axios'

const module = 'employeeSchedules'

export default BaseActions({module}, {
  async fetchModel({commit, getters}, employee_uuid = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post(`${formatModuleUrl(module)}/get-by-key`, {key: 'employee_uuid', value: employee_uuid}, {trigger404: false})
      .then(res => commit(Types.SET_MODEL, res.data))
  },

  async fetchProjectModels({commit, getters}, project_uuid = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post(`${formatModuleUrl(module)}/get-by-key`, {key: 'project_uuid', value: project_uuid}, {trigger404: false})
      .then(res => commit(Types.SET_MODEL, res.data))
  },

  async approve({dispatch, getters}, {uuid, employee_uuid, year, month, timekeeping_template_uuid}) {
    // approve existing schedule
    const model = getters['findSchedule'](employee_uuid, timekeeping_template_uuid, uuid)

    if(model) {
      dispatch('toggleApprove', {approve: true, data: model})
      return
    }

    // create new schedule and approve
    await dispatch('updateSchedule', {
      employee_uuid,
      year,
      month,
      timekeeping_template_uuid: timekeeping_template_uuid,
      key: 'approve',
      value: true,
    })

    dispatch('warning/closeModal', null, { root: true })
  },

  async approveModel({dispatch, commit}, data) {
    dispatch('addSingleModel', {data: data})
      .then(() => commit(Types.APPROVE_MODEL, data))

    dispatch('warning/closeModal', null, { root: true })
  },

  async refuseModel({dispatch, commit}, data) {
    commit(Types.UPDATE_SINGLE_MODEL, data)

    dispatch('warning/closeModal', null, { root: true })
  },

  async updateSchedule({dispatch, commit, getters}, {scheduleUuid, employee_uuid, year, month, timekeeping_template_uuid, key, value}) {
    let payload = getters['findSchedule'](employee_uuid, timekeeping_template_uuid, scheduleUuid) || {employee_uuid, year, month}
    payload[key] = value
    payload.timekeeping_template_uuid = timekeeping_template_uuid

    await dispatch('serialize', payload)
      .then(serialized => payload = serialized || payload)

    const create = !payload?.uuid

    const updatingSchedule = {schedule_uuid: scheduleUuid, employee_uuid, year, month}
    commit(Types.ADD_UPDATING_SCHEDULES, updatingSchedule)

    api.post(`${formatModuleUrl(module)}/${create ? 'create' : 'update'}`, payload)
      .then(async response => {
        let data = response.data

        await dispatch('parse', data)
          .then(parsedData => data = parsedData || data)

        if(create)
          await dispatch('addToModel', {data})
        else
          await dispatch(`${module}/editSingleModel`, {data}, { root: true })

        commit(Types.REMOVE_UPDATING_SCHEDULES, updatingSchedule)
      }).catch(() => commit(Types.REMOVE_UPDATING_SCHEDULES, updatingSchedule))
  },

  validateWebsocketResponse({commit, rootGetters}, {type, event}) {
    const currentEmployee = rootGetters['employees/getCurrentModule']

    if(!currentEmployee?.uuid || currentEmployee.uuid !== event.data?.employee_uuid)
      return

    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
})
