import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'SMS template',

  formData: {
    uuid: '',
    title: '',
    content: '',
  },

  dummyData: {
    uuid: '',
    title: 'title',
    content: 'description',
  },

  notificationTitle: 'title',
  primaryKey: 'uuid',

  searchBy: [],
})