import * as Types from './../../mutationTypes'

export default {
  showWarningModal({ commit }, data) {
    commit(Types.SET_WARNING_MODAL_DATA, data)
  },

  closeModal({ commit }) {
    commit(Types.CLOSE_WARNING_MODAL)
  },

  modalLoading({ commit }) {
    commit(Types.WARNING_MODAL_IS_LOADING)
  }
}