import * as Types from "@/store/mutationTypes";
import BaseMutations from '@/store/base/mutations'
import MultiFormMutations from '@/store/base/multipleFormData/mutations'
import {getValueWithoutReference} from "@/helpers/formatters";

// const module = 'employeeRotations'

export default BaseMutations({
  [Types.ADD_TO_MODEL](state, data) {
    if(!data?.employee_uuid)
      return

    state.module[data.employee_uuid] = data
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    data.forEach(oldModule => {
      if(state.module?.[data.employee_uuid])
        delete state.module[data.employee_uuid]

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `${state.moduleTitle} "${oldModule[state.notificationTitle]}" was deleted.`,
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, {employee_uuid}) {
      if(state.module?.[employee_uuid])
        delete state.module[employee_uuid]
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let module = Object.values(state.module).find(model => model.uuid === uuid)

    if(!module && state?.currentModule?.uuid === uuid)
      module = state.currentModule

    // Fill form data without object references
    if(module)
      Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    if(state.module?.[data.employee_uuid])
      state.module[data.employee_uuid] = data
  },
}, [MultiFormMutations()])