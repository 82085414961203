import BaseActions from '@/store/base/actions'
import api from '@/plugins/axios'
import * as Types from '@/store/mutationTypes'
import PersonActions from '@/store/base/person/actions'
import DocumentsActions from '@/store/base/documents/actions'
import DetailActions from "@/store/base/detail/actions";
import BlacklistActions from "@/store/base/blacklist/actions";
import TimekeepingActions from "@/store/base/timekeeping/actions";
import HistoriesActions from "@/store/base/histories/actions";
import Schema from "@/store/modules/employees/schema";
import router from "@/router";
import {isClient, formatModuleUrl, addPad} from "@/helpers/formatters";
import moment from "moment";
import {downloadFile} from "@/helpers/files";
const module = 'employees'

export default BaseActions({module}, {
  fetchedModel({dispatch}) {
    if(router.currentRoute.value.name === 'rotation-table')
      dispatch('initiateRotationTable')
  },

  async fetchCurrentModuleWarehouse({dispatch, commit}, uuid) {
    const warehouse = await dispatch('fetchCurrentModuleSubModules', {submoduleTitle: 'warehouse', uuid})

    commit(Types.SET_CURRENT_MODULE_WAREHOUSE, warehouse)
  },

  updateWarehouseFromProfile({dispatch, commit, getters}, data) {
    const tool = getters['getWarehouse'].find(tool => tool.uuid === data.uuid)

    if(tool) {
      commit(Types.UPDATE_WAREHOUSE_IN_CURRENT_MODULE, data)
      return
    }

    dispatch('addWarehouseToProfile', data)
  },

  addWarehouseToProfile({commit}, data) {
    commit(Types.ADD_WAREHOUSE_TO_CURRENT_MODULE, data)
  },

  removeWarehouseFromProfile({commit}, data) {
    commit(Types.REMOVE_WAREHOUSE_FROM_CURRENT_MODULE, data)
  },

  updateCurrentModuleWarehouse({commit}, data) {
    commit(Types.UPDATE_CURRENT_MODULE_WAREHOUSE, data)
  },

  addHiredCandidate({commit}, hiredCandidate) {
    commit(Types.ADD_HIRED_CANDIDATE, hiredCandidate)

    if(router.currentRoute.value.name === 'candidate')
      router.replace({name: 'profile', params: {slug: hiredCandidate.slug}})
  },

  fire({dispatch, commit, rootGetters}, firedEmployee) {
    commit(Types.FIRE_EMPLOYEE, firedEmployee)

    dispatch('candidates/addFiredEmployee', firedEmployee, {root: true})
      .then(() => {
        dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `"${firedEmployee[rootGetters['candidates/getNotificationKey']]}" was successfully fired`,
          actions: { close: true }
        }, { root: true })
      }).catch(() => {
        dispatch('notification/addNotificationToList', {
          type: 'error',
          message: `Failed to fire "${firedEmployee[rootGetters['candidates/getNotificationKey']]}"`,
          actions: { close: true }
        }, { root: true })
      })
  },

  validateFire({dispatch, getters}, {uuid, title}) {
    const module = getters['getModuleWithCurrent'](uuid)

    if(0 < module.tools_count) {
      dispatch('warning/showWarningModal', {
          question: `You can't fire "${title}" because of the remaining errors:`,
          description: '',
          list: ['Employee has tools.'],
          action: '',
          showConfirmButton: false
      }, {root: true})

      return
    }

    dispatch('form/showForm', {
        model: 'employees',
        action: 'fire',
        show: true,
        title: 'Fire employee'
      }, { root: true })
  },

  async unassignedTool({dispatch, commit, getters, rootGetters}, {unassignedModel, unassignedFrom}) {
    if(getters['getCurrentModuleUuid'] === unassignedModel.uuid)
      commit(Types.REMOVE_WAREHOUSE_FROM_CURRENT_MODULE, unassignedFrom.uuid)

    dispatch('warehouse/removeEmployee', unassignedFrom, { root: true })

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Tool "${unassignedFrom[rootGetters['warehouse/getNotificationKey']]}" was successfully unassigned from employee "${unassignedModel[getters['getNotificationKey']]}"`,
      actions: { close: true }
    }, { root: true })
  },

  async assignedTool({dispatch, commit, getters, rootGetters}, {assignedModel, assignedTo}) {
    if(getters['getCurrentModuleUuid'] === assignedModel.uuid)
      commit(Types.ADD_WAREHOUSE_TO_CURRENT_MODULE, assignedTo)

    dispatch('warehouse/addEmployee', assignedTo, { root: true })

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Tool "${assignedTo[rootGetters['warehouse/getNotificationKey']]}" was successfully assigned to employee "${assignedModel[getters['getNotificationKey']]}"`,
      actions: { close: true }
    }, { root: true })
  },

  async removeProject({commit}, project) {
    commit(Types.REMOVE_PROJECT_FROM_EMPLOYEE, project)
  },

  async addProject({commit}, data) {
    if(isClient())
      return
    commit(Types.ADD_PROJECT_TO_EMPLOYEE, data)
  },

  async currentModuleFetched({dispatch, getters}, {uuid}) {
    if(!uuid)
      return

    switch (getters['getCurrentModuleTab']) {
      case 'warehouse':
        dispatch('fetchCurrentModuleWarehouse', uuid)
        break

      case 'accommodation':
        dispatch('fetchCurrentModuleAccommodation', uuid)
        break

      case 'travel':
        dispatch('fetchCurrentModuleTransport', uuid)
        break

      case 'schedule':
        dispatch('fetchCurrentModuleTimekeepingData', uuid)
        break
    }
  },

  async fetchCurrentModuleAccommodation({dispatch, commit}, uuid) {
    const accommodation = await dispatch('fetchCurrentModuleSubModules', {submoduleTitle: 'accommodations', uuid})

    commit(Types.SET_CURRENT_MODULE_ACCOMMODATION, accommodation || {})
  },

  setCurrentModuleAccommodation({commit}, accommodation) {
    commit(Types.SET_CURRENT_MODULE_ACCOMMODATION, accommodation)
  },

  setCurrentModuleTransport({commit}, transport) {
    commit(Types.SET_CURRENT_MODULE_TRANSPORT, transport)
  },

  async fetchCurrentModuleTransport({dispatch, commit}, uuid) {
    const transport = await dispatch('fetchCurrentModuleSubModules', {submoduleTitle: 'transports', uuid})

    commit(Types.SET_CURRENT_MODULE_TRANSPORT, transport || {})
  },

  async fetchCurrentModuleTimekeepingData({dispatch, getters, rootGetters}, uuid) {
    const currentModuleUuid = uuid || getters['getCurrentModuleUuid']

    if(!currentModuleUuid)
      return

    if(!getters['getScheduleYear'] || !getters['getScheduleMonth']) {
      await dispatch('setScheduleDate', moment())
    }

    if(!rootGetters['employeeTimekeepingTemplates/isFetched'])
      dispatch('employeeTimekeepingTemplates/fetchModel', currentModuleUuid, {root: true})

    if(!rootGetters['timekeepingDays/isFetched'])
      dispatch('timekeepingDays/fetchModel', currentModuleUuid, {root: true})

    if(!rootGetters['employeeSchedules/isFetched'])
      dispatch('employeeSchedules/fetchModel', currentModuleUuid, {root: true})
  },

  async fetchTimekeepingTemplate({commit, getters}, uuid) {
    let submodule

    const moduleUuid = uuid || getters.getCurrentModule?.uuid || null
    if(moduleUuid) {
      await api.post(`employee-timekeeping-templates/get-by-key`, {key: 'employee_uuid', value: moduleUuid }, { trigger404: false })
        .then(({data}) => submodule = data[0] ?? undefined)
        .catch(() => {})
    }

    commit(Types.SET_CURRENT_MODULE_TIMEKEEPING_TEMPLATE, submodule || {})
  },

  async fetchCurrentModuleTrainings({dispatch, getters}, uuid) {
    let trainings = null

    const moduleUuid = uuid || getters.getCurrentModule?.uuid || null
    if(moduleUuid) {
      await api.post(`${formatModuleUrl('employeeTrainings')}/get-by-key`, {
        key: 'employee_uuid',
        value: moduleUuid
      }, { trigger404: false })
        .then(response => trainings = response.data)
        .catch(() => {})
    }

    if(!Array.isArray(trainings))
      trainings = []

    dispatch('employeeTrainings/setModules', trainings || [], { root: true })
  },

  async assignedAccommodation({dispatch, commit, getters, rootGetters}, {assignedModel, assignedTo}) {
    if(getters['getCurrentModuleUuid'] === assignedTo.uuid) {
      let currentModuleData = assignedTo
      await dispatch('parseCurrentModule', assignedTo)
        .then(parsed => currentModuleData = parsed !== null ? parsed : assignedTo)

      commit(Types.SET_CURRENT_MODULE, currentModuleData)

      commit(Types.SET_CURRENT_MODULE_ACCOMMODATION, assignedModel)
    }

    commit(Types.UPDATE_SINGLE_MODEL, assignedTo)

    if(router.currentRoute.value.name === 'accommodation' && rootGetters['accommodations/getCurrentModuleUuid'] === assignedModel.uuid)
      dispatch('accommodations/updateEmployeeFromAccommodation', assignedTo, {root: true})

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Accommodation "${assignedModel[rootGetters['accommodations/getNotificationKey']]}" was successfully assigned to employee "${assignedTo[getters['getNotificationKey']]}"`,
      actions: { close: true }
    }, { root: true })
  },

  async unassignedAccommodation({dispatch, commit, getters, rootGetters}, {unassignedModel, unassignedFrom}) {
    if(getters['getCurrentModuleUuid'] === unassignedFrom.uuid)
      commit(Types.REMOVE_ACCOMMODATION_FROM_CURRENT_MODULE, unassignedModel.uuid)

    commit(Types.UPDATE_SINGLE_MODEL, unassignedFrom)

    if(router.currentRoute.value.name === 'accommodation' && rootGetters['accommodations/getCurrentModuleUuid'] === unassignedModel.uuid)
      dispatch('accommodations/removeEmployeeFromAccommodation', unassignedFrom, {root: true})

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Accommodation "${unassignedModel[rootGetters['accommodations/getNotificationKey']]}" was successfully unassigned from employee "${unassignedFrom[getters['getNotificationKey']]}"`,
      actions: { close: true }
    }, { root: true })
  },

  async assignedTransport({dispatch, commit, getters, rootGetters}, {assignedModel, assignedTo}) {
    if(getters['getCurrentModuleUuid'] === assignedTo.uuid) {
      let currentModuleData = assignedTo
      await dispatch('parseCurrentModule', assignedTo)
        .then(parsed => currentModuleData = parsed !== null ? parsed : assignedTo)

      commit(Types.SET_CURRENT_MODULE, currentModuleData)

      commit(Types.SET_CURRENT_MODULE_TRANSPORT, assignedModel)
    }

    commit(Types.UPDATE_SINGLE_MODEL, assignedTo)

    if(router.currentRoute.value.name === 'transport' && rootGetters['transports/getCurrentModuleUuid'] === assignedModel.uuid)
      dispatch('transports/updateEmployeeFromTransport', assignedTo, {root: true})

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Transport "${assignedModel[rootGetters['transports/getNotificationKey']]}" was successfully assigned to employee "${assignedTo[getters['getNotificationKey']]}"`,
      actions: { close: true }
    }, { root: true })
  },

  async unassignedTransport({dispatch, commit, getters, rootGetters}, {unassignedFrom, unassignedModel}) {
    if(getters['getCurrentModuleUuid'] === unassignedFrom.uuid)
      commit(Types.REMOVE_TRANSPORT_FROM_CURRENT_MODULE, unassignedModel.uuid)

    commit(Types.UPDATE_SINGLE_MODEL, unassignedFrom)

    if(router.currentRoute.value.name === 'transport' && rootGetters['transports/getCurrentModuleUuid'] === unassignedModel.uuid)
      dispatch('transports/removeEmployeeFromTransport', unassignedFrom, {root: true})

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Transport "${unassignedModel[rootGetters['transports/getNotificationKey']]}" was successfully unassigned from employee "${unassignedFrom[getters['getNotificationKey']]}"`,
      actions: { close: true }
    }, { root: true })
  },

  async assignProjectToEmployee({dispatch}, {module_uuid, assign_uuid}) {
    await dispatch('form/setFormName', 'projectAssign', { root: true })

    await dispatch('form/assignModel', {
      module: 'employees',
      submodule: 'projects',
      module_callback: 'projects/assignedEmployee',
      payload: {
        module_uuid,
        assign_uuid,
      },
    }, { root: true })

    dispatch('form/setFormName', null, { root: true })
  },

  setScheduleDate({dispatch}, date) {
    const momentJsDate = moment(date)

    if(!momentJsDate.isValid())
      return

    dispatch('setScheduleYear', momentJsDate.year())

    dispatch('setScheduleMonth', momentJsDate.month() + 1)

    dispatch('setTimekeepingData', momentJsDate)
  },

  setTimekeepingData({commit}, date) {
    let timekeepingData = []

    const daysInMonth = date.daysInMonth()

    // Weekday of the first month day
    let weekday = moment(`${date.year()}-${date.month() + 1}-01`, 'YYYY-MM-DD').isoWeekday()
    for(let day = 1; day <= daysInMonth; day++) {
      if(7 < weekday)
        weekday = 1

      timekeepingData.push({
        weekday,
        day
      })

      weekday++
    }
    commit(Types.SET_TIMEKEEPING_DAYS, timekeepingData)
  },

  unsetTimekeepingDays({commit}) {
    commit(Types.UNSET_TIMEKEEPING_DAYS)
  },

  setScheduleYear({commit}, year) {
    commit(Types.SET_SCHEDULE_YEAR, year)
  },

  setScheduleMonth({commit}, month) {
    commit(Types.SET_SCHEDULE_MONTH, month)
  },

  async setTimekeepingDayData({dispatch}, {employee_uuid, year, month, day, property, value, weekday, dayIndex}) {
    await dispatch('timekeepingDays/setMultipleFormData', {employee_uuid, year, month, day, property, value, weekday, dayIndex}, { root: true })
  },

  appendScheduleDaysErrors({commit}, errors) {
    commit(Types.APPEND_SCHEDULE_DAYS_ERRORS, errors)
  },

  clearSingleMultipleFormErrors({commit}, formIndex) {
    commit(Types.CLEAR_SINGLE_MULTIPLE_FORM_ERRORS, formIndex)
  },

  async exportScheduleXLSX({commit, dispatch}, data) {
    commit(Types.EXPORTING_EMPLOYEE_SCHEDULE, true)

    await api.post(`employees/schedule/export`, data)
      .then(response => {
        commit(Types.EXPORTING_EMPLOYEE_SCHEDULE, false)

        dispatch('employeeScheduleExports/addToModel', response, { root: true })
      })
  },

  /**
   * Approve employee schedule.
   * Validate approval by checking amount of schedule errors.
   *
   * @param dispatch
   * @param getters
   * @param uuid
   * @param employee_uuid
   * @param employee_full_name
   * @param year
   * @param month
   * @param timekeeping_template_uuid
   * @returns {Promise<void>}
   */
  async approveSchedule({dispatch, getters}, {uuid, employee_uuid, employee_full_name, year, month, timekeeping_template_uuid}) {
    const errors = []

    Object.values(getters['getScheduleErrors']).forEach(fieldErrors => {
      fieldErrors.forEach(error => errors.push(error))
    })

    if(errors?.length === 0) {
      dispatch('warning/showWarningModal', {
        question: `Are you sure you want to approve ${employee_full_name} schedule for ${year}-${addPad(month)}?`,
        description: '',
        action: 'employeeSchedules/approve',
        data: {uuid, employee_uuid, year, month, timekeeping_template_uuid},
        cancelTitle: 'No',
        confirmTitle: 'Yes',
      }, { root: true })

      return
    }

    const displayErrors = 5 < errors.length ? [...errors.slice(0, 4), '...'] : errors

    dispatch('warning/showWarningModal', {
      question: `You can't approve ${employee_full_name} schedule for ${year}-${addPad(month)} because of the remaining errors:`,
      description: '',
      list: displayErrors,
      action: '',
      showConfirmButton: false
    }, {root: true})
  },

  async refuseSchedule({dispatch}, {employee_full_name, value, year, month}) {
    dispatch('warning/showWarningModal', {
      question: `Are you sure you want to cancel ${employee_full_name} schedule for ${year}-${addPad(month)}?`,
      description: '',
      action: 'employeeSchedules/refuse',
      data: {uuid: value},
      cancelTitle: 'No',
      confirmTitle: 'Yes',
    }, { root: true })
  },

  exportRotationTableXLSX({commit}) {
    commit(Types.EXPORTING_ROTATION_TABLE, true)

    api.post(`${formatModuleUrl(module)}/rotation-table/export`, {},{responseType: 'blob'})
      .then(response => {
        downloadFile(response, 'Rotation table')

        commit(Types.EXPORTING_ROTATION_TABLE, false)
      })
  },

  setRotationTableDate({commit}, date) {
    commit(Types.SET_ROTATION_TABLE_DATE, {
      date,
    })
  },

  unshiftRotationTableDate({getters, commit}) {
    const month = getters['sliceRotationTableNextMonth'](false)

    if(!month?.year || !month.month)
      return

    commit(Types.SET_ROTATION_TABLE_DATE, {
      date: moment(`${month.year}-${month.month}`, 'YYYY-M').subtract(1, 'M'),
      push: false,
    })
  },

  pushRotationTableDate({getters, commit}) {
    const month = getters['sliceRotationTableNextMonth'](true)

    if(!month?.year || !month.month)
      return

    commit(Types.SET_ROTATION_TABLE_DATE, {
      date: moment(`${month.year}-${month.month}`, 'YYYY-M').add(1, 'M'),
      push: true,
    })
  },

  initiateRotationTable({commit}) {
    commit(Types.INITIATE_ROTATION_TABLE)
  },

  unsetRotationTable({commit}) {
    commit(Types.UNSET_ROTATION_TABLE)
  },


  setGenerateDocumentsData({dispatch, getters, rootGetters}, uuid) {
    const data = getters['getModelData'](uuid)

    // Positions
    dispatch('setGenerateDocumentsFormInputData', {key: 'position', value: rootGetters['employeeResponsibilities/getPositionTitle'](data?.positions_uuid || [], 'lt')})

    // Address
    let address = data?.address || ''

    const country = rootGetters['locations/getCountry'](data.country_id)
    const city = rootGetters['locations/getCountryCity'](data.country_id, data.city_id)

    if(city?.title_lt)
      address += `, ${city.title_lt}`
    else if(city?.title)
      address += `, ${city.title}`

    if(country?.title_lt)
      address += `, ${country.title_lt}`

    dispatch('setGenerateDocumentsFormInputData', {key: 'address', value: address})
  },

  exportWarehousePDF({getters, commit}, uuid) {
    commit(Types.EXPORTING_EMPLOYEE_WAREHOUSE, true)

    const employee = getters['getModelData'](uuid)

    api.post(`${formatModuleUrl(module)}/warehouse/export`, {uuid},{responseType: 'blob'})
      .then(response => {
        downloadFile(response, `Tools of ${employee?.full_name || 'employee'}`)

        commit(Types.EXPORTING_EMPLOYEE_WAREHOUSE, false)
      })
  },

  async fetchHistories({dispatch, commit}, {uuid, limit = 20, page}) {
    commit(Types.SET_IS_FETCHING_HISTORIES, true)

    api.post(`${formatModuleUrl(module)}/history`, {uuid, page, model: [module, 'candidates'], limit})
      .then(async ({data}) => {
        await dispatch('setHistories', {data, append: true})

        dispatch('setHistoriesLoadedLength', data?.length || 0)

        commit(Types.SET_IS_FETCHING_HISTORIES, false)

      }).catch(() => dispatch('setHistoriesLoadedLength', 0))
  },

  validateWebsocketResponse({dispatch, rootGetters, commit, getters}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        if(getters['cancelDocumentsWebsocket'](event))
          return

        commit(Types.ADD_TO_MODEL, event.data)

        if(router.currentRoute.value.name === 'accommodation' && rootGetters['accommodations/getCurrentModuleUuid'] === event.data.accommodation_uuid)
          dispatch('accommodations/updateEmployeeFromAccommodation', event.data, { root: true })

        if(router.currentRoute.value.name === 'transport' && rootGetters['transports/getCurrentModuleUuid'] === event.data.transport_uuid)
          dispatch('transports/updateEmployeeFromTransport', event.data, { root: true })
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)

        if(router.currentRoute.value.name === 'accommodation' && [rootGetters['accommodations/getCurrentModuleUuid'], null].includes(event.data.accommodation_uuid))
          dispatch('accommodations/updateEmployeeFromAccommodation', event.data, { root: true })

        if(router.currentRoute.value.name === 'transport' && [rootGetters['transports/getCurrentModuleUuid'], null].includes(event.data.transport_uuid))
          dispatch('transports/updateEmployeeFromTransport', event.data, { root: true })
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)

        if(router.currentRoute.value.name === 'accommodation')
          dispatch('accommodations/removeEmployeeFromAccommodation', event.data, { root: true })

        if(router.currentRoute.value.name === 'transport')
          dispatch('transports/removeEmployeeFromTransport', event.data, { root: true })
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },

  fillAssignFormData({commit, dispatch, rootGetters}, data) {
    commit(Types.FILL_ASSIGN_FORM_DATA, data)

    if(
      (router.currentRoute.value.name === 'accommodation' && rootGetters['accommodations/getCurrentModuleTab'] === 'residents') ||
      (router.currentRoute.value.name === 'transport' && rootGetters['transports/getCurrentModuleTab'] === 'employees')
    ) {
      dispatch('setAssignFormInputData', {key: 'module_uuid', value: data})
    }
  },

  filter({commit}) {
    commit(Types.FILTER_MODULE, {
      searchBy: 'searchBy',
      rootModule: 'module'
    })
  },
}, [PersonActions, DocumentsActions({module}), BlacklistActions({module}), DetailActions({module}), TimekeepingActions({module}), HistoriesActions({module}), Schema])
