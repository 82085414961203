import {encodeBase64, transformToLatin} from "@/helpers/formatters";
import {isJson} from "@/helpers/formatters";
import {clone} from "lodash";

export default {
  async serializeCreateWithDocuments({getters, dispatch}) {
    const filesData = getters['getFormFilesData']

    let data = null
    await dispatch('serialize').then(serialized => data ??= serialized)

    if(data === null)
      return null

    const payload = new FormData()
    for(const key in data) {
      if(Array.isArray(data[key])) {
        [...data[key]].forEach(value => {
          let appendValue = value

          if(isJson(appendValue)) {
            appendValue = JSON.parse(appendValue)

            Object.keys(appendValue).forEach((valueKey) => {
              appendValue[valueKey] = encodeBase64(appendValue[valueKey])
            })

            appendValue = JSON.stringify(appendValue)

          }else
            appendValue = encodeBase64(appendValue)

          payload.append(`${key}[]`, appendValue)
        })

        continue
      }
      payload.append(key, encodeBase64(data[key]))
    }

    await dispatch('serializeUpload', filesData)
      .then(serializedFormData => {
        if(!(serializedFormData instanceof FormData)) {
          if(process.env.VUE_APP_DEBUG_MODE === 'true')
            console.error('Files serialization failed. FormData object expected.')

          return
        }

        for(const entry of serializedFormData.entries()) {
          payload.append(entry[0], entry[1])
        }
      })

    return payload
  },

  async serializeUpload({dispatch}, formData) {
    const payload = new FormData()

    for(const key in formData) {
      if(!Array.isArray(formData[key])) {
        payload.append(key, encodeBase64(formData[key]))
        continue
      }

      // Serialize each individual document data
      if(key === 'documents') {
        for(const document of formData[key])
          await dispatch('serializeDocument', clone(document)).then(data => Object.keys(data).forEach(key => payload.append(`${key}[]`, data[key])))

        continue
      }

      [...formData[key]].forEach(data => payload.append(`${key}[]`, encodeBase64(data)))
    }

    return payload
  },

  /**
   * Serialize single document data
   *
   * @param context
   * @param document
   * @returns {{}}
   */
  serializeDocument(context, document) {
    const data = {}

    const file = document.file

    data.documents = new File([file], transformToLatin(file.name), {
      type: file.type,
      lastModified: file.lastModified,
    })

    const isInvoice = !!document.is_invoice

    const products = []
    document?.products?.forEach(product => {
      products.push({
        product_code: isInvoice ? encodeBase64(product.product_code) : undefined,
        product_title: isInvoice ? encodeBase64(product.product_title) : undefined,
        product_quantity: isInvoice ? encodeBase64(product.product_quantity || 0) : undefined,
        product_price_without_taxes: isInvoice ? encodeBase64(product.product_price_without_taxes || 0) : undefined,
        product_price_taxes: isInvoice ? encodeBase64(product.product_price_taxes || 0) : undefined,
        product_price: isInvoice ? encodeBase64(product.product_price || 0) : undefined,
        department_code: isInvoice ? encodeBase64(product.department_code || 0) : undefined,
        product_type: isInvoice ? encodeBase64(product.product_type || 0) : undefined,
        warehouse_category_uuid: isInvoice && product.warehouse_category_uuid ? encodeBase64(product.warehouse_category_uuid) : undefined,
      })
    })

    const categories = []
    Object.values(document?.categories || {}).forEach(documentCategories => {
      categories.push(...documentCategories)
    })

    data.documents_data = JSON.stringify({
      is_invoice: encodeBase64(isInvoice),
      document_serial_number: isInvoice ? encodeBase64(document.document_serial_number) : undefined,
      invoice_client: isInvoice ? encodeBase64(document.invoice_client) : undefined,
      invoice_date: isInvoice ? encodeBase64(document.invoice_date) : undefined,
      invoice_payment_date: isInvoice ? encodeBase64(document.invoice_payment_date) : undefined,
      invoice_type: isInvoice ? encodeBase64(document.invoice_type) : undefined,
      categories: categories.map(category => encodeBase64(category)),
      products,
    })

    return data
  },
}
