export default {
  getFormData: state => state.formData,
  getFormModel: state => state.formModel,
  showForm: state => state.showForm,
  getFormAction: state => state.formAction,
  isFormSubmitted: state => state.formSubmitted,
  getModalTitle: state => state.modalTitle,
  getFormFieldsErrors: state => state.formFieldsErrors,
  getFormErrors: state => state.formErrors,
  getFormMetaData: state => state.formMetaData,
  getFormName: state => state.formName,
}