import * as Types from './../../mutationTypes'
import {formatModuleUrl} from "@/helpers/formatters";
import Schema from "@/store/modules/employeeRotations/schema";
import BaseActions from '@/store/base/actions'
import MultipleFormActions from '@/store/base/multipleFormData/actions'
import api from '@/plugins/axios'

const module = 'employeeRotations'

export default BaseActions({module}, {
  async fetchModel({commit, getters, dispatch}, data = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    let url = formatModuleUrl(module)

    if(typeof data.preloadData !== "undefined" && data.preloadData)
      url = `fetch-data/${url}`

    await api.get(`${url}/all/indexed`).then(async ({data}) => {
      let modules = data || {}

      await dispatch('parseModules', modules)
        .then(parsedModules => modules = parsedModules || {})

      if(Array.isArray(modules))
        modules = {}

      commit(Types.SET_MODEL, modules)

      dispatch('fetchedModel')
    })
  },
}, [MultipleFormActions(module), Schema])