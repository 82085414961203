import BaseState from '@/store/base/state'
import DetailState from "@/store/base/detail/state";
import DocumentsState from "@/store/base/documents/state";
import ArchivesState from "@/store/base/archives/state";

export default BaseState({
  moduleTitle: 'Accommodation',

  formData: {
    uuid: '',
    project_uuid: '',
    address: '',
    country_id: '',
    city_id: '',
    total_beds: 0,
    price: 0,
    rental_period_starts_at: '',
    rental_period_ends_at: '',
    contact_details: '',
    lessor: '',
    comment: '',
    documents: []
  },

  dummyData: {
    uuid: '',
    project_uuid: {key: 'uuid', type: 'random_element', getterNamespace: 'projects/getModule'},
    address: 'address1',
    country_id: {key: 'id', type: 'random_element', getterNamespace:'locations/getCountries'},
    city_id: '',
    total_beds: {type: 'integer', from: 1, to: 10},
    price: {type: 'integer', from: 100, to: 999},
    rental_period_starts_at: {key: 'Y-m-d', type: 'date'},
    rental_period_ends_at: {key: 'Y-m-d', type: 'date'},
    contact_details: 'description',
    lessor: 'full_name',
    comment: 'description',
    documents: ''
  },

  notificationTitle: 'address',
  primaryKey: 'uuid',

  currentModule: {},
  currentModuleTabs: {
    information: {title: 'Information'},
    residents: {title: 'Residents'},
  },
  currentModuleTab: 'information',

  searchBy: ['object_no', 'address', 'lessor', 'country'],
}, [ArchivesState, DocumentsState, DetailState])