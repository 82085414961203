import BaseMutations from '@/store/base/mutations'
import * as Types from '@/store/mutationTypes'
import {isEqual, isObject, merge, values} from 'lodash'
import {getValueWithoutReference} from "@/helpers/formatters";
// const module = 'employeeSchedules'

export default BaseMutations({
  [Types.SET_MODEL](state, data) {
    const mappedModules = {}

    data.forEach(model => {
      if(!isObject(mappedModules[model.employee_uuid]))
        mappedModules[model.employee_uuid] = {}

      if(!isObject(mappedModules[model.employee_uuid][model.timekeeping_template_uuid]))
        mappedModules[model.employee_uuid][model.timekeeping_template_uuid] = {}

      mappedModules[model.employee_uuid][model.timekeeping_template_uuid][model.uuid] = model
    })

    state.module = merge(state.module, mappedModules)
    state.isFetched = true
  },

  [Types.ADD_TO_MODEL](state, data) {
    if(!isObject(state.module?.[data.employee_uuid]))
      state.module[data.employee_uuid] = {}

    if(!isObject(state.module[data.employee_uuid]?.[data.timekeeping_template_uuid]))
      state.module[data.employee_uuid][data.timekeeping_template_uuid] = {}

    state.module[data.employee_uuid][data.timekeeping_template_uuid][data.uuid] = data
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    data.forEach(oldModule => {
      if(isObject(state.module?.[oldModule.employee_uuid]?.[oldModule.timekeeping_template_uuid]?.[oldModule.uuid]))
        delete state.module[oldModule.employee_uuid][oldModule.timekeeping_template_uuid][oldModule.uuid]

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `${state.moduleTitle} "${oldModule[state.notificationTitle]}" was deleted.`,
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, {employee_uuid, timekeeping_template_uuid, uuid}) {
    if(isObject(state.module?.[employee_uuid]?.[timekeeping_template_uuid]?.[uuid]))
      delete state.module[employee_uuid][timekeeping_template_uuid][uuid]
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let module = values(state.module).find(employeeIndex => {
      return values(employeeIndex).find(timekeepingTemplateIndex => {
        return values(timekeepingTemplateIndex).find(schedule => schedule.uuid === uuid)
      })
    })

    // Fill form data without object references
    Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    if(state.module?.[data.employee_uuid]?.[data.timekeeping_template_uuid]?.[data.uuid])
      state.module[data.employee_uuid][data.timekeeping_template_uuid][data.uuid] = data
  },

  [Types.ADD_UPDATING_SCHEDULES](state, {schedule_uuid, employee_uuid, year, month}) {
    if(schedule_uuid) {
      state.updatingSchedules[schedule_uuid] = 1
      return
    }

    if(!Array.isArray(state.updatingSchedules?.[0]))
      state.updatingSchedules[0] = []

    state.updatingSchedules[0].push({employee_uuid, year, month})
  },

  [Types.REMOVE_UPDATING_SCHEDULES](state, {schedule_uuid, employee_uuid, year, month}) {
    if(schedule_uuid && state.updatingSchedules[schedule_uuid]) {
      delete state.updatingSchedules[schedule_uuid]
      return
    }

    if(!Array.isArray(state.updatingSchedules[0]))
      return

    const index = state.updatingSchedules[0].findIndex(updatingSchedule => isEqual(updatingSchedule, {employee_uuid, year, month}))

    if(0 <= index)
      delete state.updatingSchedules[0][index]
  },
})
