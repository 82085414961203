export default [
  {
    'title': 'Work at night',
    'name': 'DN',
    'slug': 'dn',
    'personnel_only': false,
  },

  {
    'title': 'Overtime work',
    'name': 'VD',
    'slug': 'vd',
    'personnel_only': false,
  },

  {
    'title': 'Time actually worked',
    'name': 'FD',
    'slug': 'fd',
    'personnel_only': false,
  },

  {
    'title': 'Work in deviations from normal working conditions',
    'name': 'KS',
    'slug': 'ks',
    'personnel_only': false,
  },

  {
    'title': 'Work on rest days and holidays',
    'name': 'DP',
    'slug': 'dp',
    'personnel_only': false,
  },

  {
    'title': 'Staying at home',
    'name': 'BN',
    'slug': 'bn',
    'personnel_only': false,
  },

  {
    'title': 'Being on duty at work',
    'name': 'BĮ',
    'slug': 'bį',
    'personnel_only': false,
  },

  {
    'title': 'Time to look for a new job',
    'name': 'ID',
    'slug': 'id',
    'personnel_only': false,
  },

  {
    'title': 'Time of mandatory medical examinations',
    'name': 'MD',
    'slug': 'md',
    'personnel_only': false,
  },

  {
    'title': 'Additional rest days granted for work over the duration of daily working time, work on rest days and holidays',
    'name': 'V',
    'slug': 'v',
    'personnel_only': false,
  },

  {
    'title': 'Additional rest time for employees raising a disabled child up to 16 years old or two or more children up to 12 years old',
    'name': 'M',
    'slug': 'm',
    'personnel_only': true,
  },

  {
    'title': 'Blood donation days for donors',
    'name': 'D',
    'slug': 'd',
    'personnel_only': false,
  },

  {
    'title': 'Incapacity for work due to illness or injury',
    'name': 'L',
    'slug': 'l',
    'personnel_only': true,
  },

  {
    'title': 'Unpaid unemployment',
    'name': 'N',
    'slug': 'n',
    'personnel_only': true,
  },

  {
    'title': 'Inability to work for nursing patients with certificates',
    'name': 'NS',
    'slug': 'ns',
    'personnel_only': true,
  },

  {
    'title': 'Yearly holidays',
    'name': 'A',
    'slug': 'a',
    'personnel_only': true,
  },

  {
    'title': 'Study vacation',
    'name': 'MA',
    'slug': 'ma',
    'personnel_only': true,
  },

  {
    'title': 'Free vacation',
    'name': 'NA',
    'slug': 'na',
    'personnel_only': true,
  },

  {
    'title': 'Creative vacation',
    'name': 'KA',
    'slug': 'ka',
    'personnel_only': false,
  },

  {
    'title': 'Pregnancy and maternity leave',
    'name': 'G',
    'slug': 'g',
    'personnel_only': true,
  },

  {
    'title': 'Leave to take care of the child until he turns 3 years old',
    'name': 'PV',
    'slug': 'pv',
    'personnel_only': true,
  },

  {
    'title': 'Other types of holidays',
    'name': 'KR',
    'slug': 'kr',
    'personnel_only': false,
  },

  {
    'title': 'Paternity leave',
    'name': 'TA',
    'slug': 'ta',
    'personnel_only': true,
  },

  {
    'title': 'Day of science and knowledge',
    'name': 'MŽ',
    'slug': 'mž',
    'personnel_only': true,
  },

  {
    'title': 'Business trips',
    'name': 'K',
    'slug': 'k',
    'personnel_only': true,
  },

  {
    'title': 'Internships',
    'name': 'SŽ',
    'slug': 'sž',
    'personnel_only': false,
  },

  {
    'title': 'Training',
    'name': 'KV',
    'slug': 'kv',
    'personnel_only': true,
  },

  {
    'title': 'Breaks at work, according to normative legislation, are counted as working time',
    'name': 'PR',
    'slug': 'pr',
    'personnel_only': false,
  },

  {
    'title': 'Performance of state, public or citizen duties',
    'name': 'VV',
    'slug': 'vv',
    'personnel_only': false,
  },

  {
    'title': 'Military service',
    'name': 'KT',
    'slug': 'kt',
    'personnel_only': false,
  },

  {
    'title': 'Educational military exercises',
    'name': 'KM',
    'slug': 'km',
    'personnel_only': true,
  },

  {
    'title': 'Downtime due to the fault of the employee',
    'name': 'PK',
    'slug': 'pk',
    'personnel_only': false,
  },

  {
    'title': 'The downtime is not the fault of the employee',
    'name': 'PN',
    'slug': 'pn',
    'personnel_only': false,
  },

  {
    'title': 'Absenteeism and other non-attendance at work without a valid reason',
    'name': 'PB',
    'slug': 'pb',
    'personnel_only': true,
  },

  {
    'title': 'Absence from work with the permission of the administration',
    'name': 'ND',
    'slug': 'nd',
    'personnel_only': true,
  },

  {
    'title': 'Absence from work during other periods established by normative legal acts',
    'name': 'NP',
    'slug': 'np',
    'personnel_only': false,
  },

  {
    'title': 'Suspension from work',
    'name': 'NN',
    'slug': 'nn',
    'personnel_only': true,
  },

  {
    'title': 'Rest days',
    'name': 'P',
    'slug': 'p',
    'personnel_only': false,
  },

  {
    'title': 'Holidays',
    'name': 'S',
    'slug': 's',
    'personnel_only': false,
  },

  {
    'title': 'Strike',
    'name': 'ST',
    'slug': 'st',
    'personnel_only': false,
  },
]