import BaseActions from "@/store/base/actions";
import api from "@/plugins/axios";
import * as Types from "@/store/mutationTypes";

const module = 'transportDefects'

export default BaseActions({module}, {
  async fetchModel({commit, getters, rootGetters}) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    const currentTransportUuid = rootGetters['transports/getCurrentModuleUuid']

    await api.post(`transport-defects/get-by-key`, {
        key: 'transport_uuid',
        value: currentTransportUuid,
    }, {
      trigger404: false
    }).then(res => {
        commit(Types.SET_MODEL, res.data)}
    )
  },
})