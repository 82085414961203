import * as Types from "@/store/mutationTypes"

export default {
  [Types.SET_STATE_DATA]: (state, { name, data }) => {
    state[name] = name === 'revenue' && typeof data === 'object' && !Array.isArray(data) ? Object.values(data) : data
  },
  [Types.DASHBOARD_INITIATED]: (state, { status }) => state.loaders.init = status,
  [Types.SET_LOADING_STATE]: (state, { name, status }) => state.loaders[name] = status,
  [Types.SEARCH_BY_TEXT]: (state, searchValue) => state.searchValue = searchValue,
  [Types.CLEAR_SEARCH]: (state) => state.searchValue = '',
  [Types.REVENUE_ADD_FILTER]: (state, { name, data }) => state.filters[name] = data,
}