export default {
  serialize({getters, rootGetters}) {
    const {receivers, module} = rootGetters['form/getFormMetaData']
    const {message} = getters['getFormData']

    return  {
      receivers,
      module,
      message
    }
  },
}