import * as Types from '@/store/mutationTypes'
import {getValueWithoutReference} from "@/helpers/formatters";

export default {
  [Types.SET_FORM_INPUT_DATA](state, data) {
    state.formData[data.key] = data.value
  },

  [Types.SET_ARRAY_FORM_INPUT_DATA](state, data) {
    if(!Array.isArray(state.formData[data.key]))
      state.formData[data.key] = []

    state.formData[data.key].push(Object.assign({}, data.value))
  },

  [Types.EDIT_ARRAY_FORM_INPUT_DATA](state, {formDataKey, uuid, arrayKey, arrayKeyValue}) {
    const arrayIndex = state.formData[formDataKey].findIndex(arrayElement => arrayElement.uuid === uuid)

    if(0 <= arrayIndex)
      state.formData[formDataKey][arrayIndex][arrayKey] = arrayKeyValue
  },

  [Types.REMOVE_ARRAY_FORM_INPUT_DATA](state, data) {
    const arrayIndex = state.formData[data.key].findIndex(arrayElement => arrayElement.uuid === data.value)

    if(Object.keys(state.formData[data.key]).includes(arrayIndex + ''))
      state.formData[data.key].splice(arrayIndex, 1)
  },

  [Types.SET_FORM_DATA](state, data) {
    state.formData = data
  },

  [Types.EMPTY_FORM_DATA](state) {
    Object.keys(state.formData).forEach(key => {
      state.formData[key] = ''
    })
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let module

    if(Array.isArray(state.module))
      module = state.module.find(el => el.uuid === uuid)

    if(!module && state?.currentModule?.uuid === uuid)
      module = state.currentModule

    // Fill form data without object references
    Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.SET_ASSIGN_FORM_INPUT_DATA](state, data) {
    state.assignFormData[data.key] = data.value
  },

  [Types.SET_UNASSIGN_FORM_INPUT_DATA](state, data) {
    state.unassignFormData[data.key] = data.value
  },

  [Types.EMPTY_ASSIGN_FORM_DATA](state) {
    Object.keys(state.assignFormData).forEach(key => {
      state.assignFormData[key] = ''
    })
  },

  [Types.EMPTY_UNASSIGN_FORM_DATA](state) {
    Object.keys(state.unassignFormData).forEach(key => {
      state.unassignFormData[key] = ''
    })
  },

  [Types.FILL_ASSIGN_FORM_DATA](state, uuid) {
    let data = typeof state?.currentModule?.uuid !== 'undefined' && state.currentModule.uuid === uuid ? state.currentModule : undefined

    if(!data)
      data = state.module.find(el => el.uuid === uuid)

    if(!data)
      return

    Object.keys(state.assignFormData).forEach(key => state.assignFormData[key] = getValueWithoutReference(data[key]))
  },

  [Types.FILL_UNASSIGN_FORM_DATA](state, uuid) {
    let data = state.module.find(el => el.uuid === uuid)

    if(!data) {
      data = state.currentModule
    }

    Object.keys(state.unassignFormData).forEach(key => {
      state.unassignFormData[key] = data[key]
    })
  },

  [Types.SET_GENERATE_DOCUMENTS_FORM_INPUT_DATA](state, data) {
    state.generateDocumentsFormData[data.key] = data.value
  },

  [Types.FILL_GENERATE_DOCUMENTS_FORM_DATA](state, uuid) {
    let data = undefined

    if(typeof state?.currentModule?.uuid === 'string' && state.currentModule.uuid.length && state.currentModule.uuid === uuid)
      data = state.currentModule

    if(!data)
      data = state.module.find(el => el.uuid === uuid)

    if(data)
      Object.keys(state.generateDocumentsFormData).forEach(key => {
        state.generateDocumentsFormData[key] = data[key]
      })
  },

  [Types.EMPTY_GENERATE_DOCUMENTS_FORM_DATA](state) {
    Object.keys(state.generateDocumentsFormData).forEach(key => {
      state.generateDocumentsFormData[key] = ''
    })
  },
}
