import DocumentSchema from '@/store/base/documents/schema'

export default Object.assign({}, DocumentSchema, {
  serialize({getters}) {
    const inputsData = getters['getFormData']

    return {
      uuid: inputsData?.uuid,
    }
  },
})
