import * as Types from './../../mutationTypes'

export default {
  [Types.SET_FORM_MODAL_DATA](state, data) {
    state.formModel = data.model
    state.formAction = data.action
    state.showForm = data.show
    state.formSubmitted = false
    state.modalTitle = data.title
    state.formMetaData = data.metaData || {}
    state.formName = data?.formName || null
  },

  [Types.SET_FORM_FIELDS_ERRORS](state, data) {
    state.formFieldsErrors = data
    state.formSubmitted = false
  },

  [Types.SET_FORM_ERRORS](state, data) {
    state.formErrors = data
    state.formSubmitted = false
  },

  [Types.SET_FORM_NAME](state, name) {
    state.formName = name
  },

  [Types.FORM_IS_SUBMITTED](state) {
    state.formSubmitted = true
  },

  [Types.FORM_IS_FINISHED](state) {
    state.formSubmitted = false
  },

  [Types.SET_FORM_META_DATA](state, {key, value}) {
    state.formMetaData[key] = value
  },
}