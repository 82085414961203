import BaseMutations from '@/store/base/mutations'
import * as Types from '@/store/mutationTypes'
import {isObject, merge} from "lodash";
import {getValueWithoutReference} from "@/helpers/formatters";
import MultiFormMutations from '@/store/base/multipleFormData/mutations'

// const module = 'plans'

export default BaseMutations({
  [Types.SET_MODEL](state, data) {
    const mappedModules = {}
    data.forEach(model => {
      if(!isObject(mappedModules[model.year]))
        mappedModules[model.year] = {}

      if(!isObject(mappedModules[model.year][model.month]))
        mappedModules[model.year][model.month] = {}

      mappedModules[model.year][model.month][model.project_uuid] = model
    })

    state.module = merge(state.module, mappedModules)
    state.isFetched = true
  },

  [Types.ADD_TO_MODEL](state, data) {
    if(!data?.year || !data?.month)
      return

    if(!isObject(state.module[data.year]))
      state.module[data.year] = {}

    if(!isObject(state.module[data.year][data.month]))
      state.module[data.year][data.month] = {}

    state.module[data.year][data.month][data.project_uuid] = data
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    data.forEach(oldModule => {
      state.module = state.module.filter(mod => mod[state.primaryKey] !== oldModule[state.primaryKey])

      if(isObject(state.module[oldModule?.year]) && isObject(state.module[oldModule.year][oldModule.month]))
        delete state.module[oldModule.year][oldModule.month][oldModule.project_uuid]

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `${state.moduleTitle} "${oldModule[state.notificationTitle]}" was deleted.`,
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, {year, month, project_uuid}) {
    if(isObject(state.module[year]) && isObject(state.module[year][month]))
      delete state.module[year][month][project_uuid]
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let module

    if(Array.isArray(state.module)) {
      let modules = []

      Object.keys(state.module).forEach(year => {
        Object.keys(state.module[year]).forEach(month => modules = modules.concat(Object.values(state.module[year][month])))
      })

      module = state.module.find(el => el.uuid === uuid)
    }

    if(!module && state?.currentModule?.uuid === uuid)
      module = state.currentModule

    // Fill form data without object references
    Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    if(state.module[data?.year] && state.module[data.year][data?.month] && state.module[data.year][data.month][data?.project_uuid])
      state.module[data.year][data.month][data.project_uuid] = data
  },
}, [MultiFormMutations()])