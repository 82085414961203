export default {
  serialize({getters}) {
    const formData = getters['getFormData']

    return {
      uuid: formData.uuid,
      title_en: formData.title_en,
      title_lt: formData.title_lt,
      administrative_employee: !!formData.administrative_employee,
    }
  }
}