import DocumentSchema from "@/store/base/documents/schema";

export default Object.assign({}, DocumentSchema, {
  serialize({getters, rootGetters}, payload) {
    const formMetaData = rootGetters['form/getFormMetaData']
    let formData = getters['getFormData']

    switch(rootGetters['form/getFormName']) {
      case 'warehouseAssign':
        return {
          module_uuid: payload.module_uuid,
          assign_uuid: payload.assign_uuid,
        }

      case 'warehouseUnassign':
        formData = getters['getUnassignFormData']

        return {
          module_uuid: formMetaData.module_uuid,
          unassign_uuid: formMetaData.unassign_uuid,
          return_reason: formData.return_reason,
        }
    }

    return {
      uuid: formData.uuid,
      title: formData.title,
      tool_no: formData.tool_no,
      project_uuid: formData.project_uuid,
      price: formData.price,
      category_uuid: formData.category_uuid,
      category_2_uuid: formData.category_2_uuid,
      category_3_uuid: formData.category_3_uuid,
      category_4_uuid: formData.category_4_uuid,
      category_5_uuid: formData.category_5_uuid,

      responsible: formData.responsible,
      is_assigned_responsible: formData.is_assigned_responsible,

      qty: formData.qty,
      qty_type: formData.qty_type,
    }
  }
})
