import BaseActions from './../../base/actions'
import * as Types from '@/store/mutationTypes'
import Schema from './schema'
import {formatModuleUrl} from "@/helpers/formatters";
import api from '@/plugins/axios'

const module = 'comments'

export default BaseActions({module}, {
  async fetchModel({commit, getters}, data = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post(`${formatModuleUrl(module)}/get-by-key`, data, {trigger404: false})
      .then(res => {
        commit(Types.SET_MODEL, res.data)
      })
  },
}, [Schema])