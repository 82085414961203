<template lang="pug">
.auth-page-wrapper.pt-5

  // auth page bg
  #auth-particles.auth-one-bg-position.auth-one-bg
    .bg-overlay

  // auth page content
  .auth-page-content
    .container
      .row
        .col-lg-12
          .text-center.mt-sm-5.mb-4.text-white-50
            div
              router-link.d-inline-block.auth-logo(to='/')
                img(src='@/assets/images/logo-light.png' alt='' height='60')

      // Auth card by route
      router-view
</template>

<script>
export default {
  name: "AuthLayout",
}
</script>
