import BaseMutations from '@/store/base/mutations'
import * as Types from './../../mutationTypes'

export default BaseMutations({
  [Types.FILL_FORM_DATA](state, uuid) {
    let module = state.module.find(el => el.uuid === uuid)

    state.formData.uuid = module.uuid
    state.formData.first_name = module.first_name
    state.formData.last_name = module.last_name
    state.formData.email = module.email
    state.formData.password = module.password
    state.formData.password_confirm = module.password_confirm
    state.formData.type = module.type
    state.formData.client = module.client
    state.formData.phone_number = module.phone_number
    state.formData.project_uuid = module.project_uuid || null
    state.formData.employee_uuid = module.employee_uuid || null
    state.formData.role = module.role ? module.role.slug : ''
  }
})