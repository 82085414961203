import BaseGetters from '@/store/base/getters'
import MultiFormGetters from '@/store/base/multipleFormData/getters'
import {isObject} from "lodash";

export default BaseGetters({
  getPlanByKeys: (state) => (year, month, projectUuid) => {
    if(isObject(state.module[year]) && isObject(state.module[year][month]) && isObject(state.module[year][month][projectUuid]))
      return state.module[year][month][projectUuid]

    return undefined
  },

  getProjectPlan: ({module}, getters) => (year, month, project) => {
    // TODO: get single form
    const singleFormData = getters['getSingleFormData']({
      project_uuid: project.uuid,
      year: year,
      month: month * 1
    })

    if(singleFormData)
      return singleFormData

    if(module && isObject(module[year]) && isObject(module[year][month]) && module[year][month][project.uuid])
      return module[year][month][project.uuid]

    return undefined
  },

  getModuleByKeysValues: state => keysValues => {
    let modules = []

    Object.keys(state.module).forEach(year => {
      Object.keys(state.module[year]).forEach(month => modules = modules.concat(Object.values(state.module[year][month])))
    })

    return modules.filter(module => Object.keys(keysValues).every(key => module[key] === keysValues[key]))
  },
}, [MultiFormGetters()], {multipleFormSearchBy: ['project_uuid', 'year', 'month']})