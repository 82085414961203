import BaseMutations from "@/store/base/mutations";
import DetailMutations from "@/store/base/detail/mutations";
import DocumentsMutations from "@/store/base/documents/mutations";
import ArchivesMutations from "@/store/base/archives/mutations";
import * as Types from "@/store/mutationTypes";
import HistoriesMutations from "@/store/base/histories/mutations";

const module = 'transports'

export default BaseMutations({
  [Types.FILL_FORM_DATA](state, uuid) {
    let module
    if(
      Array.isArray(state.module['archived']) &&
      Array.isArray(state.module['active'])
    )
      module = state.module['archived'].concat(state.module['active']).find(el => el.uuid === uuid)

    if(!module && state.currentModule?.uuid === uuid)
      module = state.currentModule

    state.formData.uuid = module.uuid
    state.formData.body_type = module.body_type
    state.formData.registration_number = module.registration_number
    state.formData.car_brand_uuid = module.car_brand_uuid
    state.formData.car_model_uuid = module.car_model_uuid
    state.formData.seats = module.seats
    state.formData.insurance_starts_at = module.insurance_starts_at
    state.formData.insurance_ends_at = module.insurance_ends_at
    state.formData.inspection_starts_at = module.inspection_starts_at
    state.formData.inspection_ends_at = module.inspection_ends_at
    state.formData.fuel_cards = module.fuel_cards
    state.formData.ownership_type = module.ownership_type
    state.formData.information = module.information
    state.formData.price = module.price
    state.formData.contract_ends_at = module.contract_ends_at
    state.formData.project_uuid = module.project_uuid
  },

  [Types.SET_CURRENT_MODULE_EMPLOYEES](state, employees) {
    state.currentModule.employees = employees
    state.currentModule.employeesFetched = true
  },

  [Types.REMOVE_EMPLOYEE_FROM_CURRENT_TRANSPORT](state, unassigned) {
    state.currentModule.employees = state.currentModule.employees.filter(employee => employee.uuid !== unassigned.uuid)
  },

  [Types.UPDATE_EMPLOYEE_FROM_CURRENT_TRANSPORT](state, assigned) {
    if(assigned.transport_uuid === null) {
      state.currentModule.employees = state.currentModule.employees.filter(employee => employee.uuid !== assigned.uuid)
      return
    }

    let index = state.currentModule.employees.findIndex(employee => employee.uuid === assigned.uuid)

    if(0 <= index) {
      state.currentModule.employees[index] = assigned
      return
    }

    state.currentModule.employees.push(assigned)
  },
}, [ArchivesMutations({module}), DocumentsMutations(module), DetailMutations({module}), HistoriesMutations({module})])
