import { createStore } from 'vuex'
import modules from './modules'
import Websocket from '@/plugins/websocket'
import {isClient, userCan} from "@/helpers/formatters";

export default createStore({
  state: {
    dataPacketsLoaded: 0,
    maxDataPackets: 0,
    dataIsFetching: false,
    dataIsFetched: false,
    registeredPrefetches:
      [
        {
          action: 'roles/fetchDefaultRoleList',
          actionPayload: {preloadData: true},
          permission: 'view-roles',
        },

        {
          action: 'roles/fetchModel',
          permission: 'view-roles',
        },

        {
          action: 'auth/fetchAlerts',
          actionPayload: {preloadData: true},
          permission: 'view-alerts',
        },

        {
          action: 'projectCategories/fetchAllCategories',
          actionPayload: {preloadData: true},
          permission: 'view-project-categories',
        },

        {
          action: 'employeeResponsibilities/fetchModel',
          actionPayload: {preloadData: true},
          permission: 'view-employee-responsibilities',
        },

        {
          action: 'locations/fetchAllLocations',
          actionPayload: {preloadData: true},
        },

        {
          action: 'citizenships/fetchModel',
          actionPayload: {preloadData: true},
        },

        {
          action: 'certificates/fetchModel',
          permission: 'view-certificates',
        },

        {
          action: 'carCategories/fetchAllCategories',
          actionPayload: {preloadData: true},
          permission: 'view-car-models-brands',
        },

        {
          action: 'warehouseCategories/getCategoryList',
          permission: 'view-warehouse-categories',
        },

        {
          action: 'companies/fetchModel',
        },

        {
          action: 'clients/fetchModel',
        },

        // Only for clients
        {
          action: 'projects/fetchModel',
          callback: () => isClient()
        },

        {
          action: 'projects/fetchClientProjectEmployees',
          callback: () => isClient(),
        },

        {
          action: 'transports/fetchModel',
          callback: () => isClient()
        },

        {
          action: 'employees/fetchModel',
        },

        {
          action: 'documentCategories/fetchAllCategories',
          actionPayload: {preloadData: true},
          permission: 'view-document-categories',
        }
      ],
  },
  getters: {
    dataIsFetching: state => state.dataIsFetching,
    dataPacketsLoaded: state => state.dataPacketsLoaded,
    maxDataPackets: state => state.maxDataPackets,
    dataIsFetched: state => state.dataIsFetched,
  },
  mutations: {
    INCREASE_DATA_PACKET_LOADED(state) {
      state.dataPacketsLoaded++
    },

    START_DATA_FETCH(state) {
      state.dataIsFetching = true
      state.maxDataPackets = Object.keys(state.registeredPrefetches).length + 1
    },

    FINISH_DATA_FETCH(state) {
      state.dataIsFetching = false
      state.dataIsFetched = true
    },

    FILTER_REGISTERED_PREFETCHES(state) {
      state.registeredPrefetches = state.registeredPrefetches.filter(prefetch => (!prefetch?.permission || userCan(prefetch.permission)) && (!prefetch?.callback || prefetch.callback()))
    }
  },

  actions: {
    async initData({dispatch}) {
      await dispatch('auth/setToken')
      await dispatch('auth/getAuthenticatedUser')
      return true
    },

    async fetchImportantData({commit, getters, dispatch}) {
      commit('FILTER_REGISTERED_PREFETCHES')

      commit('START_DATA_FETCH')

      // await dispatch('auth/getAuthenticatedUser')
      // commit('INCREASE_DATA_PACKET_LOADED')

      await Websocket.init(getters['auth/authUser'].uuid)
      commit('INCREASE_DATA_PACKET_LOADED')

      // Prefetch registered and filtered data
      await dispatch('prefetchFiltered')

      setTimeout(() => {
        commit('FINISH_DATA_FETCH')
      }, 500)

      return 'Data Fetch finished'
    },

    async prefetchFiltered({dispatch, commit, state}) {
      for(const prefetch of state.registeredPrefetches) {
        await dispatch(prefetch.action, prefetch?.actionPayload || undefined)
        commit('INCREASE_DATA_PACKET_LOADED')
      }
    },
  },
  modules
})
