import BaseState from '@/store/base/state'
import DocumentsState from '@/store/base/documents/state'

export default BaseState({
  moduleTitle: 'Document',

  isFetching: false,

  formData: {
    uuid: '',
  },

  dummyData: {
    uuid: '',
  },

  notificationTitle: 'uuid',
  primaryKey: 'uuid',

}, [DocumentsState])
