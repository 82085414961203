import { mapValues } from "lodash";

export default {
  async serialize({dispatch}, module) {
    let days

    await dispatch('serializeDays')
      .then(serializedDays => days = serializedDays)

    return Object.assign(module, { days })
  },

  async parse({dispatch}, module) {
    let days

    await dispatch('parseDays', module.days)
      .then(parsedDays => days = parsedDays)

    return Object.assign(module, { days })
  },

  serializeDays({getters}) {
    const formData = getters['getFormData']

    const serializedDays = {}
    Object.keys(formData?.days || {}).forEach(weekday  => {
      const {work_starts_at, work_ends_at, lunch_starts_at, lunch_ends_at} = formData.days[weekday] || {}

      if(
        work_starts_at &&
        work_ends_at &&
        lunch_starts_at &&
        lunch_ends_at
      )
       serializedDays[weekday] = {
          work_starts_at,
          work_ends_at,
          lunch_starts_at,
          lunch_ends_at,
          work_total: getters['getDayWorkHours'](weekday),
          night_work_total: getters['getNightWorkHours'](weekday),
      }
    })

    return serializedDays
  },

  parseDays(context, days) {
    return mapValues(days || {}, day => ({
      work_starts_at: day?.work_starts_at || null,
      work_ends_at: day?.work_ends_at || null,
      lunch_starts_at: day?.lunch_starts_at || null,
      lunch_ends_at: day?.lunch_ends_at || null,
    }))
  },
}