<template lang="pug">
ul#navbar-nav.navbar-nav.h-100
  li.menu-title
    span Menu

  transition(name="slide-fade")
    li.nav-item.back-button(
      v-show="history.length"
    )
      a.nav-link.menu-link(
        role="button"
        :aria-expanded="false"
        @click.prevent="goBack"
      )
        i.bx.bx-arrow-back
        span Back

  menu-nav-bar-item(
    v-for="menu in menus"
    :item="menu"
  )
</template>

<script>
import MenuNavBarItem from "@/components/menu/MenuNavBarItem";
import PageMenuMixin from "@/mixins/pageMenuMixin";
import {isClient, userCan} from "@/helpers/formatters";
import {mapGetters} from "vuex";

export default {
  name: 'MenuNavBar',

  components: {
    MenuNavBarItem
  },

  mixins: [PageMenuMixin],

  computed: {
    ...mapGetters({
      history: 'history/getHistory',
    })
  },

  beforeMount() {
    if(isClient()) {
      this.menus = this.menus.map(menu => {
        if(!menu?.subMenu)
          return menu

        const accessibleSubMenus = menu.subMenu.filter(subMenu => userCan(subMenu.role))

        if(menu.routeName === 'dashboard')
          menu.title = 'General information'

        return accessibleSubMenus.length === 1 ? accessibleSubMenus[0] : menu
      })
    }
  },

  methods: {
    goBack() {
      let previousPage = this.history[this.history.length - 1] || undefined

      if(!previousPage)
        return

      previousPage = Object.assign({}, previousPage)
      previousPage.query = {wentBack: 'true'}

      this.$store.dispatch('history/popPage')

      if(previousPage)
        this.$router.push(previousPage)
    },
  },
}
</script>
