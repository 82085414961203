import { emptyValues, getValueWithoutReference } from '@/helpers/formatters'
import { has, isObject, merge } from 'lodash'
import BaseMutations from '@/store/base/mutations'
import TimekeepingMutations from '@/store/base/timekeeping/mutations'
import * as Types from '@/store/mutationTypes'

// const module = 'employeeTimekeepingTemplates'

export default BaseMutations({
  [Types.FILL_FORM_DATA](state, uuid) {
    let module

    Object.values(state.module).forEach((employeeTemplates) => {
      if(employeeTemplates?.[uuid])
        module = employeeTemplates[uuid]
    })

    if(!module)
      return

    // Fill form data without object references
    Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.SET_MODEL](state, data) {
    const mappedModules = {}

    data.forEach(model => {
      if(!isObject(mappedModules[model.employee_uuid]))
        mappedModules[model.employee_uuid] = {}

      mappedModules[model.employee_uuid][model.uuid] = model
    })

    state.module = merge(state.module, mappedModules)
    state.isFetched = true
  },

  [Types.ADD_TO_MODEL](state, data) {
    if(!isObject(state.module[data.employee_uuid]))
      state.module[data.employee_uuid] = {}

    state.module[data.employee_uuid][data.uuid] = data
  },

  // TODO: implement
  [Types.DELETE_FROM_MODULE](/*state, data*/) {
    // data.forEach(oldModule => {
    //   state.module = state.module.filter(mod => mod[state.primaryKey] !== oldModule[state.primaryKey])
    //
    //   if(isObject(state.module?.[oldModule?.year]?.[oldModule.month]))
    //     delete state.module[oldModule.year][oldModule.month][oldModule.project_uuid]
    //
    //   this.dispatch('notification/addNotificationToList', {
    //       type: 'success',
    //       message: `${state.moduleTitle} "${oldModule[state.notificationTitle]}" was deleted.`,
    //       actions: {
    //         close: true,
    //       }
    //     }, { root: true })
    // })
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, {employee_uuid, uuid}) {
    if(isObject(state.module?.[employee_uuid]?.[uuid]))
      delete state.module[employee_uuid][uuid]
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    if(state.module?.[data.employee_uuid]?.[data.uuid])
      state.module[data.employee_uuid][data.uuid] = data
  },

  [Types.SET_FORM_OBJECT_DATA](state, {weekday, property, value, formAction}) {
    const formData = formAction === 'assignModel' ? 'assignFormData': 'formData'

    if(!has(state[formData].days, weekday))
      return

    state[formData].days[weekday][property] = value
  },

  [Types.EMPTY_FORM_DATA](state) {
    Object.keys(state.formData).forEach(inputName => {
      if(inputName !== 'days')
        state.formData[inputName] = ''
    })

    state.formData.days = emptyValues(state.formData.days, null)
  },

  [Types.EMPTY_FORM_DAYS_DATA](state) {
    state.formData.days = emptyValues(state.formData.days, null)
  },

  [Types.EMPTY_ASSIGN_FORM_DATA](state) {
    Object.keys(state.assignFormData).forEach(inputName => {
      if(inputName !== 'days')
        state.assignFormData[inputName] = ''
    })

    state.assignFormData.days = emptyValues(state.assignFormData.days, null)
  },
  [Types.FILL_TEMPLATE](state, {templates, templateUuid}) {
    const template = templates.find(template => template.uuid === templateUuid)

    if(!template)
      return

    state.formData.days = getValueWithoutReference(template.days)
  },
}, [TimekeepingMutations({module})])
