export default {
  fireReasonCategories: [
    {
      title_en: "Termination of the employment contract by agreement of the parties",
      title_lt: "Nutraukus darbo sutartį šalių susitarimu",
      value: 'decision-by-both-parties',
    },
    {
      title_en: "Termination of the employment contract at the employee's request",
      title_lt: "Nutraukus darbo sutartį darbuotojo prašymu",
      value: 'decision-by-employee',
    },
    {
      title_en: "Termination of the employment contract by the will of the employer",
      title_lt: "Nutraukus darbo sutartį darbdavio valia",
      value: 'decision-by-employer',
    },
    {
      title_en: "Termination of the employment contract without the will of the parties",
      title_lt: "Nutraukus darbo sutartį nesant šalių valios",
      value: 'decision-by-no-parties',
    },
    {
      title_en: "The death of the party to the employment contract - a natural person",
      title_lt: "Mirus darbo sutarties šaliai – fiziniam asmeniui",
      value: 'death-of-party',
    },
  ],

  residenceTypes: [
    {
      name: 'Temporary Residence',
      slug: 'temporary-residence'
    },

    {
      name: 'Lifelong residence',
      slug: 'lifelong-residence'
    },
  ],
}





