import BaseState from '@/store/base/state'
import MultipleFormState from '@/store/base/multipleFormData/state'
import markings from "@/mixins/markings";

export default BaseState({
  moduleTitle: 'Timekeeping day',

  module: {},

  formData: {
    uuid: '',
    work_starts_at: '',
    work_ends_at: '',
    lunch_starts_at: '',
    lunch_ends_at: '',
    work_total: '',
    night_work_total: '',
    absence: '',
    year: null,
    month: null,
    day: null,
  },

  requiredFields: [
    'work_starts_at',
    'work_ends_at',
    'lunch_starts_at',
    'lunch_ends_at',
  ],

  optionalFields: ['absence'],

  dummyData: {
    uuid: '',
    work_starts_at: '',
    work_ends_at: '',
    lunch_starts_at: '',
    lunch_ends_at: '',
    work_total: '',
    night_work_total: '',
    absence: '',
    year: null,
    month: null,
    day: null,
  },

  notificationTitle: 'date',
  moduleKey: 'date',
  primaryKey: 'uuid',

  markings: markings,

  staticCrudActionTitle: 'updated',
}, [MultipleFormState])