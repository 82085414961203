import BaseMutations from '@/store/base/mutations'
import * as Types from '@/store/mutationTypes'

// const module = 'employeeScheduleExports'

export default BaseMutations({
  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    let index = state.module.findIndex(el => el[state.primaryKey] === data[state.primaryKey])

    if(index < 0 || !state.module?.[index])
      return

    if(!state.module[index].is_generated && data.is_generated)
      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: state.moduleTitle + ' "' + state.module[index][state.notificationTitle] + '" has been generated. You can download it now.',
          actions: {
            close: true,
          }
        }, { root: true })

    state.module[index] = data
  },
})
