import HasFormState from "@/store/base/hasForm/state";

export default Object.assign({}, HasFormState, {
  countries: [],
  cities: [],

  formData: {
    id: '',
    title: '',
    title_lt: '',
    country_id: null,
  },

  moduleTitle: 'Location',
  notificationTitle: 'title',
  staticCrudActionTitle: undefined,
})