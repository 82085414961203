import markings from "@/mixins/markings";
import BaseState from '@/store/base/state'
import MultipleFormState from '@/store/base/multipleFormData/state'

export default BaseState({
  moduleTitle: 'Rotation day',

  module: {},

  formData: {
    uuid: '',
    employee_uuid: '',
    year: '',
    month: '',
    day: '',
    marking: '',
  },

  dummyData: {
    uuid: '',
    employee_uuid: '',
    year: '',
    month: '',
    day: '',
    marking: '',
  },

  markings: markings,

  notificationTitle: '{employee} {date}',
  primaryKey: 'uuid',

  staticCrudActionTitle: 'updated',

  searchBy: [],

  formIdFields: ['employee_uuid', 'year', 'month', 'day'],
}, [MultipleFormState])