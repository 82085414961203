export default {
  getCurrentModuleTabs: state => state.currentModuleTabs,
  getCurrentModuleTab: state => state.currentModuleTab,
  getCurrentModule: state => state.currentModule,
  getCurrentModuleUuid: state => state.currentModule?.uuid,
  getModuleWithCurrent: (state, getters) => uuid => {
    let module = getters['getModule'].find(module => module.uuid === uuid)

    if(module)
      return module

    if(
      uuid &&
      getters['getCurrentModule']?.uuid === uuid
    )
      return getters['getCurrentModule']

    return undefined
  },

  getModelData: (state, getters) => uuid => {
    if(uuid && state?.currentModule?.uuid === uuid)
      return state.currentModule

    return getters['getModule']?.find(module => module.uuid === uuid) || undefined
  },
}