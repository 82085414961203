import { normalizeDate } from "@/helpers/formatters";
import BaseGetters from '@/store/base/getters'
import MultiFormGetters from '@/store/base/multipleFormData/getters'

export default BaseGetters({
  getMarkings: ({markings}) => markings,
  getDay: (state, getters) => (employeeUuid, year, month, day) => {
    if(Object.values(state.multipleFormData).length) {
      const dayInForm = getters['getMultipleFormDataDay'](employeeUuid, {year, month, day})

      if(dayInForm)
        return dayInForm
    }

    return state.module?.[employeeUuid]?.[normalizeDate(year, month, day)] || undefined
  },
  getMultipleFormData: state => state.multipleFormData,
  getMultipleFormDataDay: state => (employeeUuid, {year, month, day}) => {
    return state.multipleFormData?.[employeeUuid]?.[normalizeDate(year, month, day)] || undefined
  },
  getRequiredFields: state => state.requiredFields,
}, [MultiFormGetters()])
