import BaseActions from './../../base/actions'
import api from '@/plugins/axios'
import Schema from './schema'
import * as Types from '@/store/mutationTypes'

const module = 'employeeWorkTripMails'

export default BaseActions({module}, {
  async fetchModule({commit, getters, dispatch}, employeeUuid) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post('employee-work-trip-mails/get-by-key', {key: 'employee_uuid', value: employeeUuid}).then(async ({data}) => {
      let modules = data || []

      await dispatch('parseModules', modules)
        .then(parsedModules => modules = parsedModules || [])

      commit(Types.SET_MODEL, modules)

      dispatch('fetchedModel')
    })
  },

  send({dispatch}, {data}) {
    dispatch('addToModel', {data})
  },
}, [Schema])
