import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'Company',

  formData: {
    uuid: '',
    title: '',
    company_code: '',
    vat_code: '',
    manager: '',
    manager_position: '',
    address: '',
  },

  dummyData: {
    uuid: '',
    title: '',
    company_code: '',
    vat_code: '',
    manager: '',
    manager_position: '',
    address: '',
  },

  notificationTitle: 'title',
  primaryKey: 'uuid',
})