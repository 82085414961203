import BaseActions from '@/store/base/actions'
import Schema from '@/store/modules/companies/schema'
import {formatModuleUrl} from "@/helpers/formatters";
import api from '@/plugins/axios'
import * as Types from '@/store/mutationTypes'
import {downloadFile} from "@/helpers/files";

const module = 'employeeScheduleExports'

export default BaseActions({module}, {
  async fetchModel({commit, getters, dispatch}, data = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    let url = formatModuleUrl(module)

    if(typeof data.preloadData !== "undefined" && data.preloadData)
      url = `fetch-data/${url}`

    await api.post(`${url}/get-by-key`, data, { trigger404: false }).then(async ({data}) => {
      let modules = data || []

      await dispatch('parseModules', modules)
        .then(parsedModules => modules = parsedModules || [])

      commit(Types.SET_MODEL, modules)

      dispatch('fetchedModel')
    })
  },

  download({getters}, {uuid}) {
    api.post(`${formatModuleUrl(module)}/download`, {uuid}, {responseType: 'blob'})
      .then(response => {
        let fileName = 'Schedule'

        const employeeScheduleExport = getters['getModuleByKey']()

        if(employeeScheduleExport)
          fileName = employeeScheduleExport.title

        downloadFile(response, `${fileName}.xlsx`)
      })
  },
}, [Schema])
