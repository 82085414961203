export default {
  loaders: (state) => state.loaders,
  getDashboard: (state) => state.stats,
  getEmployees (state) {
    if (state.searchValue.length >= 2) {
      return state.employees.filter(employee => {
        let search = false
        state.searchBy.forEach(byKey => {
          if (employee[byKey].toLowerCase().includes(state.searchValue.toLowerCase())) search = true
        })
        return search
      })
    }
    return state.employees
  },
  getSelectedFiltersCount: () => 0,
  isSearching: (state) => state.searchValue.length >= 2,
  getRevenue: (state) => state.revenue,
  getRevenueFilter: (state) => (name) => state.filters[name],
  getRevenueOptions: (state, getters, rootState, rootGetters) => (name) => {
    switch(name) {
      case 'countries':
        return rootGetters['locations/getCountries'].map(({title, slug}) => ({
          label: title,
          value: slug,
        }))

      case 'categories':
        return (rootGetters['projectCategories/getPrefetchedCategories'] || []).map(({title, uuid}) => ({
          label: title,
          value: uuid,
        }))
    }

    return []
  }
}