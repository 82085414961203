import {getValueWithoutReference, normalizeDate} from "@/helpers/formatters";
import BaseMutations from '@/store/base/mutations'
import MultiFormMutations from '@/store/base/multipleFormData/mutations'
import * as Types from '@/store/mutationTypes'
import {isObject} from "lodash";

// const module = 'timekeepingDays'

export default BaseMutations({
  [Types.SET_MULTIPLE_FORM_DATA](state, {employee_uuid, dayTemplate, year, month, day, property, value}) {
    let formDataIndex = normalizeDate(year, month, day)

    if(!state.multipleFormData?.[employee_uuid]?.[formDataIndex]) {
      const data = Object.assign({}, dayTemplate || state.formData, {year, month, day})

      const singleFormData = {is_updating: false}
      Object.keys(state.formData).forEach(inputKey => singleFormData[inputKey] = data[inputKey])

      if(!isObject(state.multipleFormData[employee_uuid]))
        state.multipleFormData[employee_uuid] = {}

      state.multipleFormData[employee_uuid][formDataIndex] = singleFormData
    }

    if(!state.multipleFormData?.[employee_uuid]?.[formDataIndex])
      return

    state.multipleFormData[employee_uuid][formDataIndex][property] = value || null
  },

  [Types.EMPTY_MULTIPLE_FORM_DATA](state) {
    state.multipleFormData = {}
  },

  [Types.REMOVE_SINGLE_MULTIPLE_FORM_DATA](state, data) {
    const formDataIndex = data[state.moduleKey]

    if(state.multipleFormData?.[data.model_uuid]?.[formDataIndex])
      delete state.multipleFormData[data.model_uuid][formDataIndex]
  },

  [Types.SET_MULTIPLE_FORM_DATA_UPDATING_STATUS](state, {employee_uuid, year, month, day, updating}) {
    const formDataIndex = normalizeDate(year, month, day)

    if(state.multipleFormData?.[employee_uuid]?.[formDataIndex])
      state.multipleFormData[employee_uuid][formDataIndex].is_updating = updating
  },

  [Types.SET_MODEL](state, models) {
    let mappedModules = {}

    if(Array.isArray(models)) {
      models.forEach(model => {
        if(!isObject(mappedModules[model.model_uuid]))
          mappedModules[model.model_uuid] = {}

        mappedModules[model.model_uuid][model.date] = model
      })
    }

    state.module = mappedModules
    state.isFetched = true
  },

  [Types.ADD_TO_MODEL](state, model) {
    if(!isObject(state.module[model.model_uuid]))
      state.module[model.model_uuid] = {}

    state.module[model.model_uuid][model[state.moduleKey]] = model
  },

  [Types.DELETE_FROM_MODULE](state, models) {
    models.forEach(oldModule => {
      delete state.module[oldModule[state.moduleKey]]

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: state.moduleTitle + ' "' + oldModule[state.notificationTitle] + '" was deleted.',
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable][state.moduleKey]
      state[data.stateVariable][index] = data
      return
    }

    state.module[data.model_uuid][data[state.moduleKey]] = data
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, data) {
    if(state.module[data[state.moduleKey]])
      delete state.module[data[state.moduleKey]]
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let module = state.module.find(el => el.uuid === uuid)

    if(!module && state?.currentModule?.uuid === uuid)
      module = state.currentModule

    // Fill form data without object references
    Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.UNSET_MODULES](state) {
    state.module = {}
    state.isFetched = false
  },

  [Types.FILL_ASSIGN_FORM_DATA](state, uuid) {
    let data = typeof state?.currentModule?.uuid !== 'undefined' && state.currentModule.uuid === uuid ? state.currentModule : undefined

    if(!data)
      data = Object.keys(state.module).find(moduleKey => state.module[moduleKey].uuid === uuid)

    if(!data)
      return

    Object.keys(state.assignFormData).forEach(key => state.assignFormData[key] = getValueWithoutReference(data[key]))
  },

  [Types.FILL_UNASSIGN_FORM_DATA](state, uuid) {
    let data = Object.keys(state.module).find(moduleKey => state.module[moduleKey].uuid === uuid)

    Object.keys(state.unassignFormData).forEach(key => {
      state.unassignFormData[key] = data[key]
    })
  },
}, [MultiFormMutations()])
