import {startOfDay} from "@/helpers/formatters";
import moment from "moment";

export default {
  getToken: state => state.token,
  loggedIn: state => !!state.user,
  authUser: state => state.user,
  isCurrentUserClient: (state, getters) => getters['authUser']?.type === 'client',
  getCurrentUserProjects: (state, getters) => getters['authUser']?.projects,
  getCurrentClientProjectSlug: (state, getters) => {
    if(getters['isCurrentUserClient'])
      return getters['getCurrentUserProjects']?.slug || undefined

    return undefined
  },
  authUserHasPermission: state => permission => {
    if(typeof permission === 'undefined' || permission.length <= 0)
      return true

    if(!state.user)
      return false

    if(!state.user.permissions)
      return false

    return state.user.permissions.find(el => el.slug === permission)
  },
  getFullName: state => {
    if(!state.user)
      return 'None'

    let firstName = state.user.first_name || 'None'
    let lastName = state.user.last_name || ''

    return (firstName + ' ' + lastName).trim()
  },
  getFirstName: state => state?.user?.first_name || 'None',
  getLastName: state => state?.user?.last_name || '',
  getRoleName: state => {
    if(!state.user)
      return 'None'

    if(!state.user.role)
      return 'None'

    return state.user.role.name
  },
  getUuid: state => state?.user?.uuid ?? '',
  getAlerts: state => state.alerts ?? [],
  getAlertTitle: () => ({type, alertable_title, alertable_expire_date, pivot_title}) => {
    let expiresIn = null
    if(alertable_expire_date) {
      moment.locale('en')

      const expireDate = moment(startOfDay(alertable_expire_date))

      expiresIn = moment.duration(expireDate.diff(moment())).humanize(true)
    }

    /// All the cases listed in backend AlertSettingSeeder
    switch (type) {
      case 'accommodations_rental_period_ends_at':
        return `Accommodation '${alertable_title}' rental period will end ${expiresIn}!`

      case 'transports_insurance_ends_at':
        return `Insurance for transport '${alertable_title}' will expire ${expiresIn}!`

      case 'transports_inspection_ends_at':
        return `Inspection for transport '${alertable_title}' will expire ${expiresIn}!`

      case 'transports_contract_ends_at':
        return `Rent contract for transport '${alertable_title}' will end ${expiresIn}!`

      case 'projects_warranty_period_ends_at':
        return`Project '${alertable_title}' warranty period will expire ${expiresIn}!`

      case 'employees_driving_license_duration_date':
        return`Employee '${alertable_title}' driver license will expire ${expiresIn}!`

      case 'employees_document_duration':
        return`Employee '${alertable_title}' identity card will expire ${expiresIn}!`

      case 'employees_health_certificate_duration_date':
        return`Employee '${alertable_title}' health certificate will expire ${expiresIn}!`

      case 'person_certificates_expire_date':
        return `Employee '${pivot_title}' certificate '${alertable_title}' will expire ${expiresIn}!`

      case 'employees_europe_health_insurance_card_duration_date':
        return `Employee '${alertable_title}' Europe health insurance card will expire ${expiresIn}!`

      case 'task_manager_tasks_name':
        return `New task '${alertable_title}' has been created!`
    }

    return ''
  },
}
