<template lang="pug">
.toast.fade.show(
  role='alert'
  aria-live='assertive'
  data-bs-autohide='false'
  aria-atomic='true'
)
  .toast-header
    span.fw-semibold.me-auto Notification
    button.btn-close(
      type='button'
      data-bs-dismiss='toast'
      aria-label='Close'
      v-if="notification.actions.close"
      @click="hideNotification"
    )
  .toast-body {{ notification.message }}

</template>

<script>
export default {
  name: "NotificationCard",

  props: {
    notification: {
      type: Object,
      required: true
    },
  },

  methods: {
    hideNotification() {
      this.$store.dispatch('notification/hideNotification', this.notification.id)
    }
  }
}
</script>
