export function downloadFile(requestResponse, fileName) {
  const fileUrl = window.URL.createObjectURL(requestResponse)

  const anchorTag = document.createElement('a')
  anchorTag.href = fileUrl
  anchorTag.download = fileName

  document.body.appendChild(anchorTag)

  anchorTag.click()

  anchorTag.remove()
}