import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'Document category',

  formData: {
    uuid: '',
    title: '',
    parent_uuid: '',
    active: false
  },

  dummyData: {
    uuid: '',
    title: '',
  },

  notificationTitle: 'title',
  primaryKey: 'uuid',

  fetchedCounts: 0,
})
