<template lang="pug">
.navbar-brand-box
    // Dark Logo
    a.logo.logo-dark(href='/')
      span.logo-sm
        img(
          src='@/assets/images/logo-sm.png'
          height='40'
        )
      span.logo-lg
        img(
          src='@/assets/images/logo-dark.png'
          height='35'
        )
    // Light Logo
    a.logo.logo-light(href='/')
      span.logo-sm
        img(
          src='@/assets/images/logo-sm.png'
          height='40'
        )
      span.logo-lg
        img(
          src='@/assets/images/logo-light.png'
          height='35'
        )
    button#vertical-hover.btn.btn-sm.p-0.fs-20.header-item.float-end.btn-vertical-sm-hover(type='button')
      i.ri-record-circle-line
</template>

<script>
export default {
  name: 'MenuLogo'
}
</script>