import store from '@/store'

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/auth/Login"),
    meta:  {
      title: "Login",
      layout: 'auth'
    }
  },
  {
    path: "/forgot-password",
    name: "forgot password",
    component: () => import("@/pages/auth/ForgotPassword"),
    meta: {
      title: "Forgot Password",
      layout: 'auth'
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      layout: 'auth'
    },
    component: () => import("@/pages/auth/Login"),
  },
  {
    path: "/",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("@/pages/dashboard/Index"),
    beforeEnter: () => {
      if (store.getters['auth/isCurrentUserClient'])
        store.dispatch('projects/setCurrentModuleTab', 'information')
    }
  },
  {
    path: "/task-manager",
    name: "task-manager",
    meta: {
      title: "Task manager",
      authRequired: true,
      role: 'view-task-manager',
    },
    component: () => import("@/pages/taskManager/Index"),
  },
  {
    path: "/task-manager/:slug",
    name: "task-manager-detail",
    meta: {
      title: "Task details",
      authRequired: true,
      role: 'view-task-manager',
      module: 'taskManagerTasks',
    },
    component: () => import("@/pages/taskManager/Detail"),
  },
  {
    path: "/task-manager/history",
    name: "task-manager-history",
    meta: {
      title: "Tasks histories",
      authRequired: true,
      role: 'view-task-manager',
    },
    component: () => import("@/pages/taskManager/History"),
  },
  {
    path: "/employees",
    name: "employees",
    meta: {
      title: "Employees",
      authRequired: true,
      role: 'view-employees',
    },
    component: () => import("@/pages/employees/Index"),
  },
  {
    path: "/profile/:slug",
    name: "profile",
    meta: {
      title: "Profile",
      authRequired: true,
      role: 'view-employees-details',
      module: ['employees', 'personCertificates', 'employeeSchedules'],
    },
    component: () => import("@/pages/profile/Index"),
  },
  {
    path: "/projects",
    name: "projects",
    meta: {
      title: "Projects",
      authRequired: true,
      role: 'view-projects',
    },
    component: () => import("@/pages/projects/Index"),
  },
  {
    path: "/project/:slug/",
    name: "project",
    meta: {
      title: "Project",
      authRequired: true,
      role: 'view-projects',
      module: 'projects',
    },
    component: () => import("@/pages/projects/Detail"),
  },
  {
    path: "/warehouse",
    name: "warehouse",
    meta: {
      title: "Warehouse",
      authRequired: true,
      role: 'view-warehouse',
    },
    component: () => import("@/pages/warehouse/Index"),
  },
  {
    path: "/resources/accommodations",
    name: "accommodations",
    meta: {
      title: "Accommodation",
      authRequired: true,
      role: 'view-accommodations',
    },
    component: () => import("@/pages/resources/accommodation/AccommodationIndex"),
  },
  {
    path: "/warehouse/:slug/",
    name: "tool",
    meta: {
      title: "Tool",
      authRequired: true,
      role: 'view-warehouse',
      module: 'warehouse',
    },
    component: () => import("@/pages/warehouse/Detail"),
  },
  {
    path: "/resources/accommodations/:slug",
    name: "accommodation",
    meta: {
      title: "Accommodation",
      authRequired: true,
      role: 'view-accommodations',
      module: 'accommodations',
    },
    component: () => import("@/pages/resources/accommodation/AccommodationDetail"),
  },
  {
    path: "/resources/transports",
    name: "transports",
    meta: {
      title: "Transport",
      authRequired: true,
      role: 'view-transports',
    },
    component: () => import("@/pages/resources/transport/TransportIndex"),
  },
  {
    path: "/resources/transports/:slug",
    name: "transport",
    meta: {
      title: "Transport",
      authRequired: true,
      role: 'view-transports',
      module: 'transports',
    },
    component: () => import("@/pages/resources/transport/TransportDetail"),
  },
  {
    path: "/resources/fuel-cards",
    name: "fuel-cards",
    meta: {
      title: "Fuel cards",
      authRequired: true,
      role: 'view-transports',
    },
    component: () => import("@/pages/resources/fuelCard/FueldCardIndex"),
  },
  {
    path: "/sales",
    name: "sales",
    meta: {
      title: "Sales",
      authRequired: true,
      role: 'view-sales',
    },
    component: () => import("@/pages/sales/Index"),
  },
  {
    path: "/candidates",
    name: "candidates",
    meta: {
      title: "Candidates",
      authRequired: true,
      role: 'view-candidates',
    },
    component: () => import("@/pages/candidates/Index"),
  },
  {
    path: "/candidate/:slug",
    name: "candidate",
    meta: {
      title: "Candidate",
      authRequired: true,
      role: 'view-candidates',
      module: 'candidates',
    },
    component: () => import("@/pages/candidates/CandidateProfile"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      title: "Settings",
      authRequired: true,
      role: 'view-settings',
    },
    component: () => import("@/pages/settings/Index"),
  },
  {
    path: "/performed-table",
    name: "performed-table",
    meta: {
      title: "Performed table",
      authRequired: true,
      role: 'view-performed-table',
    },
    component: () => import("@/pages/performedTable/Index"),
  },
  {
    path: "/rotation-table",
    name: "rotation-table",
    meta: {
      title: "Rotation table",
      authRequired: true,
      role: 'view-rotation-table',
    },
    component: () => import("@/pages/rotationTable/Index"),
  },
  {
    path: "/employees/documents",
    name: 'employees-documents',
    meta: {
      title: 'Employees documents',
      authRequired: true,
      role: '',
    },
    component: () => import("@/pages/documents/SignatureDocuments"),
  },
  {
    path: "/documents",
    name: 'documents',
    meta: {
      title: 'Documents',
      authRequired: true,
      // TODO: reconsider documents role
      role: 'view-documents',
    },
    component: () => import("@/pages/documents/DocumentsIndex"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: {
      title: "404",
      authRequired: true,
    },
    component: () => import("@/pages/errors/404"),
  },
]
