import BaseState from '@/store/base/state'
import TimekeepingState from '@/store/base/timekeeping/state'

export default BaseState({
  module: {},

  moduleTitle: 'Employee timekeeping template',

  formData: Object.assign({
    uuid: '',
    schedule_starts_at: '',
    schedule_ends_at: '',
  }, {days: TimekeepingState.formData.days}),

  dummyData: {
    uuid: '',
  },

  assignFormData: {
    module_uuid: null, // timekeeping uuid
    assign_uuid: null, // employee uuid

    days: {
      monday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      tuesday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      wednesday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      thursday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      friday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      saturday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },

      sunday: {
        work_starts_at: null,
        work_ends_at: null,
        lunch_starts_at: null,
        lunch_ends_at: null,
      },
    }
  },

  notificationTitle: 'day',
  primaryKey: 'uuid',
}, [TimekeepingState])
