import BaseState from '@/store/base/state'

export default BaseState({
  defaultRoles: [],

  moduleTitle: 'Roles',

  formData: {
    name: '',
    description: ''
  },

  dummyData: {
    name: 'title',
    description: 'description'
  },

  notificationTitle: 'name',
  primaryKey: 'slug',
})