import * as Types from '@/store/mutationTypes'

export default () => ({
    [Types.SET_CURRENT_MODULE](state, module) {
        if(typeof module?.uuid === 'string') {
            state.currentModule = module

            return
        }

        state.currentModule = {}
    },

    [Types.SET_CURRENT_MODULE_TAB](state, tab) {
        state.currentModuleTab = tab
    },
})
