import * as Types from '@/store/mutationTypes'

export default () => ({
  [Types.SET_BLACKLIST_FORM_INPUT_DATA](state, {key, value}) {
    state.blacklistFormData[key] = value
  },

  [Types.EMPTY_BLACKLIST_FORM_DATA](state) {
    Object.keys(state.blacklistFormData).forEach(dataKey => state.blacklistFormData[dataKey] = '')
  },

  [Types.FILL_BLACKLIST_FORM_DATA](state, uuid) {
    const module = state.module.find(el => el.uuid === uuid)

    state.blacklistFormData.uuid = module.uuid
  },

  [Types.SET_BLACKLIST_FORM_DATA](state, data) {
    state.blacklistFormData = data
  },
})