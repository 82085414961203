import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    modalTitle: 'No title',
    formModel: '',
    formAction: '',
    showForm: false,
    formSubmitted: false,
    formFieldsErrors: [],
    formErrors: [],
    formMetaData: {},
    formName: null,
  }),
  getters,
  actions,
  mutations
}
