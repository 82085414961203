export default {
    getActiveModules: state => state.module['active'] ?? [],
    getArchivedModules: state => state.module['archived'] ?? [],
    filteredByArchiveProjects: state => !!state.filteringArchivedModules,
    getModule: state => {
        if(Array.isArray(state.module['active']) && Array.isArray(state.module['archived']))
            return state.module['active'].concat(state.module['archived'])

        return []
    },
    getArchiveFormData: state => state.archiveFormData,
    getArchiveDummyData: state => state.archiveDummyData,
    getArchiveInputData: state => key => state.archiveFormData[key],
    getArchiveRangeInputData: state => (fromKey, toKey) => ({from: state.archiveFormData[fromKey], to: state.archiveFormData[toKey]}),
    // TODO: leave it or ignore 'uuid' in 'archiveFormData'
    archiveFormHasFields: state => 1 < Object.keys(state.archiveFormData).length,
    getModuleByKey: (state, getters) => (key, value) => getters['getModule'].find(module => module[key] === value),
}