<template lang="pug">
footer.footer
  .container-fluid
    .row
      .col-sm-6 2022 &copy; Constro
      .col-sm-6
        .text-sm-end.d-none.d-sm-block  Design &amp; Develop by&nbsp;
          a(href="https://navus.lt") Navus

</template>

<script>
export default {
  name: "FooterRow"
}
</script>

<style scoped>

</style>