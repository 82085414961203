import moment from "moment";
import {userCan, isPastDate, isFutureDate, isPresentDate} from "@/helpers/formatters";

export default {
  methods: {
    isClient() {
      return this.$store.getters['auth/isCurrentUserClient']
    },

    userCan(permission) {
      return userCan(permission)
    },

    formatDatesDifference(endDate, startDate = new Date(), expiredText = 'Expired') {
      const momentEndDate = moment(endDate)

      if(!momentEndDate?.isValid())
        return ''

      if(!momentEndDate.isBefore())
        return moment.duration(moment(startDate).diff(momentEndDate)).locale('en').humanize()

      return expiredText
    },

    formatDateRange: (startDate, endDate, format = 'YYYY-MM-DD') => {
      let formatted = ''

      if(startDate && endDate)
        formatted = `${moment(startDate).format(format)} - ${moment(endDate).format(format)}`
      else if(startDate)
        formatted = `from ${moment(startDate).format(format)}`
      else if(endDate)
        formatted = `to ${moment(endDate).format(format)}`

      return formatted
    },

    formatTimeAgo(date) {
      return moment(date).locale('en').fromNow()
    },

    formatTimeIn(futureDate, granulity = undefined) {
      // If 'futureDate' variable is in the past, then return false
      if(
          !this.isValidDate(futureDate) ||
          this.isPastDate(futureDate)
      )
        return false

      return moment(futureDate).diff(moment(), granulity)
    },

    isValidDate(date) {
      return moment(date).isValid()
    },

    isPastDate(date) {
      return isPastDate(date)
    },

    isFutureDate(date) {
      return isFutureDate(date)
    },

    isPresentDate(date, granulity = undefined) {
      return isPresentDate(date, granulity)
    },
  }
}