import DocumentsGetters from "@/store/base/documents/getters";
import HasFormGetters from "@/store/base/hasForm/getters";

export default Object.assign({}, DocumentsGetters, HasFormGetters, {
  getDocuments: state => state.documents,
	getEmployees: state => state.employees,
	getPositions: state => state.positions,
	getExcludeEmployees: state => state.exclude_employees,
	getExcludePositions: state => state.exclude_positions,
	getSelectedAll: state => state.selectedAll,
	getMassSignDocuments: state => state.massSignDocuments,
	getModuleTitle: state => state.moduleTitle,
	getToSms: state => state.toSms,
	getToEmail: state => state.toEmail,
	getSignatureIsQualified: state => state.qualified
})
