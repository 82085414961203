export default {
    getAllDocuments: state => state.documents,
    getDocuments: state => state.documents?.filter(document => !document.is_invoice) || [],
    getInvoices: state => state.documents?.filter(document => document.is_invoice) || [],
    getAllowedFileTypes: state => state.allowedFileTypes,
    getFormFilesData: state => state.formFilesData,
    getDocumentsFilesData: state => state.formFilesData.documents,
    cancelDocumentsWebsocket: (state, getters) => (event) => {
        return event.data?.stateVariable === 'documents' && event.data.base_model_uuid && getters['getCurrentModule']?.uuid !== event.data.base_model_uuid
    },
}