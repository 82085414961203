export default {
  data() {
    return {
      menus: [
        {
          title: 'Dashboard',
          role: '',
          routeName: 'dashboard',
          realUrl: true,
          id: 'sidebarDashboards',
          icon: 'ri-dashboard-line',
          subMenu: []
        },
        {
          title: 'Employees',
          role: 'view-employees',
          routeName: 'employees',
          realUrl: true,
          id: 'sidebarEmployees',
          icon: 'ri-team-line',
          subMenu: []
        },
        {
          title: 'Projects',
          role: 'view-projects',
          routeName: 'projects',
          realUrl: true,
          id: 'sidebarProjects',
          icon: 'ri-projector-2-line',
          subMenu: [],
          condition: () => {
            return !this.$store.getters['auth/isCurrentUserClient']
          }
        },
        {
          title: 'Warehouse',
          role: 'view-warehouse',
          routeName: 'warehouse',
          realUrl: true,
          id: 'sidebarWarehouse',
          icon: 'ri-home-3-line',
          subMenu: []
        },
        {
          title: 'Resources',
          role: '',
          routeName: 'accommodations',
          realUrl: false,
          id: 'sidebarResources',
          icon: 'ri-pantone-line',
          subMenu: [
            {
              title: 'Accommodation',
              role: 'view-accommodations',
              routeName: 'accommodations',
              realUrl: true,
              id: 'sidebarAccommodation',
              icon: 'ri-pantone-line',
              subMenu: []
            },
            {
              title: 'Transport',
              role: 'view-transports',
              routeName: 'transports',
              realUrl: true,
              id: 'sidebarTransport',
              icon: 'ri-car-line',
              subMenu: []
            },
            {
              title: 'Fuel card',
              role: 'view-transports',
              routeName: 'fuel-cards',
              realUrl: true,
              id: 'sidebarFuelCard',
              icon: 'ri-bank-card-line',
              subMenu: []
            },
          ]
        },
        // {
        //   title: 'Sales',
        //   role: 'view-sales',
        //   routeName: 'sales',
        //   realUrl: true,
        //   id: 'sidebarSales',
        //   icon: 'ri-money-euro-circle-line',
        //   subMenu: []
        // },
        {
          title: 'Candidates',
          role: 'view-candidates',
          routeName: 'candidates',
          realUrl: true,
          id: 'sidebarCandidates',
          icon: 'ri-user-add-line',
          subMenu: []
        },
        {
          title: 'Documents',
          // TODO: reconsider documents role
          role: 'view-documents',
          routeName: 'documents',
          realUrl: true,
          id: 'sidebarDocuments',
          icon: 'ri-file-line',
          subMenu: []
        },
        {
          title: 'Settings',
          role: 'view-settings',
          routeName: 'settings',
          realUrl: true,
          id: 'sidebarSettings',
          icon: 'ri-settings-5-line',
          subMenu: []
        }
      ]
    }
  }
}
