export default {
  showModal: state => state.showModal,
  getModalTitle: state => state.modalTitle,
  getAction: state => state.action,
  getData: state => state.data,
  modalIsLoading: state => state.loading,
  getConfirmTitle: state => state.confirmTitle,
  getCanselTitle: state => state.cancelTitle,
  getBodyType: state => state.bodyType,
  geActionData: state => state.actionData,
  getEmployeeType: state => state.employees,
}