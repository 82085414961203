import HasFormGetters from "@/store/base/hasForm/getters"

export default (getters = {}, extensions = [], importData) => Object.assign({}, HasFormGetters,{
  getModuleTitle: state => state.moduleTitle,
  getModule: state => state.module,
  getModuleByKey: state => (key, value, inArray = false) => {
    if(!inArray)
      return state.module.find(module => module[key] === value)

    return state.module.filter(module => value.includes(module[key]))
  },
  getModuleByKeysValues: state => keysValues => {
    return state.module.filter(module => Object.keys(keysValues).every(key => module[key] === keysValues[key]))
  },
  getModuleData: (state, getters) => module => {
    const searchBy = {}
    importData.multipleFormSearchBy.forEach(key => searchBy[key] = typeof module !== 'undefined' ? module[key] : undefined)

    if(!Object.keys(getters).includes('getSingleFormData'))
      return module

    const singleFormData = getters['getSingleFormData'](searchBy)

    return singleFormData || module
  },
  getByPrimaryKey: (state, getters) => searchable => {
    return state.module.find(module => module[getters['getPrimaryKey']] === searchable[getters['getPrimaryKey']])
  },
  getPages: state => (from, to) => {
    return state.module.slice(from, to)
  },
  getSearchValue: ({searchValue}) => searchValue,
  getFormData: state => state.formData,
  getInputData: state => key => state.formData[key],
  getMultipleFormData: state => state.multipleFormData,
  getFiltersBy: ({filtersBy}) => filtersBy,
  getFilterData: state => key => {
    let filter = state.filtersBy.find(el => el.key === key)

    return filter ? filter.value : ''
  },
  getRangeFilterData: (state, getters) => key => {
    let rangeFilter = state.filtersBy.find(el => el.key === key)?.value
    if(isNaN(rangeFilter?.to))
      rangeFilter = getters['getModuleMinMaxValues'](key)

    return Object.values(rangeFilter)
  },
  getIntervalFilterData: (state) => (keyFrom, keyTo) => {
    let value = null

    const intervalFilter = state.filtersBy.find(filter => filter?.keyFrom === keyFrom && filter?.keyTo === keyTo)

    if(
      intervalFilter?.value?.from &&
      intervalFilter?.value?.to
    )
      value = {from: intervalFilter.value.from, to: intervalFilter.value.to}

    return value
  },
  getDummyData: state => state.dummyData,
  getNotificationKey: state => state.notificationTitle,
  isFetched: state => state.isFetched,
  getSearchResults: state => state.moduleSearchResults,
  getFilters: state => state.filters,
  showSearchResults: state => state.showSearchResults,
  getSelectedFiltersCount: state => state?.filtersBy?.length,
  getRangeInputData: (state, getters, rootState, rootGetters) => (fromKey, toKey) => {
    let getInputData = getters['getInputData']

    switch(rootGetters['form/getFormAction']) {
      case 'assignModel':
        getInputData = getters['getAssignInputData']
        break

      case 'unassignModel':
        getInputData = getters['getUnassignInputData']
        break
    }

    return {from: getInputData(fromKey), to: getInputData(toKey)}
  },
  getModuleMinMaxValues: (state, getters) => key => {
    let minMaxValues = {from: 0, to: 1}

    if(
        getters['getModule']?.length &&
        Object.keys(getters['getModule'][0]).includes(key)
    ) {
      const sorted = getters['getModule'].sort((current, next) => current[key] - next[key])

      minMaxValues = {
        from: Math.floor(sorted[0][key]),
        to: Math.ceil(sorted[sorted.length - 1][key])
      }
    }

    return minMaxValues
  },
  getAssignFormData: state => state.assignFormData,
  getUnassignFormData: state => state.unassignFormData,
  getAssignInputData: state => key => state.assignFormData[key],
  getUnassignInputData: state => key => state.unassignFormData[key],
  getPrimaryKey: state => state.primaryKey,
  getNotificationTitle: (state, getters) => model => {
    const notificationKey = getters['getNotificationKey']

    const replaceKeys = notificationKey.match(/{(.*?)}/g)

    if(replaceKeys) {
      let title = notificationKey

      replaceKeys.forEach(replaceKey => title = title.replace(replaceKey, model[replaceKey.replace(/[{}]/g,'')]))

      return title
    }

    return model[notificationKey]
  },
  getTitleByPrimaryKey: (state, getters) => primaryKeyValue => {
    const primaryKey = getters['getPrimaryKey']

    const module = getters['getModule'].find(module => module[primaryKey] === primaryKeyValue)

    return module ? getters['getNotificationTitle'](module) : undefined
  },
  getStaticCrudAction: state => state.staticCrudActionTitle,
  isUpdating: state => (uuid, properties) => {
    if(typeof state.updating[uuid] === 'undefined')
      return false

    return !!state.updating[uuid].find(property => property === properties)
  },
  getByIndex: (state) => (...indexes) => {
    const getModule = (module, subLevel) => {
      if(subLevel === indexes?.length)
        return module
      else if(indexes[subLevel] && module?.[indexes[subLevel]])
        return getModule(module[indexes[subLevel]], subLevel + 1)

      return undefined
    }

    const model = getModule(state.module, 0) || {}

    return Object.values(model).length ? model : undefined
  },
  getChecked: ({checked}) => Object.keys(checked),
  isChecked: ({checked}) => (primaryKeyValue) => !!checked?.[primaryKeyValue],
  checkedCount: ({checked}) => Object.keys(checked)?.length || 0,
}, ...extensions, getters)