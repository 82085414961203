import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    modalTitle: '',
    moduleName: '',
    showModal: false,
    options: {},
  }),
  getters,
  actions,
  mutations
}
