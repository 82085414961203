import BaseState from '@/store/base/state'

export default BaseState({
    moduleTitle: 'Transport services',

    formData: {
        uuid: '',
        transport_uuid: '',
        action_taken: '',
        serviced_at: '',
        price: '',
        information: '',
    },

    dummyData: {
        uuid: '',
        transport_uuid: '',
        action_taken: 'sentence',
        serviced_at: {key: 'YYYY-MM-DD', type: 'date'},
        price: {type: 'double', from: 100, to: 300},
        information: 'description',
    },

    notificationTitle: 'action_taken',
    primaryKey: 'uuid',

    searchBy: [],
})