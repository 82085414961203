import BaseGetters from "@/store/base/getters";
import DetailGetters from "@/store/base/detail/getters";
import DocumentsGetters from "@/store/base/documents/getters";
import HistoriesGetters from "@/store/base/histories/getters";

export default BaseGetters({
  getPriorityLevels: state => state.priorityLevels,
  getStatusTypes: state => state.statusTypes,
  getAssignees: (state, getters, rootState, rootGetters) => ({assigned_users, assigned_roles}, max = -1) => {
    const users = rootGetters['users/getModule'].filter(user => {
      return assigned_roles?.includes(user?.role?.slug) || assigned_users?.includes(user.uuid)
    })

    return 0 < max ? users.slice(0, max) : users
  },
  getUserTasks: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['auth/authUser']

    if(!user)
      return []

    return state.module.filter(task => {
      return task.assigned_users?.includes(user.uuid) || task.assigned_roles?.includes(user.role.slug)
    })
  },
  getTaskTypes: (state, getters, rootState, rootGetters) => {
    const vueModules = ['accommodations', 'candidates', 'employees', 'employeeSchedules', 'transports', 'warehouse']

    return vueModules.map(vueModule => ({key: vueModule, label: rootGetters[`${vueModule}/getModuleTitle`]}))
  },
  getTaskType: (state, getters, rootState, rootGetters) => ({model}) => {
    if(!model)
      return 'System'

    return rootGetters[`${model}/getModuleTitle`] || 'System'
  },
  getStatistics: (state, getters) => {
    let pending = 0
    let done = 0

    const tasks = getters['getModule']
    tasks.forEach((task) => {
      switch (task.status) {
        case 3:
          pending++
          break

        case 4:
          done++
          break
      }
    })

    return {
      total: tasks.length,
      pending,
      done,
    }
  },
}, [DetailGetters, DocumentsGetters, HistoriesGetters])
