import BaseState from '@/store/base/state'
import DocumentsState from '@/store/base/documents/state'

export default BaseState({
  moduleTitle: 'Employee work trip',

  formData: {
    uuid: '',
    employee_uuid: '',
    direction_of_travel: '',
    documents: [], //
    car_uuid: '',
    fuel_card_nr: '',
    responsible_person_full_name: '',
    responsible_person_phone: '',
    workplace_address: '',
    notes: '',
    transport_info: {},
    transport_type: [],
    trip_destination: ''
  },

  dummyData: {
    uuid: '',
    employee_uuid: '',
    direction_of_travel: '',
    documents: [], //
    car_uuid: '',
    fuel_card_nr: '',
    responsible_person_full_name: '',
    responsible_person_phone: '',
    workplace_address: '',
    notes: '',
    transport_info: {},
    transport_type: [],
    trip_destination: ''
  },

  notificationTitle: 'trip_destination',
  primaryKey: 'uuid',
}, [DocumentsState])