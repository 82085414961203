import DocumentSchema from '@/store/base/documents/schema'

export default Object.assign({}, DocumentSchema, {
  serialize({getters}) {
    const inputsData = getters['getFormData']

    return {
      uuid: inputsData?.uuid,
      project_uuid: inputsData?.project_uuid,
      address: inputsData?.address,
      country_id: inputsData?.country_id,
      city_id: inputsData?.city_id,
      total_beds: inputsData?.total_beds,
      price: inputsData?.price,
      rental_period_starts_at: inputsData?.rental_period_starts_at,
      rental_period_ends_at: inputsData?.rental_period_ends_at,
      contact_details: inputsData?.contact_details,
      lessor: inputsData?.lessor,
      comment: inputsData?.comment,
    }
  },
})