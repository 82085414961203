import BaseState from '@/store/base/state'
import DetailState from "@/store/base/detail/state";
import DocumentsState from "@/store/base/documents/state";
import ArchivesState from "@/store/base/archives/state";
import HistoriesState from "@/store/base/histories/state";

export default BaseState({
    moduleTitle: 'Transport',

    formData: {
        uuid: '',
        body_type: '',
        registration_number: '',
        car_brand_uuid: '',
        car_model_uuid: '',
        seats: 0,
        insurance_starts_at: '',
        insurance_ends_at: '',
        inspection_starts_at: '',
        inspection_ends_at: '',
        fuel_cards: [],
        ownership_type: '',
        information: '',
        project_uuid: '',

        // rent fields
        price: 0,
        contract_ends_at: '',
    },

    dummyData: {
        uuid: '',
        body_type: {key: 'slug', type: 'random_element', getterNamespace:'transports/getBodyTypes'},
        registration_number: 'word',
        car_brand_uuid: {key: 'uuid', type: 'random_element', getterNamespace:'carCategories/getBrands'},
        car_model_uuid: '',
        seats: {type: 'integer', from: 1, to: 6},
        insurance_starts_at: {key: 'YYYY-MM-DD', type: 'date'},
        insurance_ends_at: {key: 'YYYY-MM-DD', type: 'date'},
        inspection_starts_at: {key: 'YYYY-MM-DD', type: 'date'},
        inspection_ends_at: {key: 'YYYY-MM-DD', type: 'date'},
        fuel_cards: [],
        ownership_type: {key: 'slug', type: 'random_element', getterNamespace:'transports/getOwnershipTypes'},
        information: 'description',
        project_uuid: '',

        // rent fields
        contract_ends_at: {key: 'YYYY-MM-DD', type: 'date'},
        price: {type: 'double', from: 100, to: 300},
    },

    notificationTitle: 'registration_number',
    primaryKey: 'uuid',

    currentModule: {},
    currentModuleTabs: {
        information: {title: 'Information'},
        employees: {title: 'Employees'},
        defects: {title: 'Defects'},
        services: {title: 'Services'},
        documents: {title: 'Documents'},
        history: {title: 'History'},
    },
    currentModuleTab: 'information',

    ownershipTypes: [
        {
            name: 'Own',
            slug: 'own',
        },
        {
            name: 'Rent',
            slug: 'rent',
        },
        {
            name: 'Personal',
            slug: 'personal',
        },
    ],

    bodyTypes: [
        {
            name: 'Car',
            slug: 'car'
        },
        {
            name: 'Passenger minibus',
            slug: 'passenger_minibus'
        },
        {
            name: 'Cargo minibus',
            slug: 'cargo_minibus'
        },
        {
            name: 'Minibus car',
            slug: 'minibus_car'
        },
        {
            name: 'Bus',
            slug: 'bus'
        },
        {
            name: 'Other',
            slug: 'other'
        },
    ],

    searchBy: ['car_brand', 'car_model', 'registration_number', 'object_no'],
}, [ArchivesState, DocumentsState, DetailState, HistoriesState])
