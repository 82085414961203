import BaseActions from '@/store/base/actions'
import DocumentsActions from '@/store/base/documents/actions'
import Schema from "@/store/modules/employeeWorkTrip/schema";
import Api from '@/plugins/axios'
import * as Types from './../../mutationTypes'

const module = 'employeeWorkTrip'

export default BaseActions({module}, {
  async getCarList() {
		return await Api.get('/employee-work-trip/get-car-list')
			.then(res => {
				return res.data
			})
			.catch(error => {
				console.log('error', error)
			})
	},

	fetchModule({ commit }, uuid) {
		console.log('fetchModule')
		Api.post('/employee-work-trip/get-by-key', {
			key: 'employee_uuid',
			value: uuid
		})
			.then(res => {
				commit(Types.SET_MODEL, res.data)
			})
	},

  validateWebsocketResponse({commit, rootGetters}, {type, event}) {
		if(rootGetters['employees/getCurrentModuleUuid'] !== event.data.employee_uuid)
			return

    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_TO_MODEL, [event.data])
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [DocumentsActions({module}), Schema])