import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'Comment',

  formData: {
    uuid: '',
    model: '',
    model_uuid: '',
    content: '',
  },

  dummyData: {
    uuid: '',
    model: '',
    model_uuid: '',
    content: 'description',
  },

  notificationTitle: 'content',
  primaryKey: 'uuid',
})