import BaseGetters from '@/store/base/getters'
import MultiFormGetters from '@/store/base/multipleFormData/getters'

export default BaseGetters({
  getModuleArray: (state) => {
    return Object.values(state.module)
  },

  getEmployeeRotation: (state, getters) => (employee, withMultipleFormData = true) => {
    if(withMultipleFormData) {
      const singleFormData = getters['getSingleFormData']({
        employee_uuid: employee.uuid,
      })

      if(singleFormData)
        return singleFormData
    }

    return getters['getByIndex'](employee.uuid)
  },

  getModuleByKeysValues: (state, getters) => (keysValues) => {
    return getters['getModuleArray'].filter(model => {
      return Object.keys(keysValues).every((key) => model[key] === keysValues[key])
    })
  },

  getByPrimaryKey: (state, getters) => searchable => {
    let primaryKey = getters['getPrimaryKey']

    return getters['getModuleArray'].find(model => model[primaryKey] === searchable[primaryKey])
  },
}, [MultiFormGetters()])