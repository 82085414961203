import * as Types from './../../mutationTypes'

export default {
  [Types.POP_PAGE](state) {
    if(state.history.length)
      state.history.pop()
  },

  [Types.PUSH_PAGE](state, page) {
    state.history.push(page)
  },
}
