import {formatModuleUrl} from '@/helpers/formatters'
import * as Types from '@/store/mutationTypes'
import api from '@/plugins/axios'

export default (module) => ({
  setMultipleFormData({dispatch, commit, getters}, {keysValues, key, value}) {
    let module = getters['getModuleByKeysValues'](keysValues)?.[0] || keysValues

    commit(Types.SET_MULTIPLE_FORM_DATA, {
      searchBy: keysValues,
      module,
      presetModule: keysValues,
      key,
      value,
    })

    const formData = getters['getSingleFormData'](keysValues)

    const searchBy = {}
    getters['getFormIdFields'].forEach(key => searchBy[key] = formData[key])

    commit(Types.SET_MULTIPLE_FORM_DATA_UPDATING_STATUS, {
      searchBy,
      updating: true,
    })

    dispatch('createOrUpdate', {
      create: !formData.uuid,
      model: Object.assign({}, getters['getSingleFormData'](keysValues))
    })
  },

  async createOrUpdate({dispatch, commit, getters}, {create, model}) {
    let payload = model

    await dispatch('serialize', payload)
      .then(serialized => payload = serialized || payload)

    api.post(`${formatModuleUrl(module)}/${create ? 'create' : 'edit'}`, payload)
      .then(async response => {
        let data = response.data

        await dispatch('parse', data)
          .then(parsedData => data = parsedData || data)

        if(create)
          await dispatch('addToModel', {data})
        else
          await dispatch('editSingleModel', {data})

        commit(Types.REMOVE_SINGLE_MULTIPLE_FORM_DATA, data)
      })
      .catch(() => {
        const searchBy = {}
        getters['getFormIdFields'].forEach(key => searchBy[key] = payload[key])

        commit(Types.SET_MULTIPLE_FORM_DATA_UPDATING_STATUS, {
          searchBy,
          updating: false,
        })
      })
  },
})