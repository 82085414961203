<template lang="pug">
li(:class="['nav-item', item.subMenu.length ? 'sub-menu' : '']")
  a.nav-link.menu-link(
    :class="[activeMenu(item.routeName) ? 'active' : '']"
    :href="'#' + item.id"
    @click.prevent="openPage(item.routeName, item.realUrl )"
    :aria-expanded="activeMenu(item.routeName)"
    :data-bs-toggle="item.subMenu.length > 0 ? 'collapse' : ''"
    role="button"
    :aria-controls="item.subMenu.length > 0 ? 'sidebarDashboards' : ''"
    v-if="userCan(item.role) && (typeof item?.condition === 'function' ? item.condition() : true)"
  )
    i(
      :class="item.icon"
    )
    span {{ item.title }}

  // Submenu dropdown
  .collapse.menu-dropdown.show(
    id="item.id"
    v-if="item.subMenu.length > 0"
  )
    ul.nav.nav-sm.flex-column
      li.nav-item(
        v-for="subItem in item.subMenu"
      )
        a.nav-link(
          :class="[activeMenu(subItem.routeName) ? 'router-link-active router-link-exact-active active' : '']"
          href='#'
          @click.prevent="openPage(subItem.routeName, subItem.realUrl )"
        ) {{ subItem.title }}

</template>

<script>
import routerMixin from "@/mixins/routerMixin";

export default {
  name: 'MenuNavBarItem',

  mixins: [routerMixin],

  props: ['item'],

}
</script>

<style scoped>

</style>