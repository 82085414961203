import BaseMutations from '@/store/base/mutations'
import DocumentsMutations from '@/store/base/documents/mutations'
import * as Types from '../../mutationTypes'

const module = 'documents'

export default BaseMutations({
  [Types.FETCH_STARTED](state) {
    state.isFetching = true
  },

  [Types.FETCH_ENDED](state) {
    state.isFetching = false
  },
}, [DocumentsMutations(module)])
