import BaseMutations from "@/store/base/mutations";
import DetailMutations from "@/store/base/detail/mutations";
import DocumentsMutations from "@/store/base/documents/mutations";
import HistoriesMutations from "@/store/base/histories/mutations";

const module = 'taskManagerTasks'

export default BaseMutations({
  //
}, [DetailMutations({module}), DocumentsMutations(module), HistoriesMutations({module})])
