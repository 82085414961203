import BaseActions from './../../base/actions'
import TimekeepingActions from '@/store/base/timekeeping/actions'
import Schema from './schema'
import * as Types from '@/store/mutationTypes'

const module = 'settingsTimekeepingTemplates'

export default BaseActions({module}, {
  setFormObjectData({commit, rootGetters}, {weekday, property, value}) {
    commit(Types.SET_FORM_OBJECT_DATA, {weekday, property, value, formAction: rootGetters['form/getFormAction']})
  },
}, [TimekeepingActions({module}), Schema])