export default {
  serialize({getters}) {
    const formData = getters['getFormData']

    return {
      uuid: formData.uuid,
      title: formData.title,
      parent_uuid: formData.parent_uuid,
      level_depth: formData.level_depth,
      active: !!formData.active,
    }
  },
}