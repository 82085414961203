import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    // {
    //     type: 'success|warning|error',
    //     message: '',
    //     actions: {
    //         close: false|true,
    //         undo: false|true
    //     }
    // },
    notifications: [],
    defaultDuration: 5000
  }),
  getters,
  actions,
  mutations
}
