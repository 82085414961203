import DocumentSchema from "@/store/base/documents/schema";

export default Object.assign({}, DocumentSchema, {
  serialize() {
    return null
  },

  parse() {
    return null
  },
})