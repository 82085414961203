import * as Types from '@/store/mutationTypes'
import api from '@/plugins/axios'
import Schema from "@/store/base/documents/schema";

export function archiveActions(apiUrlPrefix = null) {
  const prefix = apiUrlPrefix && apiUrlPrefix !== 'documents' ? apiUrlPrefix.split(/(?=[A-Z])/).join('-').toLowerCase() : ''

  return {
    archiveDocuments({commit}, {documents_uuid}) {
      api.post('documents/archive', {
        uuid: documents_uuid
      }).then(response => {
        commit(Types.ARHIVE_DOCUMENTS, response.data)

        this.dispatch('warning/closeModal', {root: true})
      }).catch(() => {
        this.dispatch('warning/closeModal', {root: true})
      })
    },

    restoreDocuments({commit}, {documents_uuid}) {
      api.post('documents/unarchive', {
        uuid: documents_uuid
      }).then(response => {
        commit(Types.RESTORE_DOCUMENTS, response.data)

        this.dispatch('warning/closeModal', {root: true})
      }).catch(() => {
        this.dispatch('warning/closeModal', {root: true})
      })
    },

    deleteDocuments({commit}, {base_model_uuid, second_model_uuid, documents_uuid, key, value}) {
      api.post(`${prefix}/documents/delete`, {
        base_model_uuid,
        second_model_uuid,
        documents_uuid,
        key,
        value
      }).then(response => {
        commit(Types.DELETE_DOCUMENTS, response.data)
        this.dispatch('warning/closeModal', {root: true})
      }).catch(() => {
        this.dispatch('warning/closeModal', {root: true})
      })
    },
  }
}

export default ({module}) => Object.assign({}, archiveActions(module), {
  setFormFilesData({commit}, data) {
    commit(Types.SET_FORM_FILES_DATA, data)
  },

  async fetchDocuments({commit}, {model, modelUuid}) {
    let documents = null

    if(typeof modelUuid === 'string' && modelUuid.length) {
      await api.post(`${model}/documents/all`, {
        model_uuid: modelUuid
      }).then(response => {
        documents = response.data
      })
    }

    if(!Array.isArray(documents) || !documents?.length)
      documents = []

    commit(Types.SET_DOCUMENTS, documents)
  },

  addDocuments({commit}, uploadedDocuments) {
    commit(Types.CREATE_DOCUMENTS, uploadedDocuments)
  },

  unsetDocuments({commit}) {
    commit(Types.UNSET_DOCUMENTS)
  },

  downloadDocument(store, {documentUuid, documentName}) {
    const url = module.split(/(?=[A-Z])/).join('-').toLowerCase()

    api.post(`${url}/documents/download`, {
      document_uuid: documentUuid
    }, {
      responseType: 'blob'
    }).then(response => {
      const fileUrl = window.URL.createObjectURL(response)

      const anchorTag = document.createElement('a')
      anchorTag.href = fileUrl
      anchorTag.setAttribute('download', documentName)

      document.body.appendChild(anchorTag)

      anchorTag.click()

      anchorTag.remove()
    })
  },

  emptyFilesFormData({commit}) {
    commit(Types.EMPTY_FILES_FORM_DATA)
  },

  setDocumentData({commit}, {index, key, value}) {
    commit(Types.SET_FORM_DOCUMENT_DATA, {index, key, value})
  },

  setInvoiceProductData({commit}, {invoiceIndex, productIndex, key, value}) {
    commit(Types.SET_FORM_INVOICE_PRODUCT_DATA, {invoiceIndex, productIndex, key, value})
  },

  addInvoiceProduct({commit}, invoiceIndex) {
    commit(Types.ADD_FORM_INVOICE_PRODUCT, invoiceIndex)
  },

  destroyInvoiceProduct({commit}, {invoiceIndex, productIndex}) {
    commit(Types.DESTROY_FORM_INVOICE_PRODUCT, {invoiceIndex, productIndex})
  },

  serializeUpload() {
    return null
  },
}, Schema)
