export default {
  serialize(context, payload) {
    return {
      uuid: payload.uuid,
      project_uuid: payload.project_uuid,
      year: payload.year,
      month: payload.month,
      hours: payload.hours,
      work_performed: payload.work_performed,
      hourly_rate: payload.hourly_rate,
      revised_hours: payload.revised_hours,
      revised_work_performed: payload.revised_work_performed,
      revised_hourly_rate: payload.revised_hourly_rate,
    }
  },
}