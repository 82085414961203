import {formatModuleUrl} from "@/helpers/formatters";
import * as Types from "@/store/mutationTypes";
import api from '@/plugins/axios'

export default ({module}) => ({
  async fetchHistories({dispatch, commit}, {uuid, limit = 20, page}) {
    commit(Types.SET_IS_FETCHING_HISTORIES, true)

    api.post(`${formatModuleUrl(module)}/history`, {uuid, page, model: module, limit})
      .then(async ({data}) => {
        await dispatch('setHistories', {data, append: true})

        dispatch('setHistoriesLoadedLength', data?.length || 0)

        commit(Types.SET_IS_FETCHING_HISTORIES, false)

      }).catch(() => dispatch('setHistoriesLoadedLength', 0))
  },

  setHistories({commit}, {data, append}) {
    commit(Types.SET_HISTORIES, {data, append})
  },

  setHistoriesLoadedLength({commit}, length) {
    commit(Types.SET_HISTORIES_LOADED_LENGTH, length)
  },

  clearHistories({commit}) {
    commit(Types.CLEAR_HISTORIES)
  },

  pushHistory({commit, getters}, data) {
    const profile = getters['getCurrentModule']

    if(profile?.uuid && profile.uuid === data.model_uuid)
      commit(Types.PUSH_HISTORY, data)
  },
})
