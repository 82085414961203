import * as Types from './../../mutationTypes'
import BaseMutations from '@/store/base/mutations'

export default BaseMutations({
  [Types.SET_DEFAULT_ROLES](state, data) {
    const grouped = data.reduce((groups, item) => {
      const group = (groups[item.module] || []);

      group.push(item);
      groups[item.module] = group;

      return groups;
    }, {});

    state.defaultRoles = grouped
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    const hasOld = Object.keys(data).includes('old')

    const index = state.module.findIndex(el => hasOld ? el.slug === data.old.slug : el.slug === data.slug)

    if(0 <= index)
      state.module[index] = hasOld ? data.new : data
  }
})