import HasFormGetters from "@/store/base/hasForm/getters";

export default Object.assign({}, HasFormGetters, {
  getCountries: state => state.countries,
  getCountry: state => id => state.countries.find(country => country.id === id),
  getCities: state => state.cities,
  getCountryCity: state => (country_id, city_id) => state.cities?.[country_id]?.find(city => city.id === city_id),
  getCountryCities: state => country => state.cities[country],
  getStaticCrudAction: state => state.staticCrudActionTitle,
  getNotificationKey: state => state.notificationTitle,
  getModuleTitle: state => state.moduleTitle,
  getNotificationTitle: (state, getters) => model => {
    const notificationKey = getters['getNotificationKey']

    const replaceKeys = notificationKey.match(/{(.*?)}/g)

    if(replaceKeys) {
      let title = notificationKey

      replaceKeys.forEach(replaceKey => title = title.replace(replaceKey, model[replaceKey.replace(/[{}]/g,'')]))

      return title
    }

    return model[notificationKey]
  },
})