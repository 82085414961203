import api from '~/plugins/axios';
import * as Types from './../../mutationTypes'
import Schema from './schema'
import DocumentsActions from "@/store/base/documents/actions"
import HasFormActions from "@/store/base/hasForm/actions"

export default Object.assign({}, DocumentsActions({module: 'signature'}), HasFormActions, Schema, {
  async signDocument({ dispatch, getters }, data) {
		if(data.actionData.employees.length <= 0) {
			data.actionData.employees = getters['getEmployees']
		}

		if(data.actionData.positions.length <= 0) {
			data.actionData.positions = getters['getPositions']
		}

		if(data.actionData.selectedAll.length <= 0) {
			data.actionData.selectedAll = getters['getSelectedAll']
		}

		let exclude_employees = getters['getExcludeEmployees']
		let exclude_positions = getters['getExcludePositions']

		let emails = []
		if(getters['getInputData']('email'))
			await dispatch('serializeEmails', getters['getInputData']('email')).then(serialized => {
				emails = serialized
			})


		api.post('/signature/create', {
			document_uuid: data.actionData.document,
			employees: data.actionData.employees,
			positions: data.actionData.positions,
			selectAll: data.actionData.selectedAll,
			qualified: data.data.qualified,
			toEmail: data.data.toEmail,
			toSms: data.data.toSms,
			exclude_employees: exclude_employees,
			exclude_positions: exclude_positions,
			emails,
		})
			.then(() => {
				dispatch('actions/closeModal', null, {root: true})

				// Show notification
				dispatch('notification/addNotificationToList', {
					type: 'success',
					message: `Document was set for sign.`,
					actions: {
						close: true,
					}
				}, { root: true })
			})
	},

	async documentMassSign({dispatch}) {
		let formData = {}
		await dispatch('serializeCreateWithDocuments')
			.then(serialized => formData = serialized)

		api.post('/signature/create/mass', formData)
			.then(() => {
				dispatch('form/closeForm', null, {root: true})

				// Show notification
				dispatch('notification/addNotificationToList', {
					type: 'success',
					message: `Document was started preparing for sign.`,
					actions: {
						close: true,
					}
				}, { root: true })
			})
	},

	addSingleDocToList({commit, dispatch}, data) {
		commit(Types.ADD_TO_MODEL, data)

		dispatch('notification/addNotificationToList', {
				type: 'success',
				message: `Document: ${data.file_name + '.' + data.file_extension} was sent for signing.`,
				actions: {
					close: true,
				}
			}, { root: true })
	},

	// eslint-disable-next-line no-unused-vars
	// setFormFilesData({ commit }, {key, value}) {
	// 	commit(Types.SIGN_SET_VARIABLES, {key, value})
	// },

	setFormInputData({ commit }, {key, value}) {
		commit(Types.SIGN_SET_VARIABLES, {key, value})
	},

	getMassSignDocuments({ commit }) {
		api.get('/signature/get-mass-sign-documents')
			.then(res => {
				console.log("Mass: ", res)

				commit(Types.SIGN_SET_VARIABLES, {
					key: 'massSignDocuments',
					value: res.data
				})
			})
	},

	delete({dispatch, commit}, data) {
    api.post('/signature/delete', data)
      .then(res => {
        commit(Types.DELETE_FROM_MODULE, res.data)

        dispatch('warning/closeModal', null, { root: true })
      })
      .catch(() => {
        dispatch('warning/closeModal', null, { root: true })
      })
  },

	download(_, {uuid, documentName}) {
		api.post('/signature/download', {
			document_uuid: uuid
		}, {
      responseType: 'blob'
    })
			.then(res => {
				const fileUrl = window.URL.createObjectURL(res)

				const anchorTag = document.createElement('a')
				anchorTag.href = fileUrl
				anchorTag.setAttribute('download', documentName)

				document.body.appendChild(anchorTag)

				anchorTag.click()

				anchorTag.remove()
			})
	},
})
