import DocumentSchema from "@/store/base/documents/schema";
import moment from "moment";

export default Object.assign({}, DocumentSchema, {
  async serialize({dispatch, getters}, data) {
    const inputsData = data || getters['getFormData']

    let description = inputsData?.description || ''
    await dispatch('serializeDescription', description)
      .then(serializedDescription => description = serializedDescription || description)

    return {
      uuid: inputsData?.uuid,
      name: inputsData?.name,
      description,
      due_date_time: inputsData?.due_date_time ? moment(inputsData?.due_date_time, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DD hh:mm:ss') : null,
      status: inputsData?.status,
      priority: inputsData?.priority,
      assigned_users: inputsData?.assigned_users,
      assigned_roles: inputsData?.assigned_roles,
    }
  },

  parse() {
    return null
  },

  parseCurrentModule({getters}, module) {
    const existingModule = getters['getCurrentModule']

    return Object.assign({}, existingModule, {
      histories: existingModule?.histories || [],
    }, module)
  },

  serializeDescription(context, description) {
    let serialized = description

    if(typeof serialized !== 'string')
      return ''

    return serialized?.trim()
  },
})