import {isEqual} from 'lodash'
import * as Types from './../../mutationTypes'

export default {
  popPage({commit}) {
    commit(Types.POP_PAGE)
  },

  pushPage({getters, commit}, page) {
    const lastHistory = getters['getLastHistory']

    if(lastHistory && lastHistory.name === page.name && Object.keys(page.params).length && isEqual(page.params, lastHistory.params))
      return

    let params = {}

    Object.keys(page.params).forEach(key => params[key] = page.params[key])

    if(page)
      commit(Types.PUSH_PAGE, {name: page.name, params})
  }
}
