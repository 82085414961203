import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    showModal: false,
    modalTitle: 'Warning',
    modalQuestion: '',
    modalDescription: '',
    modalDescriptionList: null,
    showConfirmButton: false,
    action: '',
    data: {},
    cancelTitle: null,
    confirmTitle: null,
  }),
  getters,
  actions,
  mutations
}
