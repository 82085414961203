import * as Types from './../../mutationTypes'

export default {
  mobileView({commit}, show) {
    commit(Types.MOBILE_VIEW, show)
  },

  toggleMenu({commit}, data) {
    commit(Types.TOGGLE_MENU, data)
  }
}