import BaseState from '@/store/base/state'
import DocumentsState from "@/store/base/documents/state";
import ArchivesState from "@/store/base/archives/state";
import DetailState from "@/store/base/detail/state";
import TimekeepingState from "@/store/base/timekeeping/state";
import {employeesSearchBy} from "@/store/modules/employees/state";

export default BaseState({
  moduleTitle: 'Project',

  formData: {
    uuid: '',
    accounting_code: '',
    object_no: '',
    constro_company: '',
    client: '',
    company_no: '',
    company_vat: '',
    country_id: '',
    city_id: '',
    address: '',
    phone: '',
    email: '',
    contact_person: '',
    object_name: '',
    object_address: '',
    project_type: '',
    work_begins: '',
    work_ends: '',
    work_volume: null,
    project_value: null,
    payment_period: '',
    contract_no: '',
    contract_date: '',
    project_manager_uuid: '',
    quality_manager: '',
    salesman: '',
    invoice_email_address: '',
    project_category_uuid: '',
    comment: '',
    is_archived: '',
  },

  archiveFormData: {
    uuid: '',
    pending_amounts: null,
    warranty_period_starts_at: '',
    warranty_period_ends_at: '',
    bills_covered: false,
    hours_confirmed: false,
  },

  dummyData: {
    uuid: '',
    accounting_code: {type: 'integer', from: 1000000, to: 9999999},
    object_no: {type: 'integer', from: 100, to: 999},
    constro_company: '',
    client: 'full_name',
    company_no: {type: 'integer', from: 1000000, to: 9999999},
    company_vat: {type: 'integer', from: 1000000, to: 9999999},
    country_id: {key: 'id', type: 'random_element', getterNamespace:'locations/getCountries'},
    city_id: '',
    address: 'address',
    phone: 'phone',
    email: 'email',
    contact_person: 'full_name',
    object_name: 'title',
    object_address: 'address',
    project_type: {key: 'slug', type: 'random_element', getterNamespace:'projects/getProjectTypes'},
    work_begins: {key: 'Y-m-d', type: 'date'},
    work_ends: {key: 'Y-m-d', type: 'date'},
    work_volume: {type: 'integer', from: 5, to: 600},
    project_value: {type: 'integer', from: 1000, to: 100000},
    payment_period: {type: 'integer', from: 10, to: 30},
    contract_no: {type: 'integer', from: 10000, to: 99999},
    contract_date: {key: 'Y-m-d', type: 'date'},
    project_manager_uuid: {key: 'uuid', type: 'random_element', getterNamespace:'users/getModule'},
    quality_manager: 'full_name',
    salesman: 'full_name',
    invoice_email_address: 'email',
    project_category_uuid: {key: 'uuid', type: 'random_element', getterNamespace:'projectCategories/getPrefetchedCategories'},
    comment: 'description',
    is_archived: '',
  },

  archiveDummyData: {
    uuid: '',
    pending_amounts: {type: 'integer', from: 100, to: 500000},
    warranty_period_starts_at: {key: 'Y-m-d', type: 'date'},
    warranty_period_ends_at: {key: 'Y-m-d', type: 'date'},
    bills_covered: 'boolean',
    hours_confirmed: 'boolean',
  },

  notificationTitle: 'object_no',
  primaryKey: 'uuid',

  currentModuleTabs: {
    information: {title: 'Information', permission: 'view-projects-details'},
    employees: {title: 'Employees', permission: 'view-projects-details'},
    tools: {title: 'Tools', permission: 'view-warehouse'},
    accommodations: {title: 'Accommodations', permission: 'view-accommodations'},
    documents: {title: 'Documents', permission: 'view-projects-documents'},
    settings: {title: 'Settings', permission: 'view-projects-details'},
    schedule: {title: 'Schedule', permission: 'view-employee-timekeeping-templates'},
  },

  currentModuleTab: 'information',

  projectTypes: [
    {
      name: 'Project',
      slug: 'project'
    },
    {
      name: 'Hourly',
      slug: 'hourly'
    },
    {
      name: 'Other',
      slug: 'other'
    }
  ],

  performed_table: {
    year: null,
    month: null,
    weeks: {},
    statistics: {},
    exporting: false,
  },

  // TODO: add necessary filters
  searchBy: ['object_no', 'client', 'country'],

  // Ignore project-related searches in project schedule tab
  searchByEmployee: employeesSearchBy.filter(key => !['client', 'object_name'].includes(key)),

  searchByPerformedTable: ['object_no'],
}, [ArchivesState, DocumentsState, DetailState, TimekeepingState])
