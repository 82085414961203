import * as Types from '../../mutationTypes'
import BaseActions from '@/store/base/actions'
import Schema from "@/store/modules/documents/schema";
import DocumentsActions from '@/store/base/documents/actions'
import api from '@/plugins/axios'

const module = 'documents'

let searchByTextTimer = null

export default BaseActions({module}, {
	async fetchPdf({commit, getters, dispatch}, data = {}) {
		if (process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

		commit(Types.FETCH_STARTED)

		await api.post('documents/pdf', data)
			.then(async ({data}) => {
				let modules = data || []

				await dispatch('parseModules', modules)
					.then(parsedModules => modules = parsedModules || [])

				commit(Types.SET_MODEL, modules)

				dispatch('fetchedModel')
			})
			.catch((error) => {
				if (process.env.VUE_APP_DEBUG_MODE === 'true')
					console.error('Failed to fetch PDFs', {error})

				commit(Types.SET_MODEL, [])
			})
			.finally(() => {
				commit(Types.FETCH_ENDED)
			})
	},

	validateWebsocketResponse({commit}, {type, event}) {
		if (event?.data?.file_extension?.toLowerCase() !== 'pdf')
			return

		switch (type) {
			case 'ModelCreateEvent':
				commit(Types.ADD_TO_MODEL, event.data)
				break

			case 'ModelUpdateEvent':
				commit(Types.UPDATE_SINGLE_MODEL, event.data)
				break

			case 'ModelDeleteEvent':
				commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
				break

			case 'ModelArchiveEvent':
				commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
				break
		}
	},

	searchPdf({getters, dispatch}) {
		const payload = {}

		const existsInPayload = (key) => Object.keys(payload).includes(key)

		const search = getters['getSearchValue']
		if(typeof search === 'string' && 2 <= search.length)
			payload.search = search

		const filters = (getters['getFiltersBy'] || [])

		if(Array.isArray(filters))
			filters.forEach(filter => {
				payload[filter.key] = filter.value
			})

		if(payload?.model === 'documents')
			payload.model = null

		if(!payload?.category && existsInPayload('sub_category'))
			delete payload.sub_category

		if(payload?.is_invoice !== 1) {
			delete payload.invoice_date
			delete payload.invoice_payment_date
		}

		dispatch('fetchPdf', payload)
	},

	filter({dispatch}) {
		dispatch('searchPdf')
	},

	searchByText({commit, dispatch}, {value}) {
		commit(Types.SEARCH_BY_TEXT, value)

		if(searchByTextTimer !== null) {
			clearTimeout(searchByTextTimer)
		}

		searchByTextTimer = setTimeout(() => {
			dispatch('searchPdf')
		}, 1000)
	},
}, [DocumentsActions({module}), Schema])
