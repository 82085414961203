import * as Types from './../../mutationTypes'
import HasFormMutations from "@/store/base/hasForm/mutations";
import {getValueWithoutReference} from "@/helpers/formatters";

export default Object.assign({}, HasFormMutations, {
  [Types.SET_LOCATIONS](state, data) {
    state.countries = data['countries']
    state.cities = data['cities']
  },

  [Types.FILL_FORM_DATA](state, id) {
    let module = state.countries.find(country => country.id === id)

    if(![null, '', undefined].includes(state.formData.country_id) && state.cities?.[state.formData.country_id])
      module = state.cities[state.formData.country_id].find(city => city.id === id)

    // Fill form data without object references
    if(module)
      Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.ADD_TO_MODEL](state, data) {
    if(data.country_id) {
      if(!state.cities?.[data.country_id])
        state.cities[data.country_id] = []

      state.cities[data.country_id].unshift(data)
      return
    }

    state.countries.unshift(data)
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    if(data.country_id) {
      const index = state.cities[data.country_id].findIndex(city => city.id === data.id)

      if(0 <= index && state.cities[data.country_id][index])
        state.cities[data.country_id][index] = data

      return
    }

    const index = state.countries.findIndex(country => country.id === data.id)

    if(0 <= index && state.countries[index])
      state.countries[index] = data
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    data.forEach(oldModule => {
      if(oldModule.country_id)
        state.cities[oldModule.country_id] = state.cities[oldModule.country_id].filter(city => city.id !== oldModule.id)
      else
        state.countries = state.countries.filter(country => country.id !== oldModule.id)

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: state.moduleTitle + ' "' + oldModule[state.notificationTitle] + '" was deleted.',
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, data) {
    if(data.country_id) {
      state.cities[data.country_id] = state.cities[data.country_id].filter(city => city.id !== data.id)
      return
    }

    state.countries = state.countries.filter(country => country.id !== data.id)
  },

  [Types.EMPTY_FORM_DATA](state) {
    state.formData.id = ''
    state.formData.title = ''
    state.formData.title_lt = ''
  },
})