import BaseMutations from '@/store/base/mutations'
import TimekeepingMutations from '@/store/base/timekeeping/mutations'
import * as Types from '@/store/mutationTypes'
import {emptyValues} from "@/helpers/formatters";
import {has} from "lodash";

const module = 'settingsTimekeepingTemplates'

export default BaseMutations({
  [Types.SET_FORM_OBJECT_DATA](state, {weekday, property, value, formAction}) {
    const formData = formAction === 'assignModel' ? 'assignFormData': 'formData'

    if(!has(state[formData].days, weekday))
      return

    state[formData].days[weekday][property] = value
  },

  [Types.EMPTY_FORM_DATA](state) {
    Object.keys(state.formData).forEach(inputName => {
      if(inputName !== 'days')
        state.formData[inputName] = ''
    })

    state.formData.days = emptyValues(state.formData.days, null)
  },

  [Types.EMPTY_ASSIGN_FORM_DATA](state) {
    Object.keys(state.assignFormData).forEach(inputName => {
      if(inputName !== 'days')
        state.assignFormData[inputName] = ''
    })

    state.assignFormData.days = emptyValues(state.assignFormData.days, null)
  },
}, [TimekeepingMutations({module})])