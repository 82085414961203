import BaseState from '@/store/base/state'
import DetailState from "@/store/base/detail/state";
import DocumentsState from "@/store/base/documents/state";
import HistoriesState from "@/store/base/histories/state";

export default BaseState({
  moduleTitle: 'Task',

  formData: {
    uuid: '',
    model: '',
    model_uuid: '',
    name: '',
    description: '',
    due_date_time: '',
    status: '',
    priority: '',
    assigned_users: '',
    assigned_roles: '',
    documents: [],
  },

  dummyData: {
    uuid: '',
    model: '',
    model_uuid: '',
    name: 'title',
    due_date_time: {key: 'YYYY-MM-DD', second_key: 'HH:mm', type: 'date_time'},
    status: {key: 'key', type: 'random_element', getterNamespace: 'taskManagerTasks/getStatusTypes'},
    priority: {key: 'key', type: 'random_element', getterNamespace: 'taskManagerTasks/getPriorityLevels'},
    assigned_users: {key: 'uuid', type: 'random_element', getterNamespace: 'users/getModule'},
    assigned_roles: {key: 'uuid', type: 'random_element', getterNamespace: 'employeeResponsibilities/getModule'},
    documents: '',
  },

  notificationTitle: 'name',
  primaryKey: 'uuid',

  currentModule: {},
  currentModuleTabs: {
    comments: {title: 'Comments'},
    attachments: {title: 'Attachments'},
    history: {title: 'History'},
  },
  currentModuleTab: 'comments',

  statusTypes: [
    { key: 1, label: 'To do'},
    { key: 2, label: 'In progress'},
    { key: 3, label: 'Pending'},
    { key: 4, label: 'Done'},
    { key: 5, label: 'Cancelled'},
  ],

  priorityLevels: [
    { key: 1, label: 'Low' },
    { key: 2, label: 'Medium' },
    { key: 3, label: 'High' },
  ],

  searchBy: ['name'],
}, [DetailState, DocumentsState, HistoriesState])
