import BaseActions from './../../base/actions'
import api from '@/plugins/axios'
import * as Types from './../../mutationTypes'
import Schema from './schema'

export default BaseActions({module: 'warehouseCategories'}, {
  async fetchModel({commit, getters}, data = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    await api.post('/warehouse-categories/get-by-key', {
      key: 'parent_uuid',
      value: data.value === 'main' ? null : data.value
    }, { trigger404: false })
      .then(res => {
        commit(Types.SET_MODEL, {
          key: data.value,
          list: res.data
        })
      })
  },

  setParentUuid({commit}, uuid) {
    commit(Types.SET_PARENT_UUID, uuid)
  },

  async getCategoryList({ commit }) {
    await api.get('/warehouse-categories/all')
      .then(res => {
          commit(Types.SET_TEMP_MODULE, res.data)
      })
  }
}, [Schema])
