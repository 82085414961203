import BaseMutations from '@/store/base/mutations'
import * as Types from './../../mutationTypes'

export default BaseMutations({
  [Types.FILL_FORM_DATA](state, uuid) {
    let formData = state.module.find(el => el.uuid === uuid)

    state.formData.title_en = formData.title_en
    state.formData.title_lt = formData.title_lt
    state.formData.administrative_employee = formData.administrative_employee
    state.formData.uuid = formData.uuid
  }
})