export default Object.assign({}, {
  serialize({getters, rootGetters}) {
    let { employee_work_trip_uuid } = rootGetters['form/getFormMetaData']
    let { content } = getters['getFormData']

    return {
      employee_work_trip_uuid,
      content,
    }
  }
})
