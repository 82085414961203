import BaseActions from './../../base/actions'
import DetailActions from "@/store/base/detail/actions"
import DocumentsActions from '@/store/base/documents/actions'
import Schema from './schema'
import api from '@/plugins/axios'
import * as Types from './../../mutationTypes'
import {formatModuleUrl, userCan} from "@/helpers/formatters";

const module = 'warehouse'

export default BaseActions({module}, {
  async currentModuleFetched({dispatch}, {uuid}) {
    if(!uuid)
      return

    if(userCan('view-warehouse-documents'))
      dispatch('fetchDocuments', {model: module, modelUuid: uuid})
  },

  async fetchSingleTool({commit}, uuid) {

    await api.post('/warehouse/get-by-key', {
      key: 'uuid',
      value: uuid
    })
      .then(res => {
        commit(Types.ADD_TO_MODEL, res.data[0])
      })
  },

  async assignedModel({dispatch, commit, getters, rootGetters}, {assignedModel, assignedTo}) {
    commit(Types.ASSIGN_WAREHOUSE, assignedTo)

    dispatch('employees/updateCurrentModuleWarehouse', assignedTo, {root: true})

    dispatch('employees/addWarehouseToProfile', assignedTo, {root: true})

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Warehouse '${assignedTo[getters['getNotificationKey']]}' has been assigned to employee '${assignedModel[rootGetters['employees/getNotificationKey']]}'`,
      actions: { close: true }
    }, { root: true })
  },

  async unassignedModel({dispatch, commit, getters}, {unassignedFrom}) {
    commit(Types.UNASSIGN_WAREHOUSE, unassignedFrom)

    dispatch('employees/removeWarehouseFromProfile', unassignedFrom, {root: true})

    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `Warehouse '${unassignedFrom[getters['getNotificationKey']]}' has been unassigned.`,
      actions: { close: true }
    }, { root: true })
  },

  editSingleModel({dispatch, commit, getters, rootGetters}, data) {
    commit(Types.UPDATE_SINGLE_MODEL, data.data)

    if(
      Object.keys(getters).includes('getCurrentModuleUuid') &&
      data?.data?.uuid &&
      data.data.uuid === getters.getCurrentModuleUuid
    ) {

      commit(Types.SET_CURRENT_MODULE, data.data)
    }

    // Check if warehouse was edited in employee inner page
    if(rootGetters['employees/getCurrentModule']?.warehouse)
      dispatch('employees/updateCurrentModuleWarehouse', data.data, {root: true})

    this.dispatch('notification/addNotificationToList', {
      type: 'success',
      message: getters['getModuleTitle'] + ' "' + data.data[getters['getNotificationKey']] + '" was updated.',
      actions: {
        close: true,
      }
    }, { root: true })
  },

  giveTool({dispatch, commit}, {give_uuid, give_to_uuid}) {
    api.post(`/${formatModuleUrl(module)}/give`, {
      give_uuid,
      give_to_uuid,
    }).then(response => {
      if(
        !response?.data?.uuid ||
        !response.data?.is_assigned_responsible
      )
        return new Error()

      commit(Types.TOGGLE_TOOL_RESPONSIBILITY, response.data)

      dispatch('employees/updateCurrentModuleWarehouse', response.data, {root: true})

      dispatch('notification/addNotificationToList', {
        type: 'success',
        message: 'Tool has been successfully given to employee',
        actions: {
          close: true,
        }
      }, { root: true })
    }).catch(() => {
      dispatch('notification/addNotificationToList', {
        type: 'error',
        message: 'Unsuccessfull tool transfer',
        actions: {
          close: true,
        }
      }, { root: true })
    })

    dispatch('warning/closeModal', null, {root: true})
  },

  returnTool({dispatch, commit}, data) {
    api.post(`/${formatModuleUrl(module)}/return`, {
      return_uuid: data.return_uuid,
      return_to_uuid: data.return_to_uuid,
    }).then(response => {
      if(
        !response?.data?.uuid ||
        !!response.data?.is_assigned_responsible
      )
        return new Error()

      commit(Types.TOGGLE_TOOL_RESPONSIBILITY, response.data)

      dispatch('employees/updateCurrentModuleWarehouse', response.data, {root: true})

      dispatch('notification/addNotificationToList', {
        type: 'success',
        message: 'Tool has been successfully returned to the project managers',
        actions: {
          close: true,
        }
      }, { root: true })
    }).catch(() => {
      dispatch('notification/addNotificationToList', {
        type: 'error',
        message: 'Tool return was unsuccessfull',
        actions: {
          close: true,
        }
      }, { root: true })
    })

    dispatch('warning/closeModal', null, {root: true})
  },

  async removeEmployee({commit, dispatch}, project) {
    commit(Types.REMOVE_EMPLOYEE_FROM_TOOL, project)

    dispatch('reFetchCurrentModel', project.uuid)
  },

  async addEmployee({commit}, data) {
    commit(Types.ADD_EMPLOYEE_TO_TOOL, data)
  },

  async assignToolToEmployee({dispatch}, {module_uuid, assign_uuid, re_fetch = false}) {
    await dispatch('form/setFormName', 'warehouseAssign', { root: true })

    await dispatch('form/assignModel', {
      module: 'warehouse',
      submodule: 'employees',
      module_callback: 'employees/assignedTool',
      payload: {
        module_uuid,
        assign_uuid,
      },
    }, { root: true })

    dispatch('form/setFormName', null, { root: true })

    if(re_fetch) {
      dispatch('reFetchCurrentModel', module_uuid)
    }
  },

  reFetchCurrentModel({ commit }, uuid) {
    api.post('/warehouse/get-by-key', {
      key: 'uuid',
      value: uuid
    })
      .then(res => {
        commit(Types.UPDATE_CURRENT_TOOL, res.data[0])
      })
  },

  validateWebsocketResponse({commit, rootGetters, dispatch, getters}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        if(getters['cancelDocumentsWebsocket'](event))
          return

        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)

        // eslint-disable-next-line no-case-declarations
        const currentEmployee = rootGetters['employees/getCurrentModule']

        if(!currentEmployee?.uuid)
          break

        if(event.data?.assigned_to?.uuid && currentEmployee.uuid === event.data.assigned_to.uuid)
          dispatch('employees/updateWarehouseFromProfile', event.data, { root: true })
        else if(!event.data?.assigned_to?.uuid)
          dispatch('employees/removeWarehouseFromProfile', event.data.uuid, { root: true })

        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },
}, [DocumentsActions({module}), DetailActions({module}), Schema])