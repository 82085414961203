import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    showModal: false,
    modalTitle: 'No title',
    action: '',
    actionData: {},
    data: {},
    confirmTitle: null,
    cancelTitle: null,
    bodyType: '', // signature,
    employees: '' //all, project, transport, accommodation
  }),
  getters,
  actions,
  mutations
}
