export default () => ({
  getSingleFormData: state => searchBy => {
    const formDataIndex = state.multipleFormData.findIndex(formData => {
      return Object.keys(searchBy).every(key => formData[key] === searchBy[key])
    })

    return state.multipleFormData[formDataIndex] || undefined
  },

  getFormIdFields: ({formIdFields}) => formIdFields,
})