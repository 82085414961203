import BaseState from '@/store/base/state'
import DocumentsState from "@/store/base/documents/state";

export default BaseState({
    moduleTitle: 'Document sending',

    formData: {
      uuid: '',
    },

    dummyData: {
      uuid: '',
    }
}, [DocumentsState])
