export default {
  serialize({getters}) {
    const {id, title, title_lt, country_id} = getters['getFormData']

    return {
      id,
      title,
      title_lt,
      country_id,
    }
  },

  parse() {
    return null
  }
}