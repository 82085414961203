import DocumentSchema from '@/store/base/documents/schema'

export default Object.assign({}, DocumentSchema, {
  async serialize({getters, dispatch}) {
    let employees = getters['getEmployees']
    let positions = getters['getPositions']
    let selectAll = getters['getSelectedAll']
    let exclude_employees = getters['getExcludeEmployees']
    let exclude_positions = getters['getExcludePositions']
    let toSms = getters['getToSms']
    let toEmail = getters['getToEmail']
    let qualified = getters['getSignatureIsQualified']

    if(selectAll) {
      employees = [];
      positions = [];
    }

		let emails = []
    if(getters['getInputData']('email'))
      await dispatch('serializeEmails', getters['getInputData']('email')).then(serialized => {
        emails = serialized
      })

    return {
      employees: employees.join(','),
      positions: positions.join(','),
      selectAll,
      exclude_employees: exclude_employees.join(','),
      exclude_positions: exclude_positions.join(','),
      toSms,
      toEmail,
      qualified,
      emails,
    }
  },

  serializeEmails(context, emails) {
    return emails?.map(({value}) => value) ?? undefined
  }
})
