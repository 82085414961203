export default {
  serialize({getters, rootGetters}) {
    const payload = Object.assign({}, getters['getFormData'], rootGetters['form/getFormMetaData'])

    return {
      uuid: payload?.uuid,
      model: payload?.model,
      model_uuid: payload?.model_uuid,
      content: payload?.content,
    }
  },
}

