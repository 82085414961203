import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'Employee training',

  formData: {
    uuid: '',
    title: '',
    training_starts_at: '',
    training_ends_at: '',
    duration: '',
    price: '',
    employee_uuid: '',
  },

  dummyData: {
    uuid: '',
    title: 'title',
    training_starts_at: {key: 'YYYY-MM-DD', type: 'date'},
    training_ends_at: {key: 'YYYY-MM-DD', type: 'date'},
    duration: '',
    price: {type: 'integer', from: 50, to: 1500},
    employee_uuid: '',
  },

  notificationTitle: 'title',
  primaryKey: 'uuid',
})