import * as Types from "@/store/mutationTypes";
import {massFillData} from "@/helpers/dummyData";

export default ({module}) => ({
    async validateModuleArchive({getters}, uuid) {
        const module = getters['getModuleByKey']('uuid', uuid)

        if(!module)
            return [`Undefined ${getters['getModuleTitle'].toLowerCase()}.`]

        return []
    },

    async archive({dispatch, getters}, {uuid, title}) {
        const archiveErrors = await dispatch('validateModuleArchive', uuid)

        // Validate errors
        if(archiveErrors.length !== 0) {
            dispatch('warning/showWarningModal', {
                question: `You can't archive "${title}" because of the remaining errors:`,
                description: '',
                list: archiveErrors,
                action: '',
                showConfirmButton: false
            }, {root: true})

            return
        }

        // Show archive form if it has fields
        if(getters['archiveFormHasFields']) {
            dispatch('form/showForm', {
                model: module,
                action: 'archive',
                show: true,
                title: `Archive: ${title}`,
                value: uuid
            }, {root: true})

            return
        }

        // Show alert to archive module
        dispatch('warning/showWarningModal', {
            question: `Are you sure you want to archive ${getters['getModuleTitle'].toLowerCase()}: ${title}?`,
            action: `${module}/archiveModuleAlertProcess`,
            data: {
                value: uuid
            }
        }, {root: true})
    },

    unarchive({dispatch, getters}, {uuid, title}) {
        dispatch('warning/showWarningModal', {
            question: `Are you sure you want to unarchive ${getters['getModuleTitle'].toLowerCase()}: ${title}?`,
            action: `${module}/unarchiveModuleAlertProcess`,
            data: {
                value: uuid
            }
        }, {root: true})
    },

    archiveModule({commit}, data) {
        commit(Types.ARCHIVE_MODULE, data)
    },

    unarchiveModule({commit}, data) {
        commit(Types.UNARCHIVE_MODULE, data)
    },

    async archiveModuleAlertProcess({dispatch}, {value: uuid}) {
        dispatch('form/showForm', {
            model: module,
            action: 'archive',
            show: false,
            title: '',
            value: uuid
        }, {root: true})

        dispatch('form/archive', module, {root: true})
    },

    async unarchiveModuleAlertProcess({dispatch}, {value: uuid}) {
        dispatch('form/showForm', {
            model: module,
            action: 'unarchive',
            show: false,
            title: '',
            value: uuid
        }, {root: true})

        dispatch('form/unarchive', module, {root: true})
    },

    fillArchiveFormData({commit}, data) {
        commit(Types.FILL_ARCHIVE_FORM_DATA, data)
    },

    setArchiveFormInputData({commit}, data) {
        commit(Types.SET_ARCHIVE_FORM_INPUT_DATA, data)
    },

    emptyArchiveFormData({commit}) {
        commit(Types.EMPTY_ARCHIVE_FORM_DATA)
    },

    fillArchiveDummyData({getters, commit}) {
        commit(Types.SET_ARCHIVE_FORM_DATA, massFillData(getters['getArchiveDummyData'], getters['getArchiveFormData']))
    },

    filter({commit, getters}) {
        commit(Types.SET_ARCHIVE_FILTER_STATUS, !!getters['getFilterData']('is_archived'))

        commit(Types.FILTER_MODULE, {
            searchBy: 'searchBy',
            rootModule: `module.${getters['filteredByArchiveProjects'] ? 'archived' : 'active'}`
        })
    },

    searchByText({commit, getters}, {value, searchBy, rootModule}) {
        commit(Types.SEARCH_BY_TEXT, value)
        commit(Types.FILTER_MODULE, {
            searchBy: searchBy || 'searchBy',
            rootModule: rootModule || `module.${getters['filteredByArchiveProjects'] ? 'archived' : 'active'}`
        })
    },
})