import DocumentSchema from '@/store/base/documents/schema'

export default Object.assign({}, DocumentSchema, {
  serialize({getters}) {
    const inputsData = getters['getFormData']

    return {
      uuid: inputsData?.uuid,
      title: inputsData?.title,
      parent_uuid: inputsData?.parent_uuid,
      active: inputsData?.active,
    }
  },
})
