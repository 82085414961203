export default {
    archiveFormData: {
        uuid: '',
    },

    archiveDummyData: {
        uuid: '',
    },

    // Is filtering only archive modules
    filteringArchivedModules: false,
}