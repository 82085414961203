import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'Work trip mail',

  formData: {
    employee_uuid: '',
    employee_work_trip_uuid: '',
    content: '',
  },

  dummyData: {
    employee_uuid: '',
    employee_work_trip_uuid: '',
    content: '',
  },

  staticCrudActionTitle: 'sent',
})
