export default ({model}) => Object.assign({
  getPersonByKey: (state, getters, rootState, rootGetters) => (key, value, inArray = false) => {
    const oppositeModel = model === 'employees' ? 'candidates' : 'employees'

    return getters['getModuleByKey'](key, value, inArray) || rootGetters[`${oppositeModel}/getModuleByKey`](key, value, inArray)
  },
  getFireReasonCategory: ({fireReasonCategories}) => (category) => fireReasonCategories.find(({value}) => value === category),
  getFireReasonCategories: ({fireReasonCategories}) => fireReasonCategories,
  getResidenceTypes: ({residenceTypes}) => residenceTypes,
  getResidenceType: ({residenceTypes}) => slug => residenceTypes.find(residenceType => residenceType.slug === slug)
})
