import * as Types from './../mutationTypes'
import api from '@/plugins/axios'
import {formatModuleUrl} from "@/helpers/formatters";
import {isPlainObject} from "lodash";
import HasFormActions from "@/store/base/hasForm/actions"

export default ({module, indexed}, actions = {}, extensions = []) => Object.assign({}, HasFormActions, {
  async fetchModel({commit, getters, dispatch}, data = []) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', getters['getModuleTitle'])

    let url = formatModuleUrl(module)

    if(typeof data.preloadData !== "undefined" && data.preloadData)
      url = `fetch-data/${url}`

    await api.get(`${url}/all${indexed ? '/indexed' : ''}`).then(async ({data}) => {
      let modules = data || []

      await dispatch('parseModules', modules)
        .then(parsedModules => modules = parsedModules || [])

      commit(Types.SET_MODEL, modules)

      dispatch('fetchedModel')
    })
  },

  /**
   * Callback for whenever module is fetched
   */
  fetchedModel() {},

  emptyMultipleFormData({commit}) {
    commit(Types.EMPTY_MULTIPLE_FORM_DATA)
  },

  addToModel({dispatch, commit, getters}, data) {
    commit(Types.ADD_TO_MODEL, data.data)
    dispatch('updatedModel')

    commit(Types.EMPTY_FORM_DATA)

    const modelNotificationTitle = getters['getNotificationTitle'](data.data)
    const staticActionCRUD = getters['getStaticCrudAction']
    this.dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `${getters['getModuleTitle']} ${modelNotificationTitle ? '"' + modelNotificationTitle + '" ' : ''}was ${staticActionCRUD || 'created'}.`,
      actions: {
        close: true,
      }
    }, { root: true })
  },

  async addSingleModel({dispatch, commit}, data) {
    commit(Types.ADD_TO_MODEL, data.data)
    dispatch('updatedModel')
  },

  async editSingleModel({dispatch, commit, getters}, data) {
    commit(Types.UPDATE_SINGLE_MODEL, data.data)
    dispatch('updatedModel')

    if(
      Object.keys(getters).includes('getCurrentModuleUuid') &&
      data?.data?.uuid &&
      data.data.uuid === getters.getCurrentModuleUuid
    ) {
      let currentModule = data.data

      await dispatch('parseCurrentModule', currentModule).then(response => currentModule = response !== null ? response : currentModule)

      commit(Types.SET_CURRENT_MODULE, currentModule)
    }

    const modelNotificationTitle = getters['getNotificationTitle'](data.data)
    const staticActionCRUD = getters['getStaticCrudAction']

    this.dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `${getters['getModuleTitle']} ${modelNotificationTitle ? '"' + modelNotificationTitle + '" ' : ''}was ${staticActionCRUD || 'updated'}.`,
      actions: {
        close: true,
      }
    }, { root: true })
  },

  async removeSingleModel({dispatch, commit}, data) {
    commit(Types.SINGLE_DELETE_FROM_MODAL, data.data)
    dispatch('updatedModel')
  },

  delete({dispatch, commit}, data) {
    let url = module.split(/(?=[A-Z])/).join('-').toLowerCase();

    api.post(url + '/delete', data)
      .then(res => {
        commit(Types.DELETE_FROM_MODULE, res.data)

        dispatch('updatedModel')

        // Close warning modal after delete
        this.dispatch('warning/closeModal', { root: true })
      })
      .catch(() => {
        this.dispatch('warning/closeModal', { root: true })
      })
  },

  filter({commit}) {
    commit(Types.FILTER_MODULE, {
      searchBy: 'searchBy',
      rootModule: 'module'
    })
  },

  removeEmptyFilters({commit}) {
    commit(Types.REMOVE_EMPTY_FILTERS)
  },

  addFilter({commit}, data) {
    commit(Types.REMOVE_FILTER_MODULE, data)
    commit(Types.ADD_FILTER_MODULE, data)
  },

  addIntervalFilter({commit}, data) {
    commit(Types.REMOVE_FILTER_MODULE, data)
    commit(Types.ADD_INTERVAL_FILTER_MODULE, data)
  },

  removeFilter({commit}, data) {
    commit(Types.REMOVE_FILTER_MODULE, data)
  },

  clearAllFilterBy({commit}) {
    commit(Types.CLEAR_ALL_FILTER_MODULE, [])
  },

  searchByText({commit}, {value, searchBy, rootModule}) {
    commit(Types.SEARCH_BY_TEXT, value)
    commit(Types.FILTER_MODULE, {
      searchBy: searchBy || 'searchBy',
      rootModule: rootModule || 'module'
    })
  },

  clearSearch({commit}) {
    commit(Types.CLEAR_SEARCH)
  },

  clearFilters({dispatch}) {
    dispatch('clearAllFilterBy')
    dispatch('clearSearch')
  },

  clearSearchResults({dispatch}) {
    dispatch('clearFilters')
    dispatch('filter')
  },

  serialize() {
    return null
  },

  parse() {
    return null
  },

  serializeApprove() {
    return null
  },

  serializeRefuse() {
    return null
  },

  async parseModules({dispatch}, modulesToParse) {
    let parsedModules = modulesToParse || null

    if(isPlainObject(parsedModules) && parsedModules.active && parsedModules.archived) {
      parsedModules = [...parsedModules.active, ...parsedModules.archived]
    }

    // Check if parse function exists to avoid useless looping through big amount of data
    let canBeParsed = false
    await dispatch('parse', parsedModules[0] || {})
      .then(parsed => canBeParsed = parsed !== null)

    if(!canBeParsed)
      return modulesToParse

    // Parse array of modules
    if(Array.isArray(parsedModules)) {
      for (const [index, module] of parsedModules.entries()) {
        let parsedModule = module

        await dispatch('parse', module)
          .then(parsed => parsedModule = parsed !== null ? parsed : module)

        parsedModules[index] = parsedModule
      }
    }

    return parsedModules
  },

  unsetModules({commit}) {
    commit(Types.UNSET_MODULES)
  },

  setModules({commit}, modules) {
    commit(Types.SET_MODEL, modules)
  },

  async assignedModel({dispatch, rootGetters}, {assignedTo, assignedModel, module, submodule}) {
    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `${assignedModel && submodule ? assignedModel[rootGetters[`${submodule}/getNotificationKey`]] : 'Module'} was successfully assigned ${assignedTo && module ? 'to ' + assignedTo[rootGetters[`${module}/getNotificationKey`]] : ''}`,
      actions: { close: true }
    }, { root: true })
  },

  async unassignedModel({dispatch, rootGetters}, {unassignedFrom, unassignedModel, module, submodule}) {
    dispatch('notification/addNotificationToList', {
      type: 'success',
      message: `${unassignedModel && submodule ? unassignedModel[rootGetters[`${submodule}/getNotificationKey`]] : 'Module'} was successfully assigned ${unassignedFrom && module ? 'to ' + unassignedFrom[rootGetters[`${module}/getNotificationKey`]] : ''}`,
      actions: { close: true }
    }, { root: true })
  },

  async failedAssignedModel({dispatch}) {
    dispatch('notification/addNotificationToList', {
      type: 'error',
      message: `Failed to assign`,
      actions: { close: true }
    }, { root: true })
  },

  async failedUnassignedModel({dispatch}) {
    dispatch('notification/addNotificationToList', {
      type: 'error',
      message: `Failed to unassign`,
      actions: { close: true }
    }, { root: true })
  },

  // Websocket dedicated
  async updatedSingleModel({dispatch, commit, getters}, data) {
    // Exit if module doesn't have detail page
    if(!Object.keys(getters).includes('getCurrentModule'))
      return

    const primaryKey = getters['getPrimaryKey']

    if(
      primaryKey &&
      data?.[primaryKey] &&
      getters['getCurrentModule']?.[primaryKey] === data?.[primaryKey]
    ) {
      let currentModule = data

      await dispatch('parseCurrentModule', data).then(parsedCurrentModule => currentModule = parsedCurrentModule !== null ? parsedCurrentModule : currentModule)

      commit(Types.SET_CURRENT_MODULE, currentModule)
    }
  },

  async toggleApprove({dispatch, getters}, {approve, data}) {
    let payload = data

    await dispatch(`serialize${approve ? 'Approve' : 'Refuse'}`, payload)
      .then(serialized => payload = serialized || payload)

    api.post(`${formatModuleUrl(module)}/${approve ? 'approve' : 'cancel'}`, payload)
      .then(async response => {
        let data = Array.isArray(response.data) ? response.data[0] : response.data

        await dispatch('parse', data)
          .then(parsedData => data = parsedData || data)

        const notificationTitle = getters['getNotificationTitle'](data)

        if(approve)
          dispatch('approveModel', data)
        else
          dispatch('refuseModel', data)

        const message = `${getters['getModuleTitle']} ${notificationTitle ? `"${notificationTitle}" ` : ''}was ${ getters['getStaticCrudAction'] || (approve ? 'approved' : 'canceled') }.`

        dispatch('notification/addNotificationToList', {
          type: 'success',
          message,
          actions: { close: true }
        }, { root: true })
      })
      .catch(() => {})
  },

  async approve({dispatch}, data) {
    await dispatch('toggleApprove', {approve: true, data})
  },

  async refuse({dispatch}, data) {
    await dispatch('toggleApprove', {approve: false, data})
  },

  async approveModel({commit}, data) {
    commit(Types.APPROVE_MODEL, data)
  },

  async refuseModel({commit}, data) {
    commit(Types.REFUSE_MODEL, data)
  },

  updating({commit}, {uuid, property}) {
    commit(Types.UPDATING_MODEL, {uuid, property})
  },

  updated({commit}, {uuid, property}) {
    commit(Types.UPDATED_MODEL, {uuid, property})
  },

  /**
   * Called whenever state.module was created, updated, deleted. Including websockets.
   */
  updatedModel() {},

  validateWebsocketResponse({commit}, {type, event}) {
    switch(type) {
      case 'ModelCreateEvent':
        commit(Types.ADD_TO_MODEL, event.data)
        break

      case 'ModelUpdateEvent':
        commit(Types.UPDATE_SINGLE_MODEL, event.data)
        break

      case 'ModelDeleteEvent':
        commit(Types.SINGLE_DELETE_FROM_MODAL, event.data)
        break

      case 'ModelArchiveEvent':
        commit(event.status === 'restored' ? Types.SINGLE_UNARCHIVE_MODEL : Types.SINGLE_ARCHIVE_MODEL, event.data)
        break
    }
  },

  check({commit}, {values, append = true}) {
    commit(Types.CHECK_MODULE, {values, append})
  },

  uncheck({commit}, primaryKeyValue) {
    commit(Types.UNCHECK_MODULE, primaryKeyValue)
  },

  resetChecked({commit}) {
    commit(Types.RESET_CHECKED)
  },
}, ...extensions, actions)
