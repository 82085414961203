import api from '~/plugins/axios';
import * as Types from './../../mutationTypes'
import Cookies from 'js-cookie'
/* eslint-disable */

export default {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.post('/login', {
        email: data.email,
        password: data.password,
        remember: !!data?.remember,
      })
        .then( async res => {
          Cookies.set('access_token', res.data.token, {expires: 1})
          commit(Types.SET_TOKEN, res.data.token)
          resolve(true)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  setToken({ commit }) {
    commit(Types.SET_TOKEN, Cookies.get('access_token') || null)
  },

  getAuthenticatedUser({ commit, getters }) {
    if(!getters['getToken']) return null

    return new Promise((resolve, reject) => {
      if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> FETCH_MODEL: ', 'color: #c5b413', 'Auth user')

      api.get('/user')
        .then(res => {
          commit(Types.SET_AUTH_USER, res.data)
          resolve(res.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  logOut({commit}) {
    return new Promise((resolve, reject) => {
      api.post('/logout')
      .then(() => {
        Cookies.remove('access_token')
        commit(Types.EMPTY_AUTH_USER)
        location.reload()
        resolve(true)
      })
      .catch(() => {
        Cookies.remove('access_token')
        commit(Types.EMPTY_AUTH_USER)
        location.reload()
        reject(false)
      })
    })
  },

  async fetchAlerts({getters, commit}) {
    let alerts = null

    if(getters['getUuid']) {
      await api.post('users/alerts/all', {
        user_uuid: getters['getUuid']
      }).then(response => {
        alerts = response.data
      }).catch(() => {})
    }

    if(!Array.isArray(alerts))
      alerts = []

    commit(Types.SET_ALERTS, alerts)
  },

  async clientWebsocket({rootGetters, dispatch}, {type, event}) {
    const currentProject = rootGetters['projects/getCurrentModule']

    if(type === 'ModelUpdateEvent' && ['plans', 'projectWeeks', 'projectMonths'].includes(event.model)) {
      dispatch(`${event.model}/editSingleModel`, event, { root: true })
      return
    }

    if(!currentProject?.uuid)
      return

    switch (event.model) {
      case 'employees':
        if(type !== 'ModelUpdateEvent')
          return

        const employeesFetched = rootGetters['projects/getEmployeesFetched']

        if(employeesFetched && currentProject.uuid === event.data.object_uuid)
          dispatch(`projects/assignedEmployee`, {assignedModel: currentProject, assignedTo: event.data, triggerNotification: false}, { root: true })
        else if(employeesFetched)
          dispatch('projects/unassignedEmployee', {unassignedModel: currentProject, unassignedFrom: event.data, triggerNotification: false}, { root: true })

        dispatch('employees/editSingleModel', event, { root: true })
        break

      case 'projects':
        if(
          type === 'ModelUpdateEvent' &&
          currentProject.uuid === event.data.uuid
        )
          dispatch('projects/editSingleModel', event, { root: true })
        break

      case 'transports':
        if(type !== 'ModelUpdateEvent' || !rootGetters['transports/isFetched'] || !rootGetters['employees/isFetched'])
          return

        const transportExists = !!rootGetters['transports/getModule'].find(transport => transport.uuid === event.data.uuid)
        const employeesUsingTransport = rootGetters['employees/getModule'].filter(employee => employee.transport_uuid === event.data.uuid)

        if(transportExists && !employeesUsingTransport.length)
          dispatch('transports/removeSingleModel', event, { root: true })
        else if(!transportExists)
          dispatch('transports/addSingleModel', event, { root: true })
        else
          dispatch('transports/editSingleModel', event, { root: true })
    }
  },

  updateAuthUserPermissions({commit}, permissions) {
    commit(Types.UPDATE_AUTH_USER_PERMISSIONS, permissions)
  },

  updateAuthUser({commit}, user) {
    commit(Types.UPDATE_AUTH_USER, user)
  },
}