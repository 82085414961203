import TimekeepingSchema from "@/store/base/timekeeping/schema";

export default Object.assign({}, TimekeepingSchema, {
  async serialize({dispatch, getters}) {
    const formData = getters['getFormData']

    let days
    await dispatch('serializeDays')
      .then(serializedDays => days = serializedDays || formData.days)

    return {
      uuid: formData?.uuid || undefined,
      title: formData.title,
      days,
    }
  },
})