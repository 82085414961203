import BaseGetters from "@/store/base/getters";
import DocumentsGetters from "@/store/base/documents/getters";
import ArchivesGetters from "@/store/base/archives/getters";
import DetailGetters from "@/store/base/detail/getters";
import HistoriesGetters from "@/store/base/histories/getters";

export default BaseGetters({
    getBodyTypes: state => state.bodyTypes,
    getBodyType: state => type => state.bodyTypes.find(bodyType => bodyType.slug === type)?.name || '',
    getOwnershipTypes: state => state.ownershipTypes,
    getOwnershipType: state => type => state.ownershipTypes.find(ownershipType => ownershipType.slug === type)?.name || '',
    getCurrentModuleEmployees: state => state.currentModule?.employees ?? [],
}, [ArchivesGetters, DocumentsGetters, DetailGetters, HistoriesGetters])
