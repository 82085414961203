import BaseGetters from '@/store/base/getters'
import MultiFormGetters from '@/store/base/multipleFormData/getters'
// import {merge} from "lodash";

export default BaseGetters({
  getModuleArray: (state) => {
    let models = []

    Object.values(state.module).forEach((yearGroup) => {
      Object.values(yearGroup).forEach((monthGroup) => {
        models = models.concat(Object.values(monthGroup))
      })
    })

    return models
  },

  getProjectMonth: (state, getters) => (year, month, project, withMultipleFormData = true) => {
    if(withMultipleFormData) {
      const singleFormData = getters['getSingleFormData']({
        project_uuid: project.uuid,
        year,
        month,
      })

      if(singleFormData)
        return singleFormData
    }

    return state.module?.[year]?.[month]?.[project.uuid]
  },
  getProjectMonthWeek: (state, getters) => (year, month, week, project) => {
      const model = getters['getProjectMonth'](year, month, project)

      return model?.weeks?.find(monthWeek => monthWeek.week === week.week)
  },

  getProjectPastMonths: (state) => (project, year, month) => {
    let models = []

    Object.keys(state.module).forEach((yearKey) => {
      if(yearKey < year)
        Object.keys(state.module[yearKey]).forEach((monthKey) => {
          if(state.module[yearKey][monthKey]?.[project.uuid])
            models.push(state.module[yearKey][monthKey][project.uuid])
        })
      else if(yearKey === year)
        Object.keys(state.module[yearKey]).forEach((monthKey) => {
          if(month <= monthKey && state.module[yearKey][monthKey]?.[project.uuid])
            models.push(state.module[yearKey][monthKey][project.uuid])
        })
    })

    return models
  },

  getModuleByKeysValues: (state, getters) => (keysValues) => {
    return getters['getModuleArray'].filter(model => {
      return Object.keys(keysValues).every((key) => model[key] === keysValues[key])
    })
  },
  getByPrimaryKey: (state, getters) => searchable => {
    let primaryKey = getters['getPrimaryKey']

    return getters['getModuleArray'].find(model => model[primaryKey] === searchable[primaryKey])
  },
}, [MultiFormGetters()])
