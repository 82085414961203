export default {
  showModal: state => state.showModal,
  getModalTitle: state => state.modalTitle,
  getModalQuestion: state => state.modalQuestion,
  getModalDescription: state => state.modalDescription,
  getModalDescriptionList: state => state.modalDescriptionList,
  getAction: state => state.action,
  getData: state => state.data,
  modalIsLoading: state => state.loading,
  getCancelTitle: state => state.cancelTitle,
  getConfirmTitle: state => state.confirmTitle,
}