// import api from '~/plugins/axios';
import * as Types from './../../../mutationTypes'


export default {
  showModal({commit}, data) {
    commit(Types.SHOW_FILTER_MODAL, data)
  },

  hideModal({commit}) {
    commit(Types.HIDE_FILTER_MODAL)
  },

  setFilterOptions({commit}, data) {
    commit(Types.SET_FILTER_OPTIONS, data)
  }
}