import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  if(routeFrom.name && routeTo.query?.wentBack !== 'true')
    store.dispatch('history/pushPage', routeFrom)

  const authRequired = routeTo.meta.authRequired

  // if axios after error redirect with status code 401 directly move to next page without data fetching
  if(routeTo.params.statusCode === '401')
    next()

  // Fetch important data from backend after page reload or first page open
  if(!store.getters['auth/authUser'])
    await store.dispatch('initData')

  // check if user is loggedIn
  const loggedIn = store.getters['auth/loggedIn'];

  // if user is loggedIn do not allow go to /login or /forgot-password pages
  if((routeTo.name === 'login' || routeTo.name === 'forgot password') && loggedIn) {
      next({name: 'dashboard'})
  }

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    if(!store.getters['auth/authUserHasPermission'](routeTo.meta.role) && authRequired) {
      alert('You do not have permission to view this page')
      next({name: 'dashboard'})
      return
    }
    next();
  }
})

router.afterEach(async (routeTo) => {
  const authRequired = routeTo.meta.authRequired
  const loggedIn = store.getters['auth/loggedIn'];
  const dataIsFetched = store.getters['dataIsFetched']

  if(loggedIn && !dataIsFetched && authRequired) {
    await store.dispatch('fetchImportantData')
  }
})
export default router
