import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'User',

  formData: {
    uuid: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: '',
    type: '',
    role: '',
    client: '',
    phone_number: '',
    project_uuid: '',
    employee_uuid: '',
  },

  dummyData: {
    uuid: '',
    first_name: 'first_name',
    last_name: 'last_name',
    email: 'email',
    password: '',
    password_confirm: '',
    type: '',
    role: '',
    client: 'company_name',
    phone_number: 'phone',
    project_uuid: '',
    employee_uuid: '',
  },

  notificationTitle: 'first_name',
  primaryKey: 'uuid',
})