import HasFormState from '@/store/base/hasForm/state'

export default (state = {}, extensions = []) => Object.assign({}, HasFormState, {
  module: [],

  filtersBy: [],
  searchBy: [],
  searchValue: '',
  showSearchResults: false,
  moduleSearchResults: [],

  multipleFormData: [],

  moduleTitle: 'No title',
  notificationTitle: 'title',
  moduleKey: 'uuid',
  primaryKey: 'uuid',
  isFetched: false,

  // Change action inside notification message (created, updated, deleted) to static.
  staticCrudActionTitle: undefined,

  updating: [],

  // Object keys should be module primary key value with true value assigned to it
  checked: {},
}, ...extensions, state)