import * as Types from '@/store/mutationTypes'

export default () => ({
  [Types.SET_MULTIPLE_FORM_DATA](state, {searchBy, module, presetModule, key, value}) {
    let formDataIndex = state.multipleFormData.findIndex(formData => {
      return Object.keys(searchBy).every(key => formData[key] === searchBy[key])
    })

    if(formDataIndex < 0) {
      const data = Object.assign({}, module || state.formData, presetModule)

      const singleFormData = {is_updating: false}
      Object.keys(state.formData).forEach(inputKey => singleFormData[inputKey] = data[inputKey])

      singleFormData[key] = value

      state.multipleFormData.push(singleFormData)
      return
    }

    state.multipleFormData[formDataIndex][key] = value || null
  },

  [Types.SET_MULTIPLE_FORM_DATA_UPDATING_STATUS](state, {searchBy, updating}) {
    const formDataIndex = state.multipleFormData.findIndex(formData => {
      return Object.keys(searchBy).every(key => formData[key] === searchBy[key])
    })

    if(state.multipleFormData[formDataIndex])
      state.multipleFormData[formDataIndex].is_updating = !!updating
  },

  [Types.REMOVE_SINGLE_MULTIPLE_FORM_DATA](state, data) {
    const formDataIndex = state.multipleFormData.findIndex(formData => {
      return state.formIdFields.every(key => formData[key] === data[key])
    })

    if(state.multipleFormData[formDataIndex])
      state.multipleFormData.splice(formDataIndex, 1)
  },

  [Types.EMPTY_MULTIPLE_FORM_DATA](state) {
    state.multipleFormData = []
  },
})