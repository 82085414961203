import BaseState from '@/store/base/state'
import MultipleFormState from '@/store/base/multipleFormData/state'

export default BaseState({
  moduleTitle: 'Plan',

  module: {},

  formData: {
    uuid: '',
    project_uuid: '',
    year: '',
    month: '',
    hours: '',
    work_performed: '',
    hourly_rate: '',
    revised_hours: '',
    revised_work_performed: '',
    revised_hourly_rate: '',
  },

  dummyData: {
    uuid: '',
    project_uuid: {key: 'uuid', type: 'random_element', getterNamespace: 'projects/getModule'},
    year: {type: 'integer', from: 1970, to: 2040},
    month: {type: 'integer', from: 1, to: 12},
    hours: {type: 'integer', from: 8, to: 80},
    work_performed: {type: 'integer', from: 80, to: 4400},
    hourly_rate: {type: 'integer', from: 10, to: 55},
    revised_hours: {type: 'integer', from: 8, to: 80},
    revised_work_performed: {type: 'integer', from: 80, to: 4400},
    revised_hourly_rate: {type: 'integer', from: 10, to: 55},
  },

  notificationTitle: 'date',
  primaryKey: 'uuid',

  staticCrudActionTitle: 'updated',

  searchBy: [],

  formIdFields: ['project_uuid', 'year', 'month'],
}, [MultipleFormState])