import BaseMutations from '@/store/base/mutations'
// import * as Types from './../../mutationTypes'

export default BaseMutations({
  // [Types.FILL_FORM_DATA](state, uuid) {
  //   let formData = state.module.find(el => el.uuid === uuid)
  //
  //   state.formData.title = formData.title
  //   state.formData.administrative_employee = formData.administrative_employee
  //   state.formData.uuid = formData.uuid
  // }
})