import BaseState from '@/store/base/state'

export default BaseState({
  moduleTitle: 'Employee schedule',

  module: {},

  formData: {
    uuid: '',
    employee_uuid: '',
    year: '',
    month: '',
    determined_work_days: '',
    determined_work_hours: '',
    actual_days: '',
    actual_hours: '',
    actual_night_hours: '',
    actual_overtime: '',
    actual_deviations: '',
    actual_duty_on_home: '',
    actual_duty_on_work: '',
    actual_rest_days: '',
    actual_holidays: '',
    absences_days: '',
    absences_hours: '',
    approve: '',
  },

  updatingSchedules: {},

  primaryKey: 'uuid',
  notificationTitle: '{employee_full_name} {date}',

  searchBy: [],
  staticCrudActionTitle: 'updated',
})
