export default {
  serialize({getters}) {
    const formData = getters['getFormData']

    return {
      active: !!formData.active,
      parent_uuid: formData.parent_uuid,
      title: formData.title,
      uuid: formData.uuid,
    }
  },
}