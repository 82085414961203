import * as Types from './../../mutationTypes'

export default {
  [Types.ACTION_MODAL_IS_LOADING](state) {
    state.loading = true
  },

  [Types.SET_ACTION_MODAL_DATA](state, data) {
    state.showModal = true
    state.modalTitle = data.title || 'No action'
    state.action = data.action
    state.actionData = data.actionData
    state.bodyType = data.bodyType
    state.employees = data.employees
  },

  [Types.CLOSE_ACTION_MODAL](state) {
    state.loading = false
    state.showModal = false
    state.modalTitle = 'No action'
    state.action = ''
    state.actionData = {}
    state.data = {}
    state.confirmTitle = null
    state.cancelTitle = null
    state.bodyType = ''
    state.employees = ''
  },

  [Types.ACTION_MODAL_INIT_DATA](state, data) {
    state.data = data
  },

  [Types.ACTION_SET_DATA_VALUE](state, data) {
    if(data.value === null)
      data.value = false

    state.data[data.key] = data.value
  }
}