import api from "@/plugins/axios";
import * as Types from "@/store/mutationTypes";

export default {
  async init({ commit, getters }, names = ['stats', 'employees', 'revenue']) {
    if (process.env.VUE_APP_DEBUG_MODE === 'true')
      console.log('%c>>> INITIATING: ', 'color: #c5b413', 'dashboard')

    await Promise
      .all(names
        .map(model => {
          if(model === 'revenue')
            return api.post(`/dashboard/${model}`, {
              categories: getters['getRevenueFilter']('categories'),
              countries: getters['getRevenueFilter']('countries'),
            }).then(res => commit(Types.SET_STATE_DATA, { name: model, data: res.data }))

          return api.get('/dashboard/' + model)
              .then(res => commit(Types.SET_STATE_DATA, { name: model, data: res.data }))
        })
      ).then(() => {
        commit(Types.DASHBOARD_INITIATED, true)

        if (process.env.VUE_APP_DEBUG_MODE === 'true')
          console.log('%c>>> DASHBOARD: ', 'color: #c5b413', 'initiated')
      })
  },

  searchByText({ commit }, { value }) {
    commit(Types.SEARCH_BY_TEXT, value)
  },

  clearSearchResults({ commit }) {
    commit(Types.CLEAR_SEARCH)
  },

  async setLoadingState({ commit }, state) {
    return await new Promise(resolve => {
      commit(Types.SET_LOADING_STATE, state)
      resolve()
    })
  },

  async setRevenueFilter({ dispatch, commit }, state) {
    return await new Promise(resolve => {
      commit(Types.REVENUE_ADD_FILTER, state)
      resolve()
    }).then(() => dispatch('init', ['revenue']))
  }
}