import BaseGetters from '@/store/base/getters'
import PersonGetters from '@/store/base/person/getters'
import DetailGetters from "@/store/base/detail/getters";
import DocumentsGetters from '@/store/base/documents/getters'
import BlacklistGetters from '@/store/base/blacklist/getters'

const model = 'candidates'

export default BaseGetters({
  getActiveModule: (state, getters) => getters['getModule'].filter(module => !module.is_blacklisted),
  getFiredStatisticsCount: ({fired_statistics}) => fired_statistics.count,
  getFiredStatisticsPeriod: ({fired_statistics}) => fired_statistics.period,
}, [PersonGetters({model}), DocumentsGetters, BlacklistGetters, DetailGetters])
