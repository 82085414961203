import BaseMutations from '@/store/base/mutations'
import * as Types from './../../mutationTypes'

export default BaseMutations({
  [Types.SET_MODEL](state, data) {
    state.module[data.key] = data.list
    state.fetchedCounts = state.fetchedCounts + 1
  },

  [Types.SET_PARENT_UUID](state, uuid) {
    state.formData.parent_uuid = uuid
  },

  [Types.ADD_TO_MODEL](state, model) {
    let index = 'main'

    if(model.parent_uuid !== null)
      index = model.parent_uuid

    if(state.module[index]) {
      state.module[index].unshift(model)
      state.fetchedCounts = state.fetchedCounts + 1
    }

    // Prefetched project categories
    state.prefetchedCategories.unshift(model)
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    let index = 'main'

    if(data.parent_uuid !== null)
      index = data.parent_uuid

    let index2 = state.module[index]?.findIndex(el => el[state.primaryKey] === data[state.primaryKey])

    if(state.module[index] && state.module[index][index2]) {
      state.module[index][index2] = data
      state.fetchedCounts = state.fetchedCounts + 1
    }

    // Prefetched project categories
    const fullListIndex = state.prefetchedCategories.findIndex(category => category[state.primaryKey] === data[state.primaryKey])
    if(!isNaN(fullListIndex))
      state.prefetchedCategories[fullListIndex] = data
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let index = 'main'

    if(state.formData.parent_uuid !== null && state.formData.parent_uuid !== '')
      index = state.formData.parent_uuid

    let module = state.module[index].find(el => el.uuid === uuid)

    state.formData.uuid = module.uuid
    state.formData.title = module.title
    state.formData.parent_uuid = module.parent_uuid
    state.formData.active = module.active
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    let index = 'main'

    data.forEach(oldModule => {
      if(oldModule.parent_uuid !== null)
        index = oldModule.parent_uuid

      state.module[index] = state.module[index].filter(mod => mod[state.primaryKey] !== oldModule[state.primaryKey])

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: state.moduleTitle + ' "' + oldModule[state.notificationTitle] + '" was deleted.',
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.EMPTY_FORM_DATA](state) {
    state.formData.uuid = ''
    state.formData.title = ''
    state.formData.active = false
  },

  [Types.SET_FORM_INPUT_DATA](state, data) {
    state.formData[data.key] = data.value
  },

  [Types.SET_CATEGORIES](state, data) {
    state.prefetchedCategories = data
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, data) {
    const index = data.parent_uuid !== null ? data.parent_uuid : 'main'

    if(state.module[index])
      state.module[index] = state.module[index].filter(category => category[state.primaryKey] !== data[state.primaryKey])

    // Prefetched project categories
    state.prefetchedCategories = state.prefetchedCategories.filter(category => category[state.primaryKey] !== data[state.primaryKey])
  },
})