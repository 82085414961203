import BaseState from '@/store/base/state'

export default BaseState({
    moduleTitle: 'Certificate',

    formData: {
      uuid: '',
      title: '',
      description: ''
    },

    dummyData: {
      uuid: '',
      title: 'title',
      description: 'description',
    }
})