export default {
  serialize(context, payload) {
    return {
      uuid: payload?.uuid,
      employee_uuid: payload?.employee_uuid,
      comment: payload?.comment,
      category: payload?.category,
      is_educational: !!payload?.is_educational,
      dismiss_date: payload?.dismiss_date,
    }
  },
}