import * as Types from '@/store/mutationTypes'

export default () => ({
  [Types.SET_HISTORIES](state, {data, append}) {
    state.isHistoriesFetched = true

    if(append) {
      state.histories = state.histories.concat(...(data || []))

      return
    }

    state.histories = data || []
  },

  [Types.SET_IS_FETCHING_HISTORIES](state, status) {
    state.isFetchingHistories = !!status
  },

  [Types.SET_HISTORIES_LOADED_LENGTH](state, length) {
    state.historiesLoadedLength = length
  },

  [Types.CLEAR_HISTORIES](state) {
    state.isHistoriesFetched = false
    state.historiesLoadedLength = null
    state.histories = []
  },

  [Types.PUSH_HISTORY](state, data) {
    if(Array.isArray(state?.histories))
      state.histories.unshift(data)
  },
})
