import store from '@/store'
import Echo from 'laravel-echo'
import * as Types from '@/store/mutationTypes'
import {isClient} from "@/helpers/formatters";

window.Pusher = require('pusher-js');

const BACKGROUND_PROCESS_MODULES = ['projectMonths', 'timekeepingDays', 'employeeSchedules', 'employeeRotations', 'documents']

const checkBackgroundProcessedModules = (event, currentUserUuid) => BACKGROUND_PROCESS_MODULES.includes(event.model) && !event.isDirectRequest && event.user !== currentUserUuid

export default class Websocket {
  static init(userUuid) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      wsHost: process.env.VUE_APP_PUSHER_APP_HOST,
      wsPort: process.env.VUE_APP_PUSHER_APP_PORT,
      forceTLS: process.env.VUE_APP_PUSHER_TLS === 'true',
      disableStats: true,
      namespace: "Domain.Events",
      authEndpoint: '/broadcasting/auth',
      auth: {
        headers: {
          Authorization: `Bearer ${store.getters['auth/getToken']}`
        }
      },
    });

    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> WEBSOCKET: ', 'color: #13c5b1', 'ON')

    this.listeningUser(userUuid)
    this.listeningGlobal(userUuid)
  }

  static listeningUser(uuid) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> WEBSOCKET: ', 'color: #13c5b1', 'Listening private')

    window.Echo.private('user.' + uuid)
      .listen('User.UserTestEvent', event => {
        console.log('Test WS private channel', event)
      })
      .listen('Alert.AlertStatusChangeEvent', event => {
        console.log('Alert.AlertStatusChangeEvent', event)
        switch (event.status) {
          case 'created':
            store.commit(`auth/${Types.ADD_ALERT}`, event.data)
            break;

          case 'dissmised':
            store.commit(`auth/${Types.DISMISS_ALERT}`, event.data)
            break;

          case 'deleted':
            store.commit(`auth/${Types.REMOVE_ALERT}`, event.data)
        }
      })
      .listen('User.UserPermissionsUpdatedEvent', event => {
        store.dispatch('auth/updateAuthUserPermissions', event.data)
      })
      .listen('User.UserUpdatedEvent', event => {
        store.dispatch('auth/updateAuthUser', event.data)
      })
  }

  static listeningGlobal(uuid) {
    if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('%c>>> WEBSOCKET: ', 'color: #13c5b1', 'Listening global')

    window.Echo.channel('global-channel')
      .listen('Global.ModelCreateEvent', event => {
        if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('Global.ModelCreateEvent', event)

        if(event.user?.type !== 'client' && event.model === 'automaticDocuments|documents') {
          store.dispatch('automaticDocuments/validateWebsocketResponse', {type: 'ModelCreateEvent', event})
        } else if(event.model.endsWith('|currentModule|histories')) {
          store.dispatch(`${event.model.split('|').at(0)}/pushHistory`, event.data)
        } else if(event.model === 'massSignature') {
          store.dispatch('signature/addSingleDocToList', event.data)
        } else if(event.user.uuid !== uuid && event.user?.type !== 'client' || checkBackgroundProcessedModules(event, uuid)) {
          const module = event.model.split('|')

          if(1 < module.length)
            event.data['stateVariable'] = module[1]

          store.dispatch(`${module[0]}/validateWebsocketResponse`, {type: 'ModelCreateEvent', event})
        }
      })
      .listen('Global.ModelUpdateEvent', event => {
        if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('Global.ModelUpdateEvent', event)

        if(event.user?.type !== 'client' && event.model === 'automaticDocuments|documents') {
          store.dispatch('automaticDocuments/validateWebsocketResponse', {type: 'ModelUpdateEvent', event})
        } else if((event.user.uuid !== uuid || event.model.includes('|')) || event?.updatedRelationship || checkBackgroundProcessedModules(event, uuid)) {
          const module = event.model.split('|')

          if(1 < module.length)
            event.data['stateVariable'] = module[1]

          if(!isClient()) {
            store.dispatch(`${module[0]}/validateWebsocketResponse`, {type: 'ModelUpdateEvent', event})
            store.dispatch(`${module[0]}/updatedSingleModel`, event.data, { root: true })
          }else if(isClient() && ['employees', 'projects', 'transports', 'plans', 'projectWeeks', 'projectMonths'].includes(event.model))
            store.dispatch('auth/clientWebsocket', {type: 'ModelUpdateEvent', event})
        }
      })
      .listen('Global.ModelArchiveEvent', event => {
        if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('Global.ModelArchiveEvent', event)

        if(event.user.uuid !== uuid && event.user?.type !== 'client') {
          const module = event.model.split('|')

          if(1 < module.length)
            event.data['stateVariable'] = module[1]

        store.dispatch(`${module[0]}/validateWebsocketResponse`, {type: 'ModelArchiveEvent', event})
        }
      })
      .listen('Global.ModelDeleteEvent', event => {
        if(process.env.VUE_APP_DEBUG_MODE === 'true') console.log('Global.ModelDeleteEvent', event)

        if(event.user?.type !== 'client' && event.model === 'automaticDocuments|documents') {
          store.dispatch('automaticDocuments/validateWebsocketResponse', {type: 'ModelDeleteEvent', event})
        } else if(event.user.uuid !== uuid || !event?.isDirectRequest) {
          const module = event.model.split('|')

          if(1 < module.length)
            event.data['stateVariable'] = module[1]

          if(!isClient())
            store.dispatch(`${module[0]}/validateWebsocketResponse`, {type: 'ModelDeleteEvent', event})
          else if(isClient() && ['employees', 'projects', 'transports'].includes(event.model))
            store.dispatch('auth/clientWebsocket', {type: 'ModelDeleteEvent', event})
        }
      })
  }
}
