import * as Types from '@/store/mutationTypes'
import { massFillData } from "@/helpers/dummyData";

export default {
  setFormInputData({commit}, data) {
    commit(Types.SET_FORM_INPUT_DATA, data)
  },

  appendFormInputData({commit}, data) {
    commit(Types.SET_ARRAY_FORM_INPUT_DATA, data)
  },

  editAppendedFormInputData({commit}, data) {
    commit(Types.EDIT_ARRAY_FORM_INPUT_DATA, data)
  },

  removeAppendedFormInputData({commit}, data) {
    commit(Types.REMOVE_ARRAY_FORM_INPUT_DATA, data)
  },

  fillDummyData({getters, commit}) {
    commit(Types.SET_FORM_DATA, massFillData(getters['getDummyData'], getters['getFormData']))
  },

  fillFormData({commit}, data) {
    commit(Types.FILL_FORM_DATA, data)
  },

  emptyFormData({commit}) {
    commit(Types.EMPTY_FORM_DATA)
  },

  setAssignFormInputData({commit}, data) {
    commit(Types.SET_ASSIGN_FORM_INPUT_DATA, data)
  },

  setUnassignFormInputData({commit}, data) {
    commit(Types.SET_UNASSIGN_FORM_INPUT_DATA, data)
  },

  fillAssignFormData({commit}, data) {
    commit(Types.FILL_ASSIGN_FORM_DATA, data)
  },

  fillUnassignFormData({commit}, data) {
    commit(Types.FILL_UNASSIGN_FORM_DATA, data)
  },

  emptyAssignFormData({commit}) {
    commit(Types.EMPTY_ASSIGN_FORM_DATA)
  },

  emptyUnassignFormData({commit}) {
    commit(Types.EMPTY_UNASSIGN_FORM_DATA)
  },

  setGenerateDocumentsFormInputData({commit}, data) {
    commit(Types.SET_GENERATE_DOCUMENTS_FORM_INPUT_DATA, data)
  },

  fillGenerateDocumentsFormData({commit}, data) {
    commit(Types.FILL_GENERATE_DOCUMENTS_FORM_DATA, data)
  },

  emptyGenerateDocumentsFormData({commit}) {
    commit(Types.EMPTY_GENERATE_DOCUMENTS_FORM_DATA)
  },
}