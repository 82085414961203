export default {
  stats: {},
  revenue: {},
  employees: {},
  // loading states
  loaders: {
    // global
    init: true,
    // independent
    dashboard: false,
    revenue: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    employees: false,
  },
  // employees birthdays table
  searchBy: ['full_name'],
  searchValue: '',
  // revenue graph
  filters: {
    categories: [],
    countries: []
  },
}