import {isObject, values} from "lodash";
import * as Types from "@/store/mutationTypes";
import BaseMutations from '@/store/base/mutations'
import MultiFormMutations from '@/store/base/multipleFormData/mutations'
import {getValueWithoutReference} from "@/helpers/formatters";

// const module = 'rotationDays'

export default BaseMutations({
  [Types.ADD_TO_MODEL](state, data) {
    if(!isObject(state.module?.[data.employee_uuid]))
      state.module[data.employee_uuid] = {}

    if(!isObject(state.module[data.employee_uuid]?.[data.year]))
      state.module[data.employee_uuid][data.year] = {}

    if(!isObject(state.module[data.employee_uuid][data.year]?.[data.month]))
      state.module[data.employee_uuid][data.year][data.month] = {}

    state.module[data.employee_uuid][data.year][data.month][data.day] = data
  },

  [Types.DELETE_FROM_MODULE](state, data) {
    data.forEach(oldModule => {
      if(isObject(state.module?.[oldModule.employee_uuid]?.[oldModule.year]?.[oldModule.month]?.[oldModule.day]))
        delete state.module[oldModule.employee_uuid][oldModule.year][oldModule.month][oldModule.day]

      this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `${state.moduleTitle} "${oldModule[state.notificationTitle]}" was deleted.`,
          actions: {
            close: true,
          }
        }, { root: true })
    })
  },

  [Types.SINGLE_DELETE_FROM_MODAL](state, {employee_uuid, year, month, day}) {
    if(isObject(state.module?.[employee_uuid]?.[year]?.[month]?.[day]))
      delete state.module[employee_uuid][year][month][day]
  },

  [Types.FILL_FORM_DATA](state, uuid) {
    let module = values(state.module).find(employeeIndex => {
      return values(employeeIndex).find(yearIndex => {
        return values(yearIndex).find(monthIndex => {
          return values(monthIndex).find(day => day.uuid === uuid)
        })
      })
    })

    if(!module && state?.currentModule?.uuid === uuid)
      module = state.currentModule

    // Fill form data without object references
    Object.keys(state.formData).forEach(key => state.formData[key] = getValueWithoutReference(module[key]))
  },

  [Types.UPDATE_SINGLE_MODEL](state, data) {
    if(typeof data?.stateVariable !== 'undefined') {
      let index = state[data.stateVariable].findIndex(el => el[state.primaryKey] === data[state.primaryKey])
      state[data.stateVariable][index] = data
      return
    }

    if(state.module?.[data.employee_uuid]?.[data.year]?.[data.month]?.[data.day])
      state.module[data.employee_uuid][data.year][data.month][data.day] = data
  },
}, [MultiFormMutations()])