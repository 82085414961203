import BaseGetters from '@/store/base/getters'

export default BaseGetters({
  getAvailableModules: (state, getters) => excludeModules => {
    const excludeModulesPrimaryKeys = []

    if(typeof excludeModules === 'object') {
      for(const excludeModule of Object.values(excludeModules)) {
        excludeModulesPrimaryKeys.push(excludeModule[getters['getPrimaryKey']])
      }
    }

    if(excludeModulesPrimaryKeys.length)
      return getters['getModule'].filter(module => !excludeModulesPrimaryKeys.includes(module[getters['getPrimaryKey']]))

    return getters['getModule']
  }
})