import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import store from './store'

import BootstrapVue3 from 'bootstrap-vue-3'
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts"
import Maska from 'maska'

import VueFeather from 'vue-feather'
import Particles from "particles.vue3"

import AuthLayout from './lauouts/Auth'
import CrmLayout from './lauouts/Crm'

import '@/assets/scss/config/saas/app.scss'
import '@vueform/slider/themes/default.css'

import GlobalMixin from "@/mixins/GlobalMixin"

AOS.init({
  easing: 'ease-out-back',
  duration: 1000
})

createApp(App)
  .use(store)
  .use(router)
  .component('auth-layout', AuthLayout)
  .component('crm-layout', CrmLayout)
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(Particles)
  .use(vClickOutside)
  .mixin(GlobalMixin)
  .mount('#app')
