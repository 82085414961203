import BaseGetters from '@/store/base/getters'

export default BaseGetters({
  getCategoryListByKey: state => key => {
    return typeof state.module[key] !== 'undefined' ? state.module[key] : []
  },
  fetchedCounts: state => state.fetchedCounts,

  getMainCategories: state => state.tempModule[''],
  getChildCategories: state => key => state.tempModule[key],
  getAllCategories: state => {
    const categories = []

    for(const category of Object.values(state.tempModule))
      categories.push(...category)

    return categories
  },
  getWarehouseCategory: (state, getters) => uuid => {
    return getters['getAllCategories']?.find(category => category.uuid === uuid)
  }
})

