import * as Types from "@/store/mutationTypes";
import api from "@/plugins/axios";
import {formatModuleUrl} from "@/helpers/formatters";

export default ({module}) => ({
  /**
   * Fetch current module by slug if string is passed or by 'data.key' if object is passed
   */
  async fetchCurrentModule({dispatch, commit, getters}, data) {
    let currentModule = null

    const key = typeof data?.key !== 'undefined' ? data.key : 'slug'
    const value = typeof data?.value !== 'undefined' ? data.value : data

    if(typeof value === 'string' && value.length) {
      currentModule = getters.getModule.find(module => module[key] === value)

      if(!currentModule) {
        await api.post(`/${formatModuleUrl(module)}/get-by-key`, {
          key,
          value
        }).then(response => {
          currentModule = response?.data[0]
        })
      }

      if(currentModule) {
        await dispatch('parseCurrentModule', currentModule).then((response) => currentModule = response !== null ? response : currentModule)

        dispatch('currentModuleFetched', currentModule)
      }
    }

    commit(Types.SET_CURRENT_MODULE, currentModule)
  },

  async currentModuleFetched() {
    return null
  },

  async fetchCurrentModuleSubModules({getters}, {submoduleTitle, uuid}) {
    let submodule = null

    const moduleUuid = uuid || getters.getCurrentModule?.uuid || null
    if(moduleUuid) {
      await api.post(`${formatModuleUrl(module)}/${formatModuleUrl(submoduleTitle)}/all`, {module_uuid: moduleUuid }, { trigger404: false })
        .then(response => submodule = response.data)
        .catch(() => {})
    }

    if(!Array.isArray(submodule) && !submodule?.uuid)
      submodule = []

    return submodule
  },

  setCurrentModuleTab({commit}, tab) {
    commit(Types.SET_CURRENT_MODULE_TAB, tab)
  },

  parseCurrentModule() {
    return null
  },
})