import BaseMutations from "@/store/base/mutations";
import * as Types from "@/store/mutationTypes";

export default BaseMutations({
    [Types.FILL_FORM_DATA](state, uuid) {
        let module = state.module.find(el => el.uuid === uuid)

        if(!module && state.currentModule?.uuid === uuid)
            module = state.currentModule

        state.formData.uuid = module.uuid
        state.formData.transport_uuid = module.transport_uuid
        state.formData.defect = module.defect
        state.formData.effected_at = module.effected_at
        state.formData.comment = module.comment
    },
})