import BaseState from '@/store/base/state'
import MultipleFormState from '@/store/base/multipleFormData/state'

export default BaseState({
  moduleTitle: 'Employee rotation',

  module: {},

  formData: {
    uuid: '',
    employee_uuid : '',
    comment : '',
    category : '',
    is_educational : '',
    dismiss_date : '',
  },

  dummyData: {
    uuid: '',
    employee_uuid : '',
    comment : '',
    category : '',
    is_educational : '',
    dismiss_date : '',
  },

  notificationTitle: 'employee',
  primaryKey: 'uuid',

  staticCrudActionTitle: 'updated',

  searchBy: [],

  formIdFields: ['employee_uuid'],
}, [MultipleFormState])