import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,

  state: () => ({
    user: null,
    token: null,
    alerts: null,
  }),
  getters,
  actions,
  mutations
}
