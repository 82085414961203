import BaseState from '@/store/base/state'

export default BaseState({
    moduleTitle: 'Employee position',

    formData: {
      uuid: '',
      title_en: '',
      title_lt: '',
      administrative_employee: false
    },

    dummyData: {
      uuid: '',
      title_en: 'title',
      title_lt: 'title',
      administrative_employee: 'boolean',
    },

    notificationTitle: 'title_en',
})