import casual from 'casual-browserify'
import store from '@/store'

/* eslint-disable */
export function massFillData(data, form) {
  let formData = {}

  Object.keys(data).forEach(key => {
    if(typeof data[key] === 'object') {
      switch(data[key].type) {
        case 'number':
          formData[key] = parseInt(casual[data[key].key])
          break;

        case 'date':
          formData[key] = casual.date(data[key].key)
          break;

        case 'date_time':
          let date = casual.date(data[key].key)
          let time = casual.time(data[key].second_key)

          console.log(date, time, date + ' ' + time)

          formData[key] = date + ' ' + time
          break;

        case 'random_element':
          const array = store.getters[data[key].getterNamespace]
          const arrayElementKey = data[key].key

          formData[key] = casual.random_element(array)[arrayElementKey]
          break;

        case 'integer':
          const fromInt = data[key]?.from ?? 0
          const toInt = data[key]?.to ?? 1

          formData[key] = parseInt(casual.integer(fromInt, toInt))
          break;

        case 'double':
          const fromDouble = data[key]?.from ?? 0
          const toDouble = data[key]?.to ?? 1

          // Precisely round double to two decimal places
          formData[key] = Math.round((casual.double(fromDouble, toDouble) + Number.EPSILON) * 100) / 100
          break;

        default:
          formData[key] = casual[data[key].key]
      }

      return
    }

    if(data[key] !== '')
      formData[key] = casual[data[key]]
    else
      formData[key] = form[key]
  })

  return formData
}