import auth from './auth'
import accommodations from './accommodations'
import form from './form'
import users from './users'
import notification from './notifications'
import crmSettings from './crmSettings'
import roles from './roles'
import warning from './warning'
import employeeResponsibilities from './employeeResponsibilities';
import employees from './employees';
import employeeTrainings from './employeeTrainings';
import employeeSchedules from './employeeSchedules';
import warehouseCategories from './warehouseCategories';
import projectCategories from './projectCategories';
import projects from './projects';
import carCategories from './carCategories';
import certificates from './certificates';
import alertEditing from './alertEditing';
import warehouse from './warehouse';
import filters from './system/filters';
import locations from './locations';
import citizenships from './citizenships';
import transports from './transports';
import transportDefects from './transportDefects';
import transportServices from './transportServices';
import candidates from './candidates';
import comments from './comments';
import taskManagerTasks from './taskManagerTasks';
import settingsTimekeepingTemplates from './settingsTimekeepingTemplates';
import employeeTimekeepingTemplates from './employeeTimekeepingTemplates';
import timekeepingDays from './timekeepingDays';
import dashboard from './dashboard';
import employeeWorkTrip from "./employeeWorkTrip";
import plans from './plans';
import projectWeeks from "./projectWeeks";
import projectMonths from "./projectMonths";
import employeeRotations from "./employeeRotations";
import rotationDays from "./rotationDays";
import sms from "./sms";
import smsTemplates from "./smsTemplates";
import companies from "./companies";
import signature from "./signature";
import actions from "./actions";
import clients from "./clients";
import history from "./history";
import employeeScheduleExports from "./employeeScheduleExports";
import employeeWorkTripMails from "./employeeWorkTripMails";
import fuelCards from "./fuelCards";
import automaticDocuments from "./automaticDocuments";
import documentCategories from "./documentCategories";
import documents from "./documents";

export default {
  auth,
  companies,
  accommodations,
  form,
  users,
  notification,
  crmSettings,
  roles,
  warning,
  employees,
  employeeSchedules,
  employeeTrainings,
  employeeResponsibilities,
  warehouseCategories,
  projectCategories,
  projects,
  carCategories,
  certificates,
  alertEditing,
  warehouse,
  filters,
  locations,
  citizenships,
  transports,
  transportDefects,
  transportServices,
  candidates,
  comments,
  taskManagerTasks,
  timekeepingDays,
  settingsTimekeepingTemplates,
  employeeTimekeepingTemplates,
  dashboard,
  employeeWorkTrip,
  plans,
  projectWeeks,
  projectMonths,
  employeeRotations,
  rotationDays,
  sms,
  smsTemplates,
  signature,
  actions,
  clients,
  history,
  employeeScheduleExports,
  employeeWorkTripMails,
  fuelCards,
  automaticDocuments,
  documentCategories,
  documents,
}
