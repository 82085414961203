import * as Types from './../../mutationTypes'

export default {
  [Types.REMOVE_FROM_MODEL](state, id) {
    state.notifications = state.notifications.filter(notification => notification.id !== id)
  },

  [Types.ADD_TO_MODEL](state, data) {
    let notificationId = 0

    // Set notification id
    if(state.notifications.length > 0 ) {
      notificationId = state.notifications[state.notifications.length - 1].id + 1
    }

    // Take default duration or passed duration from dispatch
    let duration = data.duration ?? state.defaultDuration

    state.notifications.push({
      id: notificationId,
      type: data.type,
      message: data.message,
      actions: {
        close: data.actions.close ?? false,
        undo: data.actions.undo ?? false
      }
    })

    // Remove notification after duration pass
    setTimeout(() => {
      this.dispatch('notification/hideNotification', notificationId);
    }, duration)
  }
}
