import BaseGetters from '@/store/base/getters'

export default BaseGetters({
    getPositionByUuid: state => uuid => state.module.find(position => position.uuid === uuid),
    getPositionTitle: (state, getters) => (uuid, locale = 'en') => {
        if(Array.isArray(uuid)) {
            let titles = []

            getters['getModule'].forEach(module => {
                if(uuid.includes(module.uuid))
                    titles.push(module[`title_${locale}`])
            })

            return titles.join(', ')
        }

        return getters['getModuleByKey']('uuid', uuid)?.[`title_${locale}`] || ''
    },
})