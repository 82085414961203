import * as Types from '@/store/mutationTypes'

export function archiveMutations(module) {
  return {
    [Types.ARHIVE_DOCUMENTS](state, archivedDocument) {
      const index = (module === 'documents' ? state.module : state.documents).findIndex(document => document.uuid === archivedDocument.uuid)

      if(index < 0)
        return

      const document = (module === 'documents' ? state.module : state.documents)[index]

      if(module === 'documents')
        state.module[index] = archivedDocument
      else
        state.documents[index] = archivedDocument

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `Document "${document.file_name} ${document.uploaded_at}" has been archived.`,
        actions: {
          close: true,
        }
      }, {root: true})
    },

    [Types.RESTORE_DOCUMENTS](state, restoredDocument) {
      const index = (module === 'documents' ? state.module : state.documents).findIndex(document => document.uuid === restoredDocument.uuid)
      if(index < 0)
        return

      const document = (module === 'documents' ? state.module : state.documents)[index]

      if(module === 'documents')
        state.module[index] = restoredDocument
      else
        state.documents[index] = restoredDocument

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `Document "${document.file_name} ${document.uploaded_at}" has been restored.`,
        actions: {
          close: true,
        }
      }, {root: true})
    },

    [Types.DELETE_DOCUMENTS](state, data) {
      data.forEach(oldDocument => {
        const documents = (module === 'documents' ? state.module : state.documents).filter(document => document.uuid !== oldDocument.uuid)

        if(module === 'documents')
          state.module = documents
        else
          state.documents = documents

        this.dispatch('notification/addNotificationToList', {
          type: 'success',
          message: `Document "${oldDocument.file_name} ${oldDocument.uploaded_at}" was deleted.`,
          actions: {
            close: true,
          }
        }, {root: true})
      })
    },
  }
}

export default (module) => Object.assign({}, archiveMutations(module), {
  [Types.SET_DOCUMENTS](state, documents) {
    state.documents = documents
  },

  [Types.UNSET_DOCUMENTS](state) {
    state.documents = null
  },

  [Types.CREATE_DOCUMENTS](state, documents) {
    if(documents.length && !Array.isArray(state.documents))
      state.documents = []

    documents?.forEach(document => {
      state.documents.unshift(document)

      this.dispatch('notification/addNotificationToList', {
        type: 'success',
        message: `Document "${document.file_name} ${document.uploaded_at}" was created.`,
        actions: {
          close: true,
        }
      }, { root: true })
    })
  },

  [Types.SET_FORM_FILES_DATA](state, data) {
    state.formFilesData[data.key] = data.value
  },

  [Types.EMPTY_FILES_FORM_DATA](state) {
    state.formFilesData = {}
  },

  [Types.SET_FORM_DOCUMENT_DATA](state, {index, key, value}) {
    if(key === 'categories') {
      if(!state.formFilesData.documents[index][key])
        state.formFilesData.documents[index][key] = {}

      if(!Array.isArray(state.formFilesData.documents[index][key][value.parent]))
        state.formFilesData.documents[index][key][value.parent] = []

      if(!value.value) {
        if(value.parent === 'main' && state.formFilesData.documents[index][key][value.uuid])
          delete state.formFilesData.documents[index][key][value.uuid]

        state.formFilesData.documents[index][key][value.parent] = state.formFilesData.documents[index][key][value.parent].filter(uuid => uuid !== value.uuid)
        return
      }

      state.formFilesData.documents[index][key][value.parent].push(value.uuid)

      return
    }

    state.formFilesData.documents[index][key] = value && !isNaN(value) ? value * 1 : value
  },

  [Types.SET_FORM_INVOICE_PRODUCT_DATA](state, {invoiceIndex, productIndex, key, value}) {
    state.formFilesData.documents[invoiceIndex].products[productIndex][key] = value && !isNaN(value) ? value * 1 : value
  },

  [Types.ADD_FORM_INVOICE_PRODUCT](state, invoiceIndex) {
    if(!Array.isArray(state.formFilesData.documents[invoiceIndex].products))
      state.formFilesData.documents[invoiceIndex].products = []

    state.formFilesData.documents[invoiceIndex].products.push({})
  },

  [Types.DESTROY_FORM_INVOICE_PRODUCT](state, {invoiceIndex, productIndex}) {
    const products = state.formFilesData.documents[invoiceIndex].products

    if(products && products[productIndex])
      state.formFilesData.documents[invoiceIndex].products = (products || []).filter((product, index) => index !== productIndex)
  },
})
