import axios from 'axios'
import store from '@/store'
import router from '@/router'

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_DOMAIN + 'api/',
})

HTTP.interceptors.request.use(request => {
  if(store.getters['auth/getToken']) {
    request.headers.common['Authorization'] = 'Bearer ' + store.getters['auth/getToken']
  }

  return request
})

export default {
  get(url) {
    return new Promise((resolve, reject) => {
      HTTP.get(url.trim('/'))
        .then((res) => {
          resolve(res.data)
        })
        .catch(error => {
          reject(error.response.data)
          this.errorHandling(error.response, 'GET')
        })
    })
  },

  post(url, data, config = {}) {
    return new Promise((resolve, reject) => {
      HTTP.post(url.trim('/'), data, config)
        .then((res) => {
          resolve(res.data)
        })
        .catch(error => {
          reject(error.response.data)

          if(!Object.keys(config).includes('suppressErrorHandling') || config?.suppressErrorHandling !== true)
            this.errorHandling(error.response, 'POST', config)
        })
    })
  },

  errorHandling(error, type, config = {}) {
    // TODO: add 404 code. to allow send data to form response
    switch(error.status) {
      case 401:
          router.push({
            name: 'login',
            params: {
              statusCode: 401
            }
          })

          store.dispatch('notification/addNotificationToList', {
            type: 'error',
            message: 'You have to be logged in to access this resource.',
            actions: {
              close: true
            }
          })
        break

      case 403:
          // TODO: add normal notification message for Forbidden response.
          store.dispatch('notification/addNotificationToList', {
            type: 'error',
            message: `You do not have permission to: "${error.data.data}".`,
            actions: {
              close: true
            }
          })
        break

      case 404:
        if(config?.trigger404 !== false)
          router.push({
            name: '404',
            params: {
              statusCode: 404
            }
          })
        break

      case 405:
        if(store.getters['form/showForm']) {
          store.dispatch('form/setFormErrors', !Array.isArray(error.data.message) ? [error.data.message] : error.data.message)
          break
        }

        store.dispatch('notification/addNotificationToList', {
          type: 'error',
          message: `Failed to process submitted form.`,
          actions: {
            close: true
          }
        })

        break

      case 422:
        if(store.getters['form/showForm']) {
          store.dispatch('form/setFormFieldsErrors', error.data.errors)
          break
        }

        store.dispatch('notification/addNotificationToList', {
          type: 'error',
          message: `Failed to process submitted form fields.`,
          actions: {
            close: true
          }
        })

        break

      case 500:
        store.dispatch('notification/addNotificationToList', {
          type: 'error',
          message: 'Unexpected server error.',
          actions: {
            close: true
          }
        })
        break

      default:
        if(process.env.VUE_APP_DEBUG_MODE === 'true')
          console.log('%c>>> ' + type + ' ERROR: ', 'color: red', error.status, error.data)
        break
    }
  }
}
