const workContractTerminationFields = ['document_serial_number', 'employment_contract_date', 'employment_contract_number', 'work_contract_termination_date', 'work_contract_termination_reason', 'company_uuid', 'signer', 'first_name', 'last_name']

export default {
  work_contract_termination: {
    title: 'Darbo sutarties nutraukimas',
    fields: workContractTerminationFields,
  },

  work_contract_termination_by_article: {
    title: 'Darbo sutarties nutraukimas pagal straipsnį',
    fields: [...workContractTerminationFields, ...['article']],
  },

  work_contract_correction: {
    title: 'Darbo sutarties pakeitimas',
    fields: ['document_serial_number', 'first_name', 'last_name', 'employment_contract_date', 'employment_contract_number', 'company_uuid', 'signer', 'other_conditions', 'work_contract_clause', 'work_contract_clause_correction', 'valid_from'],
  },

  npd_request: {
    title: 'NPD prašymas',
    fields: ['document_serial_number', 'document_date', 'first_name', 'last_name', 'address', 'personal_number', 'position', 'company_uuid', 'from_date', 'apply'],
  },

  work_contract: {
    title: 'Darbo sutartis',
    fields: ['document_serial_number', 'employment_contract_date', 'employment_contract_number', 'first_name', 'last_name', 'personal_number', 'address', 'phone', 'email', 'position', 'contract_type', 'salary', 'contract_duration', 'work_hours_per_week', 'work_start', 'trial_period_months', 'company_uuid', 'other_conditions', 'agreements', 'signer'],
  },

  confidential_contract: {
    title: 'Konfidencialios informacijos saugojimo susitarimas',
    fields: ['document_serial_number', 'attachment_number', 'document_date', 'employment_contract_date', 'employment_contract_number', 'first_name', 'last_name', 'company_uuid', 'signer', 'date_of_birth', 'address', 'phone'],
  },

  guaranty_contract: {
    title: 'Pasižadėjimas',
    fields: ['document_serial_number', 'attachment_number', 'document_date', 'first_name', 'last_name', 'position', 'department', 'company_uuid'],
  },

  hire_contract: {
    title: 'Prašymas priimti į darbą (M)',
    fields: ['document_serial_number', 'document_date', 'first_name', 'last_name', 'address', 'phone', 'company_uuid', 'position', 'work_start', 'salary', 'account_number', 'decision'],
  },

  fire_contract: {
    title: 'Prašymas atleisti',
    fields: ['document_serial_number', 'document_date', 'first_name', 'last_name', 'company_uuid', 'position', 'reason', 'decision', 'article', 'resignation_date'],
  },

  vacation_request: {
    title: 'Kasmetinių atostogų prašymas',
    fields: ['document_serial_number', 'document_date', 'company_uuid', 'first_name', 'last_name', 'department', 'position', 'vacation_start', 'vacation_end', 'signer'],
  },
}
