<template lang="pug">
.dropdown.ms-sm-3.header-item.topbar-user
  button#page-header-user-dropdown.btn(type='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
    span.d-flex.align-items-center
      user-avatar(
        :firstName="firstName"
        :lastName="lastName"
      )
      span.text-start.ms-xl-2
        span.d-none.d-xl-inline-block.ms-1.fw-medium.user-name-text {{ fullName }}
        span.d-none.d-xl-block.ms-1.fs-12.text-muted.user-name-sub-text {{ roleName }}
  .dropdown-menu.dropdown-menu-end
    // item
    h6.dropdown-header Welcome {{ firstName }}!

    //a.dropdown-item(href='/pages/profile')
    //  i.mdi.mdi-account-circle.text-muted.fs-16.align-middle.me-1
    //  span.align-middle Profile

    .dropdown-divider

    a.dropdown-item(
      @click.prevent="logout"
    )
      i.mdi.mdi-logout.text-muted.fs-16.align-middle.me-1
      span.align-middle Logout
</template>

<script>
import {mapGetters} from "vuex";
import UserAvatar from "@/components/user/Avatar";

export default {
  name: 'UserMenu',
  components: {UserAvatar},
  computed: {
    ...mapGetters({
      fullName: 'auth/getFullName',
      roleName: 'auth/getRoleName',
      firstName: 'auth/getFirstName',
      lastName: 'auth/getLastName'
    })
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logOut')
        .then(() => {
          // this.$router.push({name: 'logout'})
        })
    }
  }
}
</script>
