import * as Types from './../../mutationTypes'

export default {
  hideNotification({commit}, id) {
    commit(Types.REMOVE_FROM_MODEL, id)
  },

  addNotificationToList({commit}, data) {
    commit(Types.ADD_TO_MODEL, data)
  }
}
