<template lang="pug">
.notification-wrapper
  notification(
    v-for="notification in allNotifications"
    :notification="notification"
  )

</template>

<script>
import {mapGetters} from "vuex";
import Notification from './NotificationCard'

export default {
  name: "NotificationWrapper",

  components: {
    Notification
  },

  computed: {
    ...mapGetters({
      allNotifications: 'notification/getNotifications'
    })
  }
}
</script>
